import { useEffect, useState } from 'react';
import {
  Features,
  Devices,
  UserTypes,
  Hours,
  Company,
  SelectionTypeKeys,
  FeaturesOptionSelector,
  InputFieldTypeKeys,
  initializeVissibleCards,
  initialSelectedFilters
} from './constants';
import FeaturesComp from './Features';
import DevicesComp from './devices';
import UserType from './user-type';
import FiltersHeader from './filter-header';
import CustomFieldFilter from './custom-field-filter';
import CardsHideShow from './card-hide-show';
import { getAllCompanies } from 'features/companies/companiesSlice';
import { toast } from 'react-toastify';
import CustomAutoCompleteFilter from './custom-auto-complete-filter';
import Button from 'react-bootstrap/Button';
import { getBusinessTypes } from 'features/analytics/analyticSlice';
import { getCookie, setCookie } from 'helpers/generic.methods';
import { useDispatch, useSelector } from 'react-redux';
import { clearAll, isClearAll } from 'features/analytics/analyticSlice';
import { useTranslation } from 'react-i18next';
const generateOptions = data => {
  return [...data].map(dt => ({
    key: `${dt.companyKey}`,
    value: dt.companyKey,
    id: dt.id
  }));
};

const generateBusinessOptions = data => {
  return [...data].map(dt => ({
    name: `${dt.name}`,
    value: dt.id,
    text: dt.name
  }));
};

const Filters = ({
  openColapse,
  onFilterChange,
  closePanel,
  selectedData,
  hiddenCards,
  setHiddenCards,
  roles = {},
  cardsSection
}) => {
  const [features, setFeatures] = useState(Features);

  const [hours, setHours] = useState(Hours);
  const [devices, setDevices] = useState(Devices);
  const [userTypes, setUserTypes] = useState(UserTypes);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [items, setItems] = useState([]);
  const [allFilters, setAllFilters] = useState(initialSelectedFilters);
  const [hideShowCards, setHideShowCards] = useState(hiddenCards);
  const clearAllFilters = useSelector(isClearAll);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setFilters = {
    businessTypes: setBusinessTypes,
    devices: setDevices,
    features: setFeatures,
    hours: setHours,
    items: setItems,
    userTypes: setUserTypes
  };
  const defaultFilterValues = {
    businessTypes: businessTypes,
    devices: Devices,
    features: Features,
    hours: Hours,
    items: items,
    userTypes: UserTypes
  };

  //const [items, setItems] = useState([]);
  const [companiesOption, setCompaniesOption] = useState([]);
  const [businessTypesOptions, setBusinessTypesOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const handleChange = (arg, key) => {
    let obj = {
      businessTypes: businessTypes.filter(val => val.value),
      devices: devices.filter(val => val.value),
      features: features.filter(val => val.value),
      hours: hours.filter(val => val.value),
      items: items.filter(val => val.value),
      userTypes: userTypes.filter(val => val.value)
    };
    obj[key] = arg;
    setAllFilters(obj);
  };

  const applyFilter = () => {
    console.log('apply');
    // setHiddenCards(hideShowCards);
    onFilterChange(allFilters, hideShowCards);
  };

  const handleTextChange = text => {
    setLoadingOptions(true);
    try {
      let payload = {
        start: 0,
        limit: 10,
        filter: JSON.stringify([
          {
            propertyName: 'companyKey',
            propertyValue1: `${text}%`,
            propertyValue2: '',
            propertyValue3: [],
            filterDataType: 2,
            comparison: 2
          }
        ]),
        dir: 'ASC',
        sort: 'companyKey'
      };

      getAllCompanies(payload).then(response => {
        if (response && response.data) {
          let data = generateOptions(response.data.data);
          let filteredOptions = [];
          data.forEach(element => {
            if (!alreadySelectedElement(element)) {
              filteredOptions.push(element);
            }
          });
          setCompaniesOption(filteredOptions);
          // updateOptions(filteredOptions);
          setLoadingOptions(false);
        }
      });
    } catch (error) {
      setLoadingOptions(false);
      toast.error(error || error.message);
    }
  };
  const alreadySelectedElement = option => {
    if (items.length && items.find(val => val.key == option.key)) {
      return true;
    }
    return false;
  };
  // get BusinessType Options
  useEffect(() => {
    if (getCookie('analyticsBusinessOptions')) {
      let data = generateBusinessOptions(
        JSON.parse(getCookie('analyticsBusinessOptions'))
      );
      setBusinessTypesOptions(data);
    } else {
      getBussinesTypes();
    }
  }, []);

  const getBussinesTypes = () => {
    try {
      getBusinessTypes().then(response => {
        if (response && response.data) {
          let data = generateBusinessOptions(response.data.data);
          setBusinessTypesOptions(data);
          setCookie(
            'analyticsBusinessOptions',
            JSON.stringify(response.data.data),
            1
          );
        }
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  const handleClearAll = (arg, key) => {
    setFeatures(Features);
    setHours(Hours);
    setDevices(Devices);
    setUserTypes(UserTypes);
    setHideShowCards(initializeVissibleCards(false, hiddenCards));
    setBusinessTypes([]);
    setItems([]);
    setAllFilters(initialSelectedFilters);
  };

  useEffect(() => {
    if (clearAllFilters) {
      handleClearAll();
      dispatch(clearAll(false));
    }
  }, [clearAllFilters]);

  useEffect(() => {
    updateSelectedStates();
  }, [openColapse]);

  useEffect(() => {
    updateSelectedStates();
    setAllFilters(selectedData);
  }, [selectedData, hiddenCards]);

  const updateSelectedStates = () => {
    Object.keys(selectedData).forEach((key, index) => {
      let keys = selectedData[key].map(sf => sf.key);

      let feat = defaultFilterValues[key].map(f => {
        if (keys.includes(f.key)) return { ...f, value: true };
        else return { ...f };
      });
      if (SelectionTypeKeys.includes(key)) setFilters[key]([...feat]);
      else setFilters[key](selectedData[key]);
    });
    updateOptions(selectedData.items);
    setHideShowCards(hiddenCards);
  };

  const updateOptions = (data = []) => {
    let rawData = data;
    if (selectedData && selectedData.items && selectedData.items.length) {
      rawData = [...rawData, ...selectedData.items];
    }
    if (rawData && rawData.length) {
      let distinctOptions = rawData.filter(
        (i, index, self) =>
          index ===
          self.findIndex(
            t => t.id === i.id && t.value === i.value && t.key === i.key
          )
      );
      setCompaniesOption(distinctOptions);
    }
  };

  return (
    <>
      <FiltersHeader
        title={t('company.analytics.filters')}
        onFilterClearAll={handleClearAll}
        handleClosePanel={closePanel}
        clearTitle={t('company.analytics.clearAll')}
      />

      {roles.allowFeatures && (
        <FeaturesComp
          title={t('company.analytics.features')}
          toolTipQuickStats="Total Chats, Total Leads, Total Billable Leads, Active Chats, Blank Chats, CTR,LCR"
          toolTipGraphs="Features Usage, Chat Stats By, Chats vs Leads, Top/Bottom Lead Accounts"
          dataArray={features}
          setFunction={setFeatures}
          categoryButtons={true}
          onChangefilters={e => handleChange(e, 'features')}
        />
      )}
      {roles.allowDevices && (
        <DevicesComp
          title={t('company.analytics.devices')}
          toolTipQuickStats="Total Chats,Total Leads,Total Visits,Total Billable Leads,Active Chats,Blank Chats,CTR,LCR"
          toolTipGraphs="Features Usage, Chat Stats By, Chats vs Leads"
          dataArray={devices}
          setFunction={setDevices}
          onChangefilters={e => handleChange(e, 'devices')}
        />
      )}
      {roles.allowUser && (
        <UserType
          title={t('company.analytics.user')}
          toolTipQuickStats="Total Chats,Total Leads,Total billable Leads,Active Chats,Blank Chats,CTR,LCR"
          toolTipGraphs="Features Usage, Chat Stats By, Chats vs Leads"
          dataArray={userTypes}
          setFunction={setUserTypes}
          onChangefilters={e => handleChange(e, 'userTypes')}
        />
      )}
      {/* <CustomFieldFilter
        title="Company Key"
        dataArray={items}
        setFunction={setItems}
        onChangefilters={e => handleChange(e, 'items')}
      /> */}
      {roles.allowCompanyKey && (
        <CustomAutoCompleteFilter
          title={t('company.analytics.companyKey')}
          toolTipQuickStats="Apply to All"
          toolTipGraphs="Features Usage, Chat Stats By, Chats vs Leads, Top/Bottom Lead Accounts"
          onChangefilters={e => handleChange(e, 'items')}
          setFunction={setItems}
          handleTextChange={handleTextChange}
          options={companiesOption}
          loading={loadingOptions}
          selected={items}
        />
      )}
      {/* <AutoCompeleteComp options={companiesOption} /> */}

      {roles.allowBusinessType && (
        <CustomFieldFilter
          title={t('company.analytics.typeOfBusiness')}
          toolTipQuickStats="Apply to All"
          toolTipGraphs="Features Usage, Chat Stats By, Chats vs Leads, Top/Bottom Lead Accounts"
          dataArray={businessTypes}
          setFunction={setBusinessTypes}
          options={businessTypesOptions}
          onChangefilters={e => handleChange(e, 'businessTypes')}
        />
      )}
      {/* {roles.allowHours && (
        <FeaturesComp
          title="Hours"
          dataArray={hours}
          setFunction={setHours}
          onChangefilters={e => handleChange(e, 'hours')}
          isHours={true}
        />
      )} */}
      {/* {cardsSection && ( */}
      <CardsHideShow
        onFilterClear={() =>
          setHideShowCards(initializeVissibleCards(false, hiddenCards))
        }
        title={t('company.analytics.ChartsShowHide')}
        data={hideShowCards}
        // allowedCheckBoxes={cardsSection}
        setFunction={setHideShowCards}
      />
      {/* )} */}

      {openColapse && (
        <Button
          className="floating-btn-apply-filter"
          variant="primary"
          title="Filters"
          onClick={applyFilter}
        >
          {t('company.analytics.applyFilters')}
        </Button>
      )}

      <br />
      <br />
    </>
  );
};

export default Filters;

import React from 'react';
import { Card, ListGroup, Spinner } from 'react-bootstrap';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from 'react-bootstrap/Accordion';
import { UilAngleUp } from '@iconscout/react-unicons';
import Skeleton from 'react-loading-skeleton';
import ChatItem from './ChatItem';
import ScrollBar from 'components/Scrollbars';
import { useSelector } from 'react-redux';
import { getUnsentLeadsLoading } from 'features/messagingChats/messagingChats';
import Title from './Title';
import { selectRoles } from 'features/roles/rolesSlice';

const UnsentLeadChat = ({
  title,
  chats,
  showActionBtns,
  onChatClickHandler,
  eventKey
}) => {
  const loading = useSelector(getUnsentLeadsLoading);
  const {
    roles: { isOperator, isAgent }
  } = useSelector(selectRoles);
  return (
    <>
      <Card className="chat-pool-card chat-list-container">
        <Title
          title={title}
          chatCount={chats.length}
          eventKey={eventKey}
          loading={loading}
          showRefetchIcon={true}
        />
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body>
            <ListGroup className="chat-list h-100">
              <ScrollBar
                horizontalScroll={false}
                style={{
                  // height: 'calc(100vh - 121px)'
                  height: chats.length ? '200px' : '10px'
                }}
              >
                {(loading ? Array.from(new Array(3)) : chats).map((chat, i) =>
                  chat ? (
                    <ChatItem
                      isOperator={isOperator}
                      isAgent={isAgent}
                      key={chat.chatId}
                      showActionBtns={showActionBtns}
                      chat={chat}
                      onChatClickHandler={onChatClickHandler}
                    />
                  ) : (
                    <Skeleton width={'100%'} height={60} key={i} />
                  )
                )}
              </ScrollBar>
              {chats.length === 0 && (
                <ListGroup.Item className="non-active-list">
                  <div>
                    <span className="text-center message">
                      No chat available
                    </span>
                  </div>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export default UnsentLeadChat;

export const sp_reportDetails = {
  heading1: 'Parámetros',
  note:
    'Nota: Las fechas de comienzo a las 12:00 de la mañana en la zona horaria seleccionada y terminan a la medianoche del mismo día. Para obtener los resultados para un día específico, utilice ese día como * ambos * la fecha de inicio y fin.',
  routes: {
    parameters: 'Parámetros',
    schedule: 'Calendario'
  },
  parameters: {
    name: {
      label: 'Nombre',
      info: 'Nombre del informe con un nombre único, si es necesario.'
    },
    notificationEmail: {
      label: 'Correo electrónico de notificación',
      info:
        'Enviar el informe a múltiples direcciones de correo electrónico mediante el uso de una coma.'
    },
    notificationEndPoint: {
      label: 'Punto final Notificación',
      info:
        'Dirección web para notificar cuando el informe ha ejecutado con éxito. La dirección incluirá una URL que se puede utilizar para recuperar el informe resultante.'
    },
    emailReport: {
      label: 'Informe de correo electrónico como archivo adjunto',
      info:
        'Si está activado, el informe será enviado como archivos adjuntos de correo electrónico con la notificación.'
    },
    companyKeys: {
      label: 'Claves de la compañía',
      info:
        'Uso coma para varias claves compañía separada. Asegúrese de acogida no se comprueba cuando se ejecutan informes individuales sobre la base de claves únicas o múltiples de la compañía.'
    },
    timeZone: {
      label: 'Zona horaria',
      info: 'Seleccione su zona horaria apropiada.'
    },
    period: {
      label: 'Período',
      info: 'El período de tiempo que el informe se postuló para.'
    },
    groupByCompany: {
      label: 'Grupo por la empresa',
      info:
        'Habilitar si hay varias claves que deben ser agrupados dentro del informe.'
    },
    host: {
      label: 'Anfitrión',
      info: 'Seleccione el host si el informe es para todas las empresas.'
    }
  },
  schedule: {
    schedule: {
      label: 'Calendario'
    },
    time: {
      label: 'Hora'
    },
    monday: {
      label: 'Lunes'
    },
    tuesday: {
      label: 'Martes'
    },
    wednesday: {
      label: 'Miércoles'
    },
    thursday: {
      label: 'Jueves'
    },
    friday: {
      label: 'Viernes'
    },
    saturday: {
      label: 'Sábado'
    },
    sunday: {
      label: 'Domingo'
    },
    noScheduleFound: {
      label: 'No hay horario encontrado'
    }
  }
};
export default sp_reportDetails;

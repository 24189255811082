import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import UnsentLeads from '../UnsentLeadChats';
import ChatDetail from '../ChatDetail';
import {
  getActiveChatList,
  getAllChatList,
  getIncomingChatList,
  getSelectedChatId,
  setEnableNotificationSound,
  setSelectedChat,
  setUnreadMessage,
  saveChatMessage,
  changeMessagingDashboardStatusAsync,
  getUnsentLeads,
  getChatRoomData,
  getShelvedChatList,
  getEnableNotificationSound,
  setMessagesRecived,
  isActiveChatUnsubscribed
} from 'features/messagingChats/messagingChats';
import storage from 'helpers/storage';
import ChatList from '../ChatList';
import './Chats.scss';
import { resetLeadFormUserCredentials } from 'features/lead/leadSlice';
import {
  selectChat,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import { errors, success } from 'helpers/variables';
import { selectLogin, setProfileToState } from 'features/auth/authSlice';
import Header from './Header';
import { ChatsType, UnsentChatList } from './Constants';
import { selectRoles } from 'features/roles/rolesSlice';
import ScrollBar from 'components/Scrollbars';
import { Enums } from 'helpers/dropdown-enums';
import {
  RemoveChannelsFromRTCCleint,
  UnSubscribeChat
} from 'app/communication/services/chat/pub_sub_chat';
import { chatCallbacks } from 'app/communication/services/chat/callbacks/chat_default_callbacks';
import Initiate_Chat from 'app/communication/services/chat/initiate_chat';

const Chats = ({
  setToggleChatDetail,
  toggleChatDetail,
  toggleActiveChats,
  setToggleActiveChats,
  setToggleChatSuggestion,
  setDefaultSuggestionTab,
  defaultMessageText
}) => {
  // const { toggle, incomingChatSound, clearSound } = useNotification();
  let enableNotificationSound = useSelector(getEnableNotificationSound);
  const [statusLoading, setStatusLoading] = useState(false);
  const [notifiySoundEnable, setNotifiySoundEnable] = useState(
    enableNotificationSound
  );

  const dispatch = useDispatch();
  let { profile } = useSelector(selectLogin);
  let { roles } = useSelector(selectRoles);
  let chats = {
    incomingChatList: useSelector(getIncomingChatList),
    activeChatList: useSelector(getActiveChatList),
    allChatList: useSelector(getAllChatList),
    shelvedChatList: useSelector(getShelvedChatList),
    unsentLeadList: useSelector(getUnsentLeads)
  };

  let getActiveChatUnsubscribedStatus = useSelector(isActiveChatUnsubscribed);
  let selectedChatId = useSelector(getSelectedChatId);
  const user = useSelector(selectLogin);

  // auto subscribe active chats if they are not subscribed after refresh
  const _onSuccessMessageArrived = data => {
    data['preventNotification'] = true;
    _onMessageArrived(data);
  };
  const _onMessageArrived = data => {
    if (data.userId !== user?.profile?.UserID && !data.preventNotification) {
      reviceMsg();
      dispatch(setUnreadMessage(data.chatId));
    }

    dispatch(saveChatMessage(data));
  };

  useEffect(() => {
    if (chats.activeChatList.length) {
      // re-subscribe active chats after reload
      chats.activeChatList.map(chat => {
        if (!getActiveChatUnsubscribedStatus(chat.getId())) {
          let callbacks = chatCallbacks({
            _onSuccessMessageArrived,
            _onMessageArrived,
            chatId: chat.getId()
          });
          Initiate_Chat({
            chatId: chat.getId(),
            companyId: chat.getCompanyId(),
            callbacks
          });
        }
      });
    }
  }, [chats.activeChatList.length]);

  const onChatClickHandler = roomType => chat => {
    let isFromIncomingSection =
      roomType === Enums.RoomType.IncomingChat ? true : false;
    if (!chat.getId) {
      if (chat.chatId !== selectedChatId) {
        dispatch(getChatRoomData(chat.chatId)).then(res => {
          if (typeof res !== 'string') {
            setChatRoomForSelectedChat({ ...res, isFromIncomingSection });
          }
        });
      }
    } else {
      if (isFromIncomingSection) {
        let isRouted = window.RTC.subscribedChannels.some(item =>
          item.includes(chat.getId())
        );
        console.log('roomType', roomType, isRouted);
        if (isRouted) {
          //unsubscribe chat;
          UnSubscribeChat(chat.getId());
          // RemoveChannelsFromRTCCleint(chat.getId());
        }
      }

      setChatRoomForSelectedChat({ ...chat, isFromIncomingSection });
    }
  };

  const setChatRoomForSelectedChat = chat => {
    if (chat.getId() !== selectedChatId) {
      dispatch(
        toggleChatDetailsRightPane({
          show: false
        })
      );
      dispatch(resetLeadFormUserCredentials());
    }
    dispatch(setSelectedChat(chat));
    setToggleChatDetail(true);
    if (!isMobile) {
      setToggleChatSuggestion(true);
    }
    if (isMobile) {
      setToggleActiveChats(true);
    }
  };

  const setNotificationSoundEnable = status => {
    setNotifiySoundEnable(status);
    dispatch(setEnableNotificationSound(status));
    storage.setItem('enableSound', status);
  };

  const reviceMsg = () => {
    dispatch(setMessagesRecived(true));
    setTimeout(() => {
      dispatch(setMessagesRecived(false));
    }, 1000);
  };

  const toggleStatus = async e => {
    let value = e.target.checked;
    setStatusLoading(true);
    let statusChanged = await dispatch(
      changeMessagingDashboardStatusAsync(+value)
    );

    if (statusChanged) {
      toast.success(success.statusChange);
      setStatusLoading(false);
      let profile = JSON.parse(storage.getItem('Profile'));
      profile['Active'] = value;
      storage.setItem('Profile', profile);
      dispatch(setProfileToState());
    } else {
      toast.error(errors.failure);
      setStatusLoading(false);
    }
  };

  return (
    <>
      <Col className="mb-3 col-chat-wrap">
        <Card border="white" className="card-rounded all-chat-section">
          <Row className="all-chat-row no-gutters">
            {!toggleActiveChats && (
              <div className="chat-list-wrapper border-right px-8">
                <Header
                  totalCount={
                    chats.activeChatList.length +
                    chats.allChatList.length +
                    chats.incomingChatList.length +
                    chats.shelvedChatList.length
                  }
                  notifiySoundEnable={notifiySoundEnable}
                  setNotificationSoundEnable={setNotificationSoundEnable}
                  toggleStatus={toggleStatus}
                  status={profile.Active}
                  statusLoading={statusLoading}
                />
                <ScrollBar
                  horizontalScroll={false}
                  style={{
                    // height: 'calc(100vh - 121px)'
                    height: 'calc(100% - 105px)'
                    // maxHeight: '300px'
                  }}
                >
                  {ChatsType.map(
                    type =>
                      (type.key !== 'SHL' ||
                        (type.key === 'SHL' && roles.isAgent)) && (
                        <Accordion
                          key={type.key}
                          className="messaging-chats"
                          defaultActiveKey={type.defaultActiveKey}
                          flush="true"
                        >
                          {type.key === 'UL' ? (
                            <UnsentLeads
                              title={type.title}
                              chats={chats[type.chats]}
                              showActionBtns={type.showActionBtns}
                              onChatClickHandler={onChatClickHandler(
                                type.roomType
                              )}
                              eventKey={type.eventKey}
                            />
                          ) : (
                            <ChatList
                              title={type.title}
                              chats={chats[type.chats]}
                              showActionBtns={type.showActionBtns}
                              onChatClickHandler={onChatClickHandler(
                                type.roomType
                              )}
                              eventKey={type.eventKey}
                              defaultActiveKey={type.defaultActiveKey}
                            />
                          )}
                        </Accordion>
                      )
                  )}
                </ScrollBar>
                <Accordion
                  className="messaging-chats"
                  defaultActiveKey={UnsentChatList.defaultActiveKey}
                  flush="true"
                >
                  <UnsentLeads
                    title={UnsentChatList.title}
                    chats={chats[UnsentChatList.chats]}
                    showActionBtns={UnsentChatList.showActionBtns}
                    onChatClickHandler={onChatClickHandler(
                      UnsentChatList.roomType
                    )}
                    eventKey={UnsentChatList.eventKey}
                  />
                </Accordion>
                {/* <Accordion
                  className="messaging-chats"
                  defaultActiveKey="0"
                  flush="true"
                >
                  <ChatList
                    title={'Incoming Chats'}
                    chats={incomingChatList}
                    showActionBtns={true}
                    onChatClickHandler={onChatClickHandler}
                    eventKey={'0'}
                  />
                </Accordion>
                <Accordion
                  className="messaging-chats"
                  defaultActiveKey="1"
                  flush="true"
                >
                  <ChatList
                    title={'Active Chats'}
                    chats={activeChatList}
                    showActionBtns={false}
                    onChatClickHandler={onChatClickHandler}
                    eventKey={'1'}
                  />
                </Accordion>
                <Accordion
                  className="messaging-chats"
                  defaultActiveKey="2"
                  flush="true"
                >
                  <ChatList
                    title={'All Chats'}
                    chats={allChatList}
                    showActionBtns={false}
                    onChatClickHandler={onChatClickHandler}
                    eventKey={'2'}
                  />
                </Accordion>
                <Accordion
                  className="messaging-chats"
                  defaultActiveKey="3"
                  flush="true"
                >
                  <ChatList
                    title={'Shelved Chats'}
                    chats={shelvedChatList}
                    showActionBtns={false}
                    onChatClickHandler={onChatClickHandler}
                    eventKey={'3'}
                  />
                </Accordion>
                <Accordion
                  className="messaging-chats"
                  defaultActiveKey="0"
                  flush="true"
                >
                  <UnsentLeads
                    title={'Unsent Leads'}
                    chats={unsentLeadList}
                    showActionBtns={false}
                    onChatClickHandler={onChatClickHandler}
                    eventKey={'4'}
                  />
                </Accordion> */}
              </div>
            )}
            {toggleChatDetail && (
              <Col className="card-rounded chat-details">
                <ChatDetail
                  setToggleChatDetail={setToggleChatDetail}
                  setToggleActiveChats={setToggleActiveChats}
                  setToggleChatSuggestion={setToggleChatSuggestion}
                  setDefaultSuggestionTab={setDefaultSuggestionTab}
                  defaultMessageText={defaultMessageText}
                  notifiySound={reviceMsg}
                />
              </Col>
            )}
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default Chats;

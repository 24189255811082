import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SidebarRight from 'components/Sidebar/sidebar-right';
import {
  getAllQueuedReportList,
  removeReportsFromState
} from 'features/report/reportsSlice';
import { Container, Row, Col } from 'react-bootstrap';
import storage from 'helpers/storage';
import {
  fetchMobileSuggestionsAsync,
  selectMobileSuggestions
} from 'features/fetchGeneralData/fetchGeneralDataOnAuth';
import {
  TabComponent,
  TabsContainer
} from '../../../../components/TabsComponent';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { queuedReportTableConfig } from 'utils/tableConfig';
import {
  queuedReportFilterConfig,
  queuedReportFilterTypeConfig
} from 'utils/filterConfig';
import ReportContainer from '../details/index';
import HeaderToExistingPortal from 'components/headerToExistingPortal';
import config from 'config';
import { selectRoles } from 'features/roles/rolesSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GridWrapper } from '../../../../components/GridWrapper';

const QueuedReports = ({
  setDataLimit,
  setTotalRecords,
  setLoading,
  loading,
  totalRecords
}) => {
  const { t } = useTranslation();
  const [openColapse, setOpenColapse] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const mobileSuggestions = useSelector(selectMobileSuggestions);
  const selectedData = {};
  const { roles } = useSelector(selectRoles);
  const permissions = (roles && roles.switch) || {};
  const [limit, setLimit] = useState(20);

  //const [loading, setLoading] = useState(false);
  // Colapsable panel Open/Close Handler
  const handleOpen = status => {
    setOpenColapse(status);
  };

  // Sppend Close button to TabTitle
  const tabTitle = (index, company) => (
    <span>
      {company.name}
      <span
        className="close-btn"
        onClick={() => removeCompany(index, company.id)}
      >
        &nbsp;&nbsp;&nbsp; x
      </span>
    </span>
  );

  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    setSelectedTab(JSON.parse(storage.getItem('queuedReports-selectedTab')));
    setSelectedCompanies(
      JSON.parse(storage.getItem('queuedReports-selectedQueuedReports')) || []
    );
  }, []);

  // select Company
  // const handleSelect = ({ data }) => {
  //   if (selectedCompanies.filter(sc => sc.id === data[0].id).length === 0) {
  //     let companies = [...selectedCompanies, data[0]];
  //     updateCompaniesAndSaveToLS(companies);
  //   }
  //   updateTabAndSaveToLS(data[0].id);
  //   if (!openColapse) handleOpen(true);
  // };

  // Select Tab Handler
  const handleTabSelect = tabKey => {
    if (selectedTab !== tabKey) {
      updateTabAndSaveToLS(Number(tabKey));
    }
  };

  // Remove  Tab From Selected Companies
  const removeCompany = (index, id) => {
    let companies = [...selectedCompanies];
    companies.splice(index, 1);
    updateCompaniesAndSaveToLS(companies);
    dispatch(removeReportsFromState({ id: id }));
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = companies => {
    setSelectedCompanies(companies);
    storage.setItem('queuedReports-selectedQueuedReports', companies);
  };

  // update Tabs and save To Local Storage
  const updateTabAndSaveToLS = tabKey => {
    setSelectedTab(tabKey);
    storage.setItem('queuedReports-selectedTab', tabKey);
  };

  // update Selected Tab Menu For get Back to same menu after Redirecting
  const selectTabMenu = menuKey => {
    let index = selectedCompanies.findIndex(sc => sc.id === selectedTab);
    if (index > -1) {
      let companies = selectedCompanies;
      companies[index] = { ...companies[index], selectedMenu: menuKey };
      updateCompaniesAndSaveToLS(companies);
    }
  };

  const filterTypes = Object.assign(
    {},
    ReactDataGrid.defaultProps.filterTypes,
    queuedReportFilterTypeConfig
  );

  const getFilterValue = data => {
    let updatedValue = data.value;

    if (
      data.name === 'createdOn' &&
      (data.operator === 'eq' || data.operator === 'after')
    ) {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = '2000-01-01T08:00:00.000Z';
    } else if (data.operator === 'contains') {
      updatedValue = `%${updatedValue}%`;
    } else if (data.operator === 'startsWith') {
      updatedValue = `${updatedValue}%`;
    } else if (data.operator === 'endsWith') {
      updatedValue = `%${updatedValue}`;
    }
    return updatedValue;
  };

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(v),
          propertyValue2:
            v.name === 'createdOn' &&
            (v.operator === 'before' || v.operator === 'after')
              ? getDateFilterValue(v)
              : '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        start: skip,
        limit: limit,
        filter: JSON.stringify(updatedValue),
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'createdOn'
      };
      return getAllQueuedReportList(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;
        setTotalRecords(totalCount);
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  useEffect(() => {
    !mobileSuggestions && dispatch(fetchMobileSuggestionsAsync());
  }, [mobileSuggestions, dispatch]);

  const dataSource = useCallback(loadData, []);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className={`report-section-wrapper ${loading && 'blux-text'}`}>
            {permissions.allowPortal ? (
              <HeaderToExistingPortal
                msg={t('company.general.oldPortalTag')}
                borderRadius={'0px'}
              />
            ) : null}
            <div className="mobile_view">
              <GridWrapper
                columns={queuedReportTableConfig}
                gridClass={
                  permissions.allowPortal ? 'myreports-table-container' : ''
                }
                limit={limit}
                setLimit={setLimit}
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                defaultFilterValue={queuedReportFilterConfig}
                filterTypes={filterTypes}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
              />
            </div>
          </Col>
        </Row>
      </Container>

      {selectedCompanies.length ? (
        <SidebarRight handleOpen={handleOpen} open={openColapse}>
          {/* <button onClick={() => clearAll()}>Clear All</button> */}
          <TabsContainer
            onTabSelect={e => handleTabSelect(e)}
            activeKey={
              selectedCompanies.filter(sc => sc.id === selectedTab).length
                ? selectedTab
                : selectedCompanies[0].id
            }
          >
            {selectedCompanies.map((company, index) => {
              return (
                <TabComponent
                  tabClassName={'tab-ui'}
                  key={index}
                  eventKey={company.id}
                  title={tabTitle(index, company)}
                  // TODO close button
                  // closeButton={() => {
                  //   console.log(index, 'close tab');
                  // }}
                >
                  <ReportContainer
                    selectMenu={selectTabMenu}
                    selectedCompany={company}
                  />
                </TabComponent>
              );
            })}
          </TabsContainer>
        </SidebarRight>
      ) : null}
    </>
  );
};

export default QueuedReports;

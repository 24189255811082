import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import routes from 'routes';
import HandlePageRoutes from 'routes/HandlePageRoutes';
import { useSelector } from 'react-redux';
import { selectLoading } from 'features/loading/loadingSlice';
import Sidebar from 'components/Sidebar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Home from 'pages/portal/home';
import { isMobile } from 'react-device-detect';
import NotificationComp from 'components/notification/notification';

const PortalLayout = () => {
  // const isAuthenticated = useSelector(selectLogin).isAuthenticated;
  const loading = useSelector(selectLoading);
  // const history = useHistory();
  // let routeFragment = null;
  // // Redirected if not loggedin
  // if (!isAuthenticated) {
  //   routeFragment = (
  //     <Switch>
  //       <Redirect to="/login" />
  //     </Switch>
  //   );
  // } else {
  //   // Show portal routes
  //   routeFragment = (
  //     // <Switch>
  //     <>{HandlePageRoutes(routes, 'portal')}</>
  //     // </Switch>
  //   );
  // }
  return (
    <>
      {loading.show ? (
        <ProgressBar className="global-loader" now={loading.loading} />
      ) : null}
      <div
        className={`main d-flex align-items-stretch ${
          isMobile ? 'mobile' : 'desktop'
        }-wrapper`}
      >
        <NotificationComp />
        <Sidebar />
        <div id="content">
          <div
            className="h-100 full-height-min rounded-inner print-version-wrapper"
            //style={{ pageBreakAfter: 'always' }}
          >
            <Switch>
              <Route
                path={'/'}
                component={Home}
                exact={true}
                key={`home-child`}
              />
              {HandlePageRoutes(routes, 'portal')}
              <Redirect to="/not-found" />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalLayout;

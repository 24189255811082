import { Enums } from 'helpers/dropdown-enums';
import storage from 'helpers/storage';
import moment from 'moment';

export const calculatePickupTime = (
  firstMsgCreatedOn,
  selectedChatCreatedOn
) => {
  let _firstMsg = moment(firstMsgCreatedOn);
  let _selectedChatCreatedOn = moment(selectedChatCreatedOn);
  let duration = moment.duration(_firstMsg.diff(_selectedChatCreatedOn));
  let inSeconds = parseInt(duration.asSeconds());
  return inSeconds;
};

export const calculateIdleTime = lastMsgCreatedOn => {
  let _lastMsg = moment(lastMsgCreatedOn);
  let serverOffset = window.RTC.serverOffset;
  let current = moment().add(serverOffset, 'seconds');
  let duration = moment.duration(current.diff(_lastMsg));
  let idle = parseInt(duration.asSeconds());
  return idle;
};

export const calculateTotalDuration = firstMsgCreatedOn => {
  let _firstMsg = moment(firstMsgCreatedOn);
  let serverOffset = window.RTC.serverOffset;
  let current = moment().add(serverOffset, 'seconds');
  let duration = moment.duration(current.diff(_firstMsg));
  let minutes = duration.minutes();
  let seconds = duration.seconds();
  let timer = `${minutes <= 9 ? '0' + minutes : minutes}:${
    seconds <= 9 ? '0' + seconds : seconds
  }`;
  return timer;
};

export const getCountryCode = chatLocation => {
  fetch('/countryCode.json')
    .then(response => response.json())
    .then(codes => {
      let location = chatLocation;
      let countryISO = location.slice(
        location.indexOf('(') + 1,
        location.indexOf(')')
      );
      let countryObj = codes.filter(country => country.code === countryISO);
      if (countryObj.length) {
        storage.setItem('MD_visitorCountry', countryObj[0]);
      }
    });
};

export const getIsChatEnded = chat => {
  console.log('getIsChatEnded::', chat);
  let isChatEnded = false;
  if (
    chat.userId === Enums.ChatMessageType.System &&
    (chat.isChatEnded || Enums.ChatEndedMessages.includes(chat.text))
  ) {
    isChatEnded = true;
  }

  return isChatEnded;
};

export const showTypingIndicatorInChat = initiatedBy => {
  return initiatedBy !== Enums.InitiatedBy['Facebook'] &&
    initiatedBy !== Enums.InitiatedBy['Sms Static'] &&
    initiatedBy !== Enums.InitiatedBy['Sms Container']
    ? true
    : false;
};

export const mapVisitorInfoInLeadForm = (info, leadFormValues) => {
  let values = {};
  // Update visitor name in lead form
  if (info?.visitorInfoName && !leadFormValues.name) {
    values['name'] = info?.visitorInfoName;
  }

  // Update visitor email in lead form
  if (info?.visitorInfoEmail && !leadFormValues.email) {
    values['email'] = info?.visitorInfoEmail;
  }

  // Update visitor phoneNumber in lead form
  if (info?.visitorInfoPhone && !leadFormValues.phoneNumber) {
    values['phoneNumber'] = info?.visitorInfoPhone;
  }

  return values;
};

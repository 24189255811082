import React, { useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ScrollBar from 'components/Scrollbars';
import { MMDDYYFormat } from 'helpers/generic.methods';
import MessageContainer from './MessageContainer';

const AllMessages = ({ selectedChat, chatById, isChatEnded, userImage }) => {
  const messageListEndRef = useRef(null);

  const scrollToBottom = () => {
    // messageListEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    messageListEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end'
    });
  };

  useEffect(() => {
    //  if new messages updated then scroll to last message
    scrollToBottom();
  }, [chatById?.messages?.length]);

  return (
    <div className="col-md-12 h-100">
      <ScrollBar
        horizontalScroll={false}
        style={{
          height: 'calc(100% - 90px)',
          paddingRight: '10px'
        }}
      >
        <div
          className="chat_box touchscroll chat_box_colors_a mr-3"
          style={{ padding: isMobile ? '0px' : '0px 30px' }}
        >
          <div className="chat-date-time">
            {MMDDYYFormat(selectedChat?.createdOn)}
          </div>
          {chatById &&
            Object.keys(chatById).length &&
            chatById.messages.length > 0 &&
            chatById.messages.map((msg, i) => (
              <MessageContainer
                key={msg.id}
                msg={msg}
                index={i}
                userImage={userImage}
                isChatEnded={isChatEnded}
                messagesLength={chatById.messages.length}
              />
            ))}
          <div ref={messageListEndRef}></div>
        </div>
      </ScrollBar>
    </div>
  );
};

export default AllMessages;

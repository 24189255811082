import { createSlice } from '@reduxjs/toolkit';
import storage from 'helpers/storage';

export const generalReducer = createSlice({
  name: 'general',
  initialState: {
    language: storage.getItem('language') || 'en-US'
  },
  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setLanguage: (state, action) => {
      state.language = action.payload || 'en-US';
      storage.setItem('language', state.language);
    }
  }
});
export const { setLanguage } = generalReducer.actions;
export const stopPropogation = ref => dispatch => {
  /**
   * hide on clicked outside of the element
   */
  return new Promise((resolve, reject) => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        return resolve(true);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export const selectShow = state => state.view;

export const selectLangauge = state => {
  return state.general.language;
};

export default generalReducer.reducer;

import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Form from 'react-bootstrap/Form';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import FieldError from './ui-components/FieldError';
import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import 'static/scss/time-zone.scss';
import dropdownEnums from 'helpers/dropdown-enums';
import { toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from './renderToolTip';

/*
 ** Component for TimeZone field select, edit and save
 */
const DropDownEditable = ({
  // label of the field
  heading,
  // field name
  field,
  // value of the field
  dataProperty,
  // human readable title
  titleText,
  // FormIK error for the specific field
  error,
  // custom error message text
  errorMessage,
  // text to be shown in tooltip
  helperText,
  // boolean: false to make field read-only
  editable,
  // read-only field never editable
  readOnly,
  // action trigger on change
  handleChange,
  materialFields
}) => {
  const [valueDropDowm, setValueDropDowm] = useState(dataProperty);
  const [selectOpen, setSelectOpen] = useState(false);

  const handleOnChange = element => {
    handleOnClick(true);
    setValueDropDowm(element.target.value);
    handleChange(element, field, element.target.value);
  };

  const handleOnClick = value => {
    setSelectOpen(value);
  };
  helperText = helperText || titleText;

  useEffect(() => {
    setValueDropDowm(dataProperty);
  }, [dataProperty]);
  return (
    <>
      {!materialFields && (
        <li>
          <Form.Label title={titleText} htmlFor={field}>
            {heading}
          </Form.Label>
        </li>
      )}

      <li
        className={`${!materialFields && 'd-flex'} list-margin-drop-down`}
        aria-label={titleText}
      >
        <div
          className={`timeZone ${materialFields && 'material-dropdown'} ${
            !editable ? 'has-diabled' : ''
          }`}
        >
          {materialFields && (
            <Form.Label
              title={titleText}
              htmlFor={field}
              style={{ fontSize: '0.75rem' }}
            >
              {heading}
            </Form.Label>
          )}
          <Form.Control
            as="select"
            value={valueDropDowm}
            className={`select ${error ? 'is-invalid' : ''}${
              editable ? '' : 'read-only'
            }${' text-darkgray-1'}`}
            title={valueDropDowm}
            readOnly={!editable || readOnly}
            onChange={e => handleOnChange(e)}
            onClick={e => handleOnClick(!selectOpen)}
            id={field}
            name={field + '_'}
            aria-label={titleText}
            disabled={!editable ? true : false}
          >
            {dropdownEnums.enum_Country.map(value => (
              <option
                key={value.value}
                value={value.name}
                className="text-darkgray-1"
              >
                {value.name}
              </option>
            ))}
          </Form.Control>
          <span className={`select-arrow ${selectOpen ? 'toggled' : ''}`}>
            <UilAngleDown size="24" />
          </span>
        </div>

        {error && (
          <FieldError
            titleText={titleText}
            error={error}
            errorMessage={errorMessage}
          />
        )}
        {helperText && (
          <OverlayTrigger
            placement={toolTipPlacement}
            overlay={renderToolTip(helperText)}
          >
            <UILInfoCircle className="list-icon-after-text ml-1" />
          </OverlayTrigger>
        )}
      </li>
    </>
  );
};
export default DropDownEditable;

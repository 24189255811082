import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { UilComments } from '@iconscout/react-unicons';
import {
  dayAgo,
  getStringInitials,
  truncateText
} from 'helpers/generic.methods';
import TypingIndicator from '../TypingIndicator';
import ActionButtons from './ActionButtons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChatId,
  getSelectedChatLastMessage,
  getSelectedChatStarted,
  getSelectedChatTypingIndicator,
  getShelvedChatList,
  getVisitorInfoName,
  removeChatFromShelved
} from 'features/messagingChats/messagingChats';
import { Badge, OverlayTrigger } from 'react-bootstrap';
import CopyButton from 'CellRenderer/CopyButton';
import { getUnreadMsgsCountByChatId } from 'features/messagingChats/messagingChats';
import './index.scss';
import { getPlainStringFromHTMLElementAsString } from 'helpers/validate';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import { Enums } from 'helpers/dropdown-enums';
import IdleCounter from './IdleCounter';
import { showTypingIndicatorInChat } from '../Helper';

const ChatListItem = ({
  chat,
  onChatClickHandler,
  showActionBtns,
  eventKey,
  isOperator = false,
  isAgent = false
}) => {
  const lastMessage = useSelector(getSelectedChatLastMessage);
  const isTyping = useSelector(getSelectedChatTypingIndicator);
  const isChatStarted = useSelector(getSelectedChatStarted);
  const selectedChatId = useSelector(getSelectedChatId);
  const getVisitorName = useSelector(getVisitorInfoName);
  const unreadMsgCount = useSelector(getUnreadMsgsCountByChatId);
  const shelvedChatList = useSelector(getShelvedChatList);
  let unreadCount = unreadMsgCount(chat.getId());
  let dispatch = useDispatch();
  const [chatInitialTime, setChatInitialTime] = useState('');

  const handleShelvedChatsOnChatEnded = chatId => {
    if (
      shelvedChatList.length &&
      shelvedChatList.some(chat => chat.getId() === chatId)
    ) {
      dispatch(removeChatFromShelved({ id: chatId }));
    }
  };

  const handleChatInitialTime = chat => {
    let lastMessageCreatedOn = lastMessage(chat.getId())?.createdOn;
    if (lastMessageCreatedOn) {
      setChatInitialTime(dayAgo(lastMessageCreatedOn));
    } else {
      setChatInitialTime(dayAgo(chat.getData().createdOn));
    }
  };

  return (
    <ListGroup.Item
      className={`chat-item m-2 ${
        selectedChatId === chat.getId() ? 'active-list' : 'non-active-list'
      }`}
      key={chat.getId()}
      onClick={() =>
        !showActionBtns || isChatStarted(chat.getId())
          ? onChatClickHandler(chat)
          : null
      }
    >
      <div className="left-section p-2">
        <div
          className={`chatname d-flex ${
            eventKey !== '0' ? 'justify-content-between' : ''
          }`}
        >
          <span className="d-flex align-items-center position-relative">
            <div>
              <UilComments className="chat-icon" size={25} />
            </div>
            &nbsp;
            {/* InitiatedBy Badge  */}
            <OverlayTrigger
              placement={'auto'}
              overlay={renderToolTip(
                `Initiated By: ${chat.getChatInitiatedBy()}`
              )}
            >
              <span
                className="text-left language"
                // title={`InitiatedBy:${chat.getChatInitiatedBy()}`}
              >
                <Badge bg="secondary" className="lang-pill">
                  {getStringInitials(chat.getChatInitiatedBy())}
                </Badge>
              </span>
            </OverlayTrigger>
            &nbsp;
            {/* Language Badge  */}
            {/* as per MD-91 Instructions */}
            {/* <span className="text-left language" title="Language">
              <Badge bg="secondary" className="lang-pill">
                {chat.getLanguage().toUpperCase()}
              </Badge>
            </span> */}
            <OverlayTrigger
              // show={isAgent ? undefined : false}
              placement={'auto'}
              overlay={renderToolTip(
                isAgent
                  ? chat.getCompanyName()
                  : isOperator
                  ? getVisitorName(chat) || 'Chat'
                  : ''
              )}
            >
              <span className="chatname-title">
                {isAgent
                  ? truncateText(chat.getCompanyName(), 12)
                  : isOperator
                  ? truncateText(getVisitorName(chat), 12) || 'Chat'
                  : ''}
              </span>
            </OverlayTrigger>
            &nbsp;-
            {/* Id with copy button */}
            <span className="copy-button">
              <CopyButton
                copySpecificText={`${chat.getId()} - ${chat
                  .getLanguage()
                  .toUpperCase()} - ${chat.getCompanyName()}`}
                value={chat.getId()}
              />
            </span>
          </span>
          {/* Idle timer badge */}

          <IdleCounter
            chat={chat}
            lastMessage={lastMessage}
            handleShelvedChatsOnChatEnded={handleShelvedChatsOnChatEnded}
            eventKey={eventKey}
            handleChatInitialTime={handleChatInitialTime}
          />
          {/* button */}
          {showActionBtns && !isChatStarted(chat.getId()) && (
            <ActionButtons onAccept={onChatClickHandler} chat={chat} />
          )}
        </div>
        <div className="msg-time w-100 d-flex justify-content-between align-items-center">
          {eventKey != 0 ? (
            <>
              <span className="text-left  message d-flex align-items-center">
                {unreadCount ? (
                  <Badge
                    pill
                    bg="secondary"
                    className="unread-msg-count rounded-circle mr-1"
                  >
                    {unreadCount}
                  </Badge>
                ) : null}
                {!isTyping(chat.getId()) ||
                !showTypingIndicatorInChat(chat?.data?.initiatedBy) ? (
                  truncateText(
                    getPlainStringFromHTMLElementAsString(
                      lastMessage(chat.getId()).text
                    ),
                    25
                  )
                ) : //  typing indicator will be show when visitor is typing
                showTypingIndicatorInChat(chat?.data?.initiatedBy) ? (
                  <TypingIndicator
                    msg={'typing'}
                    typing={isTyping(chat.getId())}
                    size={5}
                    containerStyles={{ marginRight: '12px' }}
                  />
                ) : null}
              </span>
              <span className="text-right date-time">{chatInitialTime}</span>
            </>
          ) : null}
          {/* Accept/Ignore buttons */}
          {/* {showActionBtns && !isChatStarted(chat.getId()) && (
            <ActionButtons onAccept={onChatClickHandler} chat={chat} />
          )} */}
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default ChatListItem;

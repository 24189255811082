import React, { useEffect, useState } from 'react';
import { UilMessage } from '@iconscout/react-unicons';
import UilPhone from '@iconscout/react-unicons/icons/uil-phone';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import * as _ from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLeadFormUserCredentials,
  selectIsPhoneNumberFromFieldChange,
  setIsPhoneNumberFromFieldChange,
  setLeadFormUserCredentials
} from 'features/lead/leadSlice';
import { Form, Button, InputGroup, Badge } from 'react-bootstrap';
import { errors } from 'helpers/variables';
import { useFormik } from 'formik';
import {
  callConnectFormSchema,
  leadFormSchema
} from 'helpers/schemas/leadsSchemas/leadFormSchemas';
import {
  getChatInfoById,
  getSelectedChat,
  getUnsentLeads,
  setChatInfoDataInChats
} from 'features/messagingChats/messagingChats';
import { selectRoles } from 'features/roles/rolesSlice';

import dropdownEnums, { Enums } from 'helpers/dropdown-enums';
import {
  UilCalling,
  UilPhoneTimes,
  UilCheck,
  UilMissedCall,
  UilStopCircle,
  UilOutgoingCall
} from '@iconscout/react-unicons';
import InputWithStartIcon from 'components/InputWithStartIcon';
import { getEnumTextByValue } from 'helpers/generic.methods';

import './LeadForm.scss';
import ButtonWithConfirmLoader from 'components/ButtonWithConfimLoader';
import { toast } from 'react-toastify';

const LeadForm = ({
  id,
  title,
  hideFields,
  fromHandler,
  loading,
  onClickUnsentLeadHandler,
  sending,
  callStatus
}) => {
  const leadFormUserCredentials = useSelector(getLeadFormUserCredentials);
  const selectedChat = useSelector(getSelectedChat);
  const unsentLeads = useSelector(getUnsentLeads);
  const isPhoneNumberFromFieldChange = useSelector(
    selectIsPhoneNumberFromFieldChange
  );
  const {
    roles: { isOperator }
  } = useSelector(selectRoles);
  const { data: chatInfo } = useSelector(getChatInfoById(selectedChat.getId()));
  const dispatch = useDispatch();

  // lead form initial state
  const [leadFormInitialValues, setleadFormInitialValues] = useState({
    ...leadFormUserCredentials
  });

  useEffect(() => {
    if (
      leadFormUserCredentials.name &&
      leadFormInitialValues.name !== leadFormUserCredentials.name
    ) {
      // we will update chats{} chatInfo visitorInfoName
      dispatch(
        setChatInfoDataInChats({
          chatId: selectedChat.getId(),
          chatInfoData: { visitorInfoName: leadFormUserCredentials.name }
        })
      );
    }
    // update initial values for email or phone sent by the visitor
    setleadFormInitialValues({
      ...leadFormUserCredentials,
      phoneNumberFrom: selectedChat?.data?.quickConnectPhoneNumber
    });
  }, [leadFormUserCredentials]);

  /** Lead form submission handler */
  const leadFormHandler = async (leadFormData, { resetForm }) => {
    // Add companyId and chatId to form data
    leadFormData['companyId'] = selectedChat.getCompanyId();
    leadFormData['chatId'] = selectedChat.getId();
    fromHandler(leadFormData);
  };

  /** Init Formik */
  const LeadFormIk = useFormik({
    enableReinitialize: true,
    initialValues: leadFormInitialValues,
    validationSchema:
      id === 'call-connect' ? callConnectFormSchema(false) : leadFormSchema(),
    onSubmit: leadFormHandler
  });

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = e => {
    Object.keys(leadFormInitialValues).forEach(key => {
      if (e.target.name === 'phoneNumberFrom') {
        // show warning on edit company number
        if (!isPhoneNumberFromFieldChange) {
          onEditFromNumber();
          dispatch(setIsPhoneNumberFromFieldChange(true));
        }
      }

      if (e.target.name === key) {
        dispatch(setLeadFormUserCredentials({ [key]: e.target.value }));
      }

      if (
        e.target.name === key &&
        (key === 'name' || key === 'email' || key === 'phoneNumber')
      ) {
        // we will update chats{} chatInfo visitorInfoName, visitorInfoEmail,visitorInfoPhone etc
        let infoKey = key[0]?.toUpperCase() + key.slice(1);
        if (key === 'phoneNumber') {
          // remove 'Number' from key, so that it matches chatInfo object key 'visitorInfoPhone'
          infoKey = infoKey.replace('Number', '');
        }
        dispatch(
          setChatInfoDataInChats({
            chatId: selectedChat.getId(),
            chatInfoData: { [`visitorInfo${infoKey}`]: e.target.value }
          })
        );
      }
    });
    LeadFormIk.handleChange(e);
  };

  //  Save/Remove from unsent leads list
  const unsentLeadHandler = bool => {
    let data = { chatId: selectedChat.getId(), isLead: bool };
    onClickUnsentLeadHandler(data);
  };

  // on edit from number
  const onEditFromNumber = _(() => {
    // toast.warning('You are about to change call connect number');
    // changed as per Owais said
    toast.warning('Warning: The company call connect number has been changed.');
  }, 1000);
  return (
    <>
      <div className="lead-form-section">
        <h5>{title} Form</h5>
        <form
          className="form"
          onSubmit={LeadFormIk.handleSubmit}
          autoComplete="off"
        >
          <Form.Group controlId="name" className="normal-form-group mb-0">
            <Form.Control
              label="name"
              name="name"
              onChange={e => handleOnChange(e)}
              value={LeadFormIk.values['name']}
              placeholder="Enter name"
            />
            {LeadFormIk.errors['name'] && LeadFormIk.touched['name'] && (
              <p className="text-red-1 field-error">
                {errors.fieldCorrection('name')}
              </p>
            )}
          </Form.Group>
          {!hideFields?.email && (
            <Form.Group controlId="email" className="normal-form-group mb-0">
              <Form.Control
                label="email"
                name="email"
                onChange={e => handleOnChange(e)}
                value={LeadFormIk.values['email']}
                placeholder="Email"
              />
              {LeadFormIk.errors['email'] && LeadFormIk.touched['email'] && (
                <p className="text-red-1 field-error">
                  {LeadFormIk.errors['email']}
                </p>
              )}
            </Form.Group>
          )}
          <Form.Group
            controlId="phoneNumber"
            className="normal-form-group mb-0"
          >
            <Form.Control
              label="phoneNumber"
              name="phoneNumber"
              onChange={e => handleOnChange(e)}
              value={LeadFormIk.values['phoneNumber']}
              placeholder="Phone number"
            />
            {LeadFormIk.errors['phoneNumber'] &&
              LeadFormIk.touched['phoneNumber'] && (
                <p className="text-red-1 field-error">
                  {LeadFormIk.errors['phoneNumber']}
                </p>
              )}
          </Form.Group>
          {!hideFields?.phoneFrom && (
            <InputWithStartIcon
              startTextOrIcon="From:"
              endTextOrIcon={null}
              name="phoneNumberFrom"
              label="phoneNumberFrom"
              placeholder="From Phone number"
              handleOnChange={handleOnChange}
              value={LeadFormIk.values['phoneNumberFrom']}
              disabled={!isOperator}
            >
              {LeadFormIk.errors['phoneNumberFrom'] &&
                LeadFormIk.touched['phoneNumberFrom'] && (
                  <p className="text-red-1 field-error">
                    {LeadFormIk.errors['phoneNumberFrom']}
                  </p>
                )}
            </InputWithStartIcon>
          )}
          {!hideFields?.reason && (
            <Form.Group controlId="reason" className="normal-form-group mb-0">
              <Form.Control
                as="textarea"
                label="reason"
                name="reason"
                onChange={e => handleOnChange(e)}
                value={LeadFormIk.values['reason']}
                placeholder="Comments"
              />
              {LeadFormIk.errors['reason'] && LeadFormIk.touched['reason'] && (
                <p className="text-red-1 field-error">
                  {LeadFormIk.errors['reason']}
                </p>
              )}
            </Form.Group>
          )}

          {!hideFields?.type && (
            <Form.Group controlId="type" className="normal-form-group mb-0">
              <div className="radio-as-button-wrapper row no-gutters mt-1">
                {dropdownEnums.enum_LeadType
                  .filter(item => item?.name?.toLowerCase() !== 'all')
                  .map((type, i) => (
                    <div
                      key={i}
                      className={`col px-0 ${i === 3 ? ' mr-0' : ' mr-2'}`}
                    >
                      <CustomRadio
                        selectedValue={LeadFormIk.values['type']}
                        value={type.name}
                        id={id ? `${id}-${type.name}` : type.name}
                        label={type.name}
                        name="type"
                        handleOnChange={e => handleOnChange(e)}
                      />
                    </div>
                  ))}
              </div>
            </Form.Group>
          )}

          {/* call connect status  */}
          {id === 'call-connect' && (
            <span>
              <span className="d-flex align-items-center call-connect-status mt-2">
                Status:&nbsp;
                <Badge
                  bg="primary"
                  pill
                  className={`call-status-badge d-flex align-items-center ${CallConnectionClass(
                    callStatus
                  )}`}
                >
                  {CallConnectionIcon(callStatus)}
                  <b className="ml-1">
                    {getEnumTextByValue(Enums.CallConnectStatus, callStatus)}
                  </b>
                </Badge>
              </span>
              <ButtonWithConfirmLoader
                title="Connect"
                loading={loading}
                isDisabled={loading}
                onConfirm={() => console.log('on confirm---')}
                buttonIcon={<UilPhone className="mr-1" />}
                loaderDuration={3000}
                classes="mt-16 mb-24 float-right"
                variant={'primary'}
                confirmVariant={'success'}
                confirmButtonType={'submit'}
              />
            </span>
          )}

          {/* save/remove from unsent leads buttons */}
          {id === 'lead-form' && (
            <span className="lead-form-footer">
              {chatInfo?.leadId &&
              chatInfo?.leadSubmitMessage &&
              !chatInfo?.sendingLead ? (
                <label className="submit-lead mt-2">
                  {/* Chat lead has been sent. */}
                  {chatInfo?.leadSubmitMessage}
                </label>
              ) : null}
              <span className="lead-form-buttons">
                {selectedChat?.data?.isLead ||
                (unsentLeads.length &&
                  unsentLeads.some(
                    chat => chat.chatId === selectedChat?.getId()
                  )) ? (
                  <Button
                    disabled={sending}
                    type="button"
                    variant="primary"
                    className="mt-16 mb-24 mr-1"
                    size="md"
                    onClick={() => unsentLeadHandler(false)}
                  >
                    {sending && (
                      <i className="uil uil-spinner spinner mr-1 small"></i>
                    )}
                    Unmark Lead
                  </Button>
                ) : (
                  <Button
                    disabled={sending}
                    type="button"
                    variant="primary"
                    className="mt-16 mb-24 mr-1"
                    size="md"
                    onClick={() => unsentLeadHandler(true)}
                  >
                    {sending && (
                      <i className="uil uil-spinner spinner mr-1 small"></i>
                    )}
                    Mark as Lead
                  </Button>
                )}

                <Button
                  disabled={loading || chatInfo?.sendingLead}
                  type="submit"
                  variant="primary"
                  className="mt-16 mb-24  d-flex align-items-center justify-content-center"
                  size="md"
                  onClick={() => false}
                >
                  {loading || chatInfo?.sendingLead ? (
                    <i className="uil uil-spinner spinner mr-1 small"></i>
                  ) : (
                    <UilMessage className="mr-1" />
                  )}
                  {chatInfo?.leadId && !chatInfo?.sendingLead
                    ? 'Re-Submit'
                    : 'Submit'}
                </Button>
              </span>
            </span>
          )}
        </form>
      </div>
    </>
  );
};

const CallConnectionIcon = status => {
  switch (status) {
    case Enums.CallConnectStatus.Ringing:
    case Enums.CallConnectStatus['In Progress']:
      return <UilCalling size={14} />;
    case Enums.CallConnectStatus.Failed:
    case Enums.CallConnectStatus.Busy:
      return <UilPhoneTimes size={14} />;
    case Enums.CallConnectStatus.Canceled:
    case Enums.CallConnectStatus['No Answer']:
      return <UilMissedCall size={14} />;
    case Enums.CallConnectStatus.Completed:
      return <UilCheck size={14} />;
    case Enums.CallConnectStatus.Idle:
      return <UilStopCircle size={14} />;
    case Enums.CallConnectStatus.Queued:
      return <UilOutgoingCall size={14} />;
    default:
      return '';
  }
};

const CallConnectionClass = status => {
  switch (status) {
    case Enums.CallConnectStatus.Ringing:
    case Enums.CallConnectStatus['In Progress']:
      return 'badge-warning';
    case Enums.CallConnectStatus.Failed:
    case Enums.CallConnectStatus.Busy:
      return 'badge-danger';
    case Enums.CallConnectStatus.Canceled:
    case Enums.CallConnectStatus['No Answer']:
      return 'badge-secondary';
    case Enums.CallConnectStatus.Completed:
      return 'badge-success';
    case Enums.CallConnectStatus.Queued:
      return 'badge-primary';
    default:
      return 'badge-primary';
  }
};

// { value: 1, name: 'Queued' },
// { value: 9, name: 'Scheduled' },
// { value: 10, name: 'Initializing' }

export default LeadForm;

import {
  getSplashScreenMeta,
  pushSplashScreenMeta
} from 'features/messagingChats/messagingChats';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import store from 'store';
import RestrictionModal from '../RestrictionModal';
import './SplashScreen.scss';

export const IncreaseProgress = meta => {
  setTimeout(() => {
    if (meta.hasCompleted) {
      meta.count = 100;
    }
    store.dispatch(pushSplashScreenMeta(meta));
  }, 1500);
};

const SplashScreen = ({ chatOperations }) => {
  let splashScreenMeta = useSelector(getSplashScreenMeta);
  if (splashScreenMeta.count >= 100) {
    setTimeout(() => {
      window.RTC.hasLoaded = true;
    }, 2000);
  }
  return (
    <>
      <div className="splash-screen" style={{ position: 'relative' }}>
        <RestrictionModal
          status={!chatOperations.status}
          message={chatOperations.error.split('!!')[1]}
          heading={chatOperations.error.split('!!')[0]}
        />
        <p style={{ textAlign: 'center' }}>
          Please wait a moment while Messaging Dashboard is loading...
        </p>
        <p>{splashScreenMeta.text}</p>
        <ProgressBar
          animated
          now={splashScreenMeta.count}
          style={{ width: '50%' }}
        />
      </div>
    </>
  );
};

export default SplashScreen;

import { Enums, TimeIntervalsEnum } from 'helpers/dropdown-enums';
import { generateDurationOptions } from 'helpers/generic.methods';
import { initializeVissibleCards } from 'pages/portal/dashboard/filters/constants';
const RoleEngine = (companyRelationship, userType, userRoles, user = null) => {
  switch (companyRelationship) {
    case Enums.Relationship.Administrator:
      return handleAdministrator(userType, userRoles, user);
    case Enums.Relationship.Partner:
      return handlePartner(userType, userRoles, user);
    case Enums.Relationship.Customer:
      return handleCustomer(userType, userRoles, user);
    default:
      return handlePartner(userType, userRoles, user);
  }
};
const attachUserSpecificRoleInfo = (
  roles,
  userType,
  hasRelationshipAdministrator,
  user
) => {
  // attaching isOperator Info.
  roles.isOperator = userType === Enums.UserType.Operator;
  // attaching isAgent Info.
  roles.isAgent = userType === Enums.UserType.Agent;
  roles.isAdminCompany = !!hasRelationshipAdministrator;
  roles.isDefaultUser = userType === Enums.UserType.Default;
  roles.messaging.allowMessagingAccess = !!user?.NewMessagingDashboardEnabled;
  roles.messaging.chatOperations = getChatOperationsInfo(user);
  return roles;
};

const getChatOperationsInfo = user => {
  const { UserType, NewMessagingDashboardEnabled } = user;
  let error = '';
  let status = true;
  if (UserType === Enums.UserType.Default && NewMessagingDashboardEnabled) {
    error =
      'Default user warning !! Being a default user you are not obliged to receive the chats.';
    status = false;
  }

  return { error, status };
};

const handleAdministrator = (userType, userRoles, user) => {
  let roles = getDefaultRoles();
  roles.messaging.allowMessaging = true;
  if (userType === Enums.UserType.Agent) {
    roles.analytics.view = false;
  }
  roles = AdministratorPermissions.Get(roles, userRoles);
  roles = attachUserSpecificRoleInfo(roles, userType, true, user);
  return roles;
};
const handlePartner = (userType, userRoles, user) => {
  let roles = getDefaultRoles();
  switch (userType) {
    case Enums.UserType.Agent:
      roles.analytics.view = false;
      roles.messaging.allowMessaging = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles = PartnerPermissions.Agent(roles, userRoles);
      break;
    case Enums.UserType.Operator:
      roles.messaging.allowMessaging = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles = PartnerPermissions.Operator(roles, userRoles);
      break;
    case Enums.UserType.Default:
      // Dashboard is not for Default users of Customers & Partners
      roles.messaging.allowMessaging = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Day,
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles = PartnerPermissions.Default(roles, userRoles);
      break;
    default:
      break;
  }
  roles = attachUserSpecificRoleInfo(roles, userType, false, user);
  return roles;
};
const handleCustomer = (userType, userRoles, user) => {
  let roles = getDefaultRoles();
  roles.analytics.filters.allowCompanyKey = false;
  roles.analytics.filters.allowBusinessType = false;
  switch (userType) {
    case Enums.UserType.Agent:
      roles.analytics.view = false;
      roles.messaging.allowMessaging = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles = CustomerPermissions.Agent(roles, userRoles);
      break;
    case Enums.UserType.Operator:
      roles.messaging.allowMessaging = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles = CustomerPermissions.Operator(roles, userRoles);
      break;
    case Enums.UserType.Default:
      // Dashboard is not for Default users of Customers & Partners
      roles.messaging.allowMessaging = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Day,
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles = CustomerPermissions.Default(roles, userRoles);
      break;
    default:
      break;
  }
  roles = attachUserSpecificRoleInfo(roles, userType, false, user);
  return roles;
};
const AdministratorPermissions = {
  Get: (roles, userRoles) => {
    roles.switch.allowPortal = true;
    if (userRoles.includes(Enums.Roles.SuperAdministrator)) {
      roles = applySuperAdminRoles(roles);
    }
    if (userRoles.includes(Enums.Roles.Administrator)) {
      const adminComponents = superAdministratorGrantedRoles;
      roles = allowGrantedRolesForView(roles, adminComponents);
      roles = allowGrantedRolesForEdit(roles, adminComponents);
    }
    // UIUX-610 - If the userType is Agent and he has role among anyone of these i.e. Admin, SuperAdmin, Limited Admin, then we will show the Analytics
    if (userRoles.includes(Enums.Roles.LimitedAdministrator)) {
      const limitedAdminComponents = [
        'analytics',
        'lead',
        'report',
        'onBoarding'
      ];
      roles = allowGrantedRolesForView(roles, limitedAdminComponents);
      roles = allowGrantedRolesForEdit(roles, limitedAdminComponents);
    }
    if (
      userRoles.includes(Enums.Roles.PartnerAdministrator) ||
      userRoles.includes(Enums.Roles.LimitedAdministrator)
    ) {
      roles = allowGrantedRolesForView(roles, []);
      roles = allowGrantedRolesForEdit(roles, []);
    }
    if (checkIfAnySupportedRoleApplied(userRoles)) {
      roles = setupLegacyRoles(roles, userRoles);
    }
    return roles;
  }
};
const PartnerPermissions = {
  Get: (roles, userRoles, allowGrantedRolesByDefault) => {
    const grantedViewRoles = ['company', 'chat', 'lead', 'onBoarding'];
    // Admin can edit company
    const grantedEditRolesForAdministrator = ['company'];
    let grantedEditRoles = ['onBoarding'];
    //add company editor for operator and default roles.
    // UIUX-786 condition inplemented
    if (!userRoles.includes(Enums.Roles.CompanyViewer)) {
      if (allowGrantedRolesByDefault) {
        grantedEditRoles.push('company');
      }
    }
    // UIUX-786 end

    if (userRoles.includes(Enums.Roles.SuperAdministrator)) {
      roles = applySuperAdminRoles(roles);
    }

    if (userRoles.includes(Enums.Roles.PartnerSupervisor)) {
      roles = allowGrantedRolesForView(roles, [...grantedViewRoles]);
      roles = allowGrantedRolesForEdit(roles, ['company', ...grantedEditRoles]);
      roles.switch.allowPortal = true;
    }
    // UIUX-610 - If the userType is Agent and he has role among anyone of these i.e. Admin, SuperAdmin, Limited Admin, then we will show the Analytics
    if (
      userRoles.includes(Enums.Roles.LimitedAdministrator) ||
      userRoles.includes(Enums.Roles.Administrator)
    ) {
      roles = allowGrantedRolesForView(roles, ['analytics']);
      roles = allowGrantedRolesForEdit(roles, [
        ...grantedEditRolesForAdministrator,
        ...grantedEditRoles
      ]);
      roles.switch.allowPortal = true;
    }
    if (
      userRoles.includes(Enums.Roles.PartnerAdministrator) ||
      userRoles.includes(Enums.Roles.LimitedAdministrator)
    ) {
      roles = allowGrantedRolesForView(roles, [...grantedViewRoles]);
      roles = allowGrantedRolesForEdit(roles, [...grantedEditRoles]);
      roles.switch.allowPortal = true;
    }
    if (
      allowGrantedRolesByDefault ||
      checkIfAnySupportedRoleApplied(userRoles)
    ) {
      roles.switch.allowPortal = true;
      roles = allowGrantedRolesForView(roles, [...grantedViewRoles]);
      roles = allowGrantedRolesForEdit(roles, [...grantedEditRoles]);
    }
    roles = setupLegacyRoles(roles, userRoles);
    return roles;
  },
  Agent: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return PartnerPermissions.Get(roles, userRoles, false);
  },
  Operator: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return PartnerPermissions.Get(roles, userRoles, true);
  },
  Default: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return PartnerPermissions.Get(roles, userRoles, true);
  }
};
const CustomerPermissions = {
  Get: (roles, userRoles, allowGrantedRolesByDefault) => {
    roles.analytics.graphs = {
      ...roles.analytics.graphs,
      // 'Chats vs Leads': false,    // comented Charts will be vissible
      // 'Features Usage': false,
      // 'Chat Referrals': false,
      'Chat Stats By': false,
      'Accounts Activation': false,
      'Top Inactive Billers': false,
      'Top/Bottom Lead Accounts': false,
      'Budget/Pacing': false,
      // 'Agent Images CTR': false,
      Accounts: false
    };

    roles.analytics.filters = {
      ...roles.analytics.filters,
      allowCompanyKey: false,
      allowBusinessType: false
    };
    //add company editor for operator and default roles.
    const grantedViewRoles = ['chat', 'lead'];
    const grantedEditRoles = [];
    // Admin can edit company
    const grantedEditRolesForAdministrator = ['company'];
    // UIUX-786 condition inplemented
    if (!userRoles.includes(Enums.Roles.CompanyViewer)) {
      if (allowGrantedRolesByDefault) {
        grantedViewRoles.push('company');
        grantedEditRoles.push('company');
      }
    }
    // UIUX-786 end--

    if (userRoles.includes(Enums.Roles.SuperAdministrator)) {
      roles = applySuperAdminRoles(roles);
    }
    // UIUX-610 - If the userType is Agent and he has role among anyone of these i.e. Admin, SuperAdmin, Limited Admin, then we will show the Analytics
    if (
      userRoles.includes(Enums.Roles.LimitedAdministrator) ||
      userRoles.includes(Enums.Roles.Administrator)
    ) {
      roles = allowGrantedRolesForView(roles, ['analytics']);
      roles = allowGrantedRolesForEdit(roles, [
        ...grantedEditRolesForAdministrator,
        ...grantedEditRoles
      ]);
      roles.switch.allowPortal = true;
    }
    if (
      userRoles.includes(Enums.Roles.PartnerSupervisor) ||
      userRoles.includes(Enums.Roles.PartnerAdministrator) ||
      userRoles.includes(Enums.Roles.LimitedAdministrator)
    ) {
      roles = allowGrantedRolesForView(roles, grantedViewRoles);
      roles = allowGrantedRolesForEdit(roles, grantedEditRoles);
      roles.switch.allowPortal = true;
    }
    if (userRoles.includes(Enums.Roles.Administrator)) {
      roles = allowGrantedRolesForView(roles, [
        'onBoarding',
        ...grantedViewRoles
      ]);
      roles = allowGrantedRolesForEdit(roles, [
        'onBoarding',
        ...grantedEditRoles
      ]);
      roles.switch.allowPortal = true;
    }

    if (
      allowGrantedRolesByDefault ||
      checkIfAnySupportedRoleApplied(userRoles)
    ) {
      roles.switch.allowPortal = true;
      roles = allowGrantedRolesForView(roles, [...grantedViewRoles]);
      roles = allowGrantedRolesForEdit(roles, [...grantedEditRoles]);
    }
    roles = setupLegacyRoles(roles, userRoles);
    return roles;
  },
  Agent: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return CustomerPermissions.Get(roles, userRoles, false);
  },
  Operator: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return CustomerPermissions.Get(roles, userRoles, true);
  },
  Default: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return CustomerPermissions.Get(roles, userRoles, true);
  }
};
const checkIfAnySupportedRoleApplied = userRoles => {
  let appliedRoles = supportedRoles.filter(element =>
    userRoles.includes(element)
  );
  if (appliedRoles.length > 0) {
    return true;
  }
  return false;
};

const customRoles = [
  Enums.Roles.CompanyViewer,
  Enums.Roles.ChatViewer,
  Enums.Roles.LeadViewer,
  Enums.Roles.ProvisioningRequestViewer,
  Enums.Roles.Reporter // Reporter is not Legacy
];
const legacyRoles = [Enums.Roles.Reporter];
const supportedRoles = [...customRoles, ...legacyRoles];
// UIUX-610 - If the userType is Agent and he has role among anyone of these i.e. Admin, SuperAdmin, Limited Admin, then we will show the Analytics
const superAdministratorGrantedRoles = [
  'analytics',
  'company',
  'lead',
  'chat',
  'report',
  'onBoarding'
];
const applySuperAdminRoles = roles => {
  roles = allowGrantedRolesForView(roles, superAdministratorGrantedRoles);
  roles = allowGrantedRolesForEdit(roles, superAdministratorGrantedRoles);
  roles.switch.allowPortal = true;
  return roles;
};
const setupLegacyRoles = (roles, userRoles) => {
  if (userRoles.includes(Enums.Roles.CompanyViewer)) {
    roles = allowGrantedRolesForView(roles, ['company']);
    let writePermissions = [];
    userRoles.includes(Enums.Roles.CompanyEditor) &&
      writePermissions.push('company');
    roles = allowGrantedRolesForEdit(roles, writePermissions);
  }
  if (userRoles.includes(Enums.Roles.ChatViewer)) {
    roles = allowGrantedRolesForView(roles, ['chat']);
    let writePermissions = [];
    userRoles.includes(Enums.Roles.ChatEditor) && writePermissions.push('chat');
    roles = allowGrantedRolesForEdit(roles, writePermissions);
  }
  if (userRoles.includes(Enums.Roles.LeadViewer)) {
    roles = allowGrantedRolesForView(roles, ['lead']);
    let writePermissions = [];
    userRoles.includes(Enums.Roles.LeadEditor) && writePermissions.push('lead');
    roles = allowGrantedRolesForEdit(roles, writePermissions);
  }
  if (userRoles.includes(Enums.Roles.ProvisioningRequestViewer)) {
    roles = allowGrantedRolesForView(roles, ['onBoarding']);
    let writePermissions = [];
    userRoles.includes(Enums.Roles.ProvisioningRequestEditor) &&
      writePermissions.push('onBoarding');
    roles = allowGrantedRolesForEdit(roles, writePermissions);
  }
  if (userRoles.includes(Enums.Roles.Reporter)) {
    roles = allowGrantedRolesForView(roles, ['report']);
    roles = allowGrantedRolesForEdit(roles, ['report']);
    roles.switch.allowPortal = true;
  }
  if (userRoles.includes(Enums.Roles.ContactInformationEditor)) {
    roles.onBoarding.contactInformationEditor = true;
    roles.switch.allowPortal = true;
  }
  return roles;
};
const allowGrantedRolesForView = (roles, allowedRoleArray) => {
  allowedRoleArray.forEach(roleName => {
    roles[roleName].view = true;
  });
  return roles;
};
const allowGrantedRolesForEdit = (roles, allowedRoleArray) => {
  allowedRoleArray.forEach(roleName => {
    roles[roleName].edit = true;
  });
  return roles;
};
const getDefaultRoles = () => {
  return {
    switch: {
      allowPortal: false
    },
    messaging: {
      allowMessaging: false,
      allowMessagingAccess: false,
      chatOperations: { error: '', status: false }
    },
    home: {
      view: true
    },
    analytics: {
      view: true,
      quickStats: true,
      graphs: {
        ...initializeVissibleCards(true)
      },
      filters: {
        allowFeatures: true,
        allowDevices: true,
        allowUser: true,
        allowCompanyKey: true,
        //  UIUX-942 - Temporary hide from filter panel
        allowBusinessType: false,
        allowHours: true
      },
      durationOptions: generateDurationOptions([
        TimeIntervalsEnum.Day,
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ])
    },
    company: {
      view: false,
      edit: false
    },
    chat: {
      view: false,
      edit: false
    },
    lead: {
      view: false,
      edit: false
    },
    report: {
      view: false,
      edit: false
    },
    onBoarding: {
      view: false,
      edit: false,
      contactInformationEditor: false
    }
  };
};

export default RoleEngine;

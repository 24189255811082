import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getAllMyReportList,
  removeReportsFromState,
  removeAllReportsFromState
} from 'features/report/reportsSlice';
import { Container, Row, Col } from 'react-bootstrap';
import {
  fetchMobileSuggestionsAsync,
  selectMobileSuggestions
} from 'features/fetchGeneralData/fetchGeneralDataOnAuth';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { myReportTableConfig } from 'utils/tableConfig';
import {
  myReportFilterConfig,
  myReportFilterTypeConfig
} from 'utils/filterConfig';
import HeaderToExistingPortal from 'components/headerToExistingPortal';
import config from 'config';
import { selectRoles } from 'features/roles/rolesSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GridWrapper } from '../../../../components/GridWrapper';

const MyReports = ({
  setDataLimit,
  setTotalRecords,
  setLoading,
  loading,
  selectedReports,
  setOpenColapse,
  selectedData,
  updateCompaniesAndSaveToLS,
  updateTabAndSaveToLS,
  totalRecords
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobileSuggestions = useSelector(selectMobileSuggestions);
  const { roles } = useSelector(selectRoles);
  const permissions = (roles && roles.switch) || {};

  // Colapsable panel Open/Close Handler
  const handleOpen = status => {
    setOpenColapse(status);
  };

  // Sppend Close button to TabTitle
  // const tabTitle = (index, company) => (
  //   <span>
  //     {company.name}
  //     <span
  //       className="close-btn"
  //       onClick={() => removeCompany(index, company.id)}
  //     >
  //       &nbsp;&nbsp;&nbsp; x
  //     </span>
  //   </span>
  // );

  // select Company
  const handleSelect = payload => {
    try {
      const { data } = payload;
      if (data.length || Object.keys(data).length) {
        let temp = data;
        if (data.length) temp = data[0];
        let selected = selectedReports.filter(sc => sc.id === Number(temp.id));
        if (selected.length === 0) {
          let companies = [
            { ...temp, selectedMenu: 'parameters' },
            ...selectedReports
          ];
          updateCompaniesAndSaveToLS(companies);
        } else if (data instanceof Object && !Array.isArray(data)) {
          if (!Object.keys(selected).includes(data.id)) {
            let index = selectedReports.findIndex(com => com.id === data.id);
            removeCompany(index, data.id);
          }
        }

        updateTabAndSaveToLS(temp.id);
        if (Array.isArray(data)) handleOpen(true);
      } else {
        clearAll();
      }
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  // Remove  Tab From Selected Companies
  const removeCompany = (index, id) => {
    let companies = [...selectedReports];
    companies.splice(index, 1);
    updateCompaniesAndSaveToLS(companies);
    dispatch(removeReportsFromState({ id: id }));
  };

  //  TODO clear all selected tabs
  const clearAll = () => {
    updateCompaniesAndSaveToLS([]);
    updateTabAndSaveToLS('');
    dispatch(removeAllReportsFromState());
  };

  const filterTypes = Object.assign(
    {},
    ReactDataGrid.defaultProps.filterTypes,
    myReportFilterTypeConfig
  );

  const getFilterValue = data => {
    let updatedValue = data.value;
    if (
      data.name === 'createdOn' &&
      (data.operator === 'eq' || data.operator === 'after')
    ) {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = '2000-01-01T08:00:00.000Z';
    } else if (data.operator === 'contains') {
      updatedValue = `%${updatedValue}%`;
    } else if (data.operator === 'startsWith') {
      updatedValue = `${updatedValue}%`;
    } else if (data.operator === 'endsWith') {
      updatedValue = `%${updatedValue}`;
    }
    return updatedValue;
  };

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    setLoadingMessage('Loading ...');
    const updatedValue = filterValue
      .filter(v => v.value !== '')
      .map(v => ({
        propertyName: v.name,
        propertyValue1: getFilterValue(v),
        propertyValue2:
          v.name === 'createdOn' &&
          (v.operator === 'before' || v.operator === 'after')
            ? getDateFilterValue(v)
            : '',
        propertyValue3: [],
        filterDataType: 2,
        comparison: 2
      }));
    let payload = {
      start: skip,
      limit: limit,
      filter: JSON.stringify(updatedValue),
      dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
      sort: sortInfo ? sortInfo.name : 'createdOn'
    };
    return getAllMyReportList(payload).then(response => {
      if (response.data.totalSize > 20) {
        setDataLimit(skip + response.data.data.length);
      } else {
        setDataLimit(response.data.data.length);
      }
      const totalCount = response.data.totalSize;
      setTotalRecords(totalCount);
      return { data: response.data.data, count: parseInt(totalCount) };
    });
  };

  useEffect(() => {
    !mobileSuggestions && dispatch(fetchMobileSuggestionsAsync());
  }, [mobileSuggestions, dispatch]);

  useEffect(() => {
    if (config.hideConsole) {
      console.clear();
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataSource = useCallback(loadData, []);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className={`report-section-wrapper ${loading && 'blux-text'}`}>
            {permissions.allowPortal ? (
              <HeaderToExistingPortal
                msg={t('company.general.oldPortalTag')}
                borderRadius={'0px'}
              />
            ) : null}
            <div className="mobile_view">
              <GridWrapper
                columns={myReportTableConfig}
                gridClass={
                  permissions.allowPortal ? 'myreports-table-container' : ''
                }
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                defaultFilterValue={myReportFilterConfig}
                filterTypes={filterTypes}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                handleSelect={handleSelect}
              />
            </div>
          </Col>
        </Row>
      </Container>

      {/* {selectedReports.length ? (
        <SidebarRight handleOpen={handleOpen} open={openColapse}>
          <button onClick={() => clearAll()}>Clear All</button>
          <TabsContainer
            onTabSelect={e => handleTabSelect(e)}
            activeKey={
              selectedReports.filter(sc => sc.id === selectedTab).length
                ? selectedTab
                : selectedReports[0].id
            }
          >
            {selectedReports.map((company, index) => {
              return (
                <TabComponent
                  tabClassName={'tab-ui'}
                  key={index}
                  eventKey={company.id}
                  title={tabTitle(index, company)}
                  TODO close button
                  closeButton={() => {
                    console.log(index, 'close tab');
                  }}
                >
                  <ReportContainer
                    selectMenu={selectTabMenu}
                    selectedCompany={company}
                  />
                </TabComponent>
              );
            })}
          </TabsContainer>
        </SidebarRight>
      ) : null} */}
    </>
  );
};

export default MyReports;

import {
  pushChatRoom,
  pushLoadingProgress,
  setAllChatList
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import storage from 'helpers/storage';
import { IncreaseProgress } from 'pages/portal/messaging/ChatSplashScreen/SplashScreen';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';

const Subscribe_GeneralQueue_CompanyID = callbacks => {
  const user = JSON.parse(storage.getItem('Profile'));
  RTC().Subscribe({
    channels: ['/generalqueue/' + user?.CompanyID],
    callbacks: callbacks
  });
};

export const Init_GeneralQueue_CompanyID = () => {
  Subscribe_GeneralQueue_CompanyID({
    onSuccess: function (args) {
      IncreaseProgress({
        text: 'Subscribing general queue for company...',
        count: 10
      });
      console.log('onSuccess::General Queue CompanyID Subscribe', args);
    },
    onFailure: function (args) {
      console.log('onFailure::General Queue CompanyID Subscribe', args);
      console.log(
        'onFailure::General Queue CompanyID::Channels',
        args.getChannels()
      );
    },
    onReceive: function (args) {
      console.log('genral queue companyID onReceive', args);
      var data = args.getData();
      var chatroomByType = chatChanged(data);
      console.log('genral queue companyID chatroomByType---', chatroomByType);
      if (
        chatroomByType.includeInSections.length ||
        chatroomByType.excludeInSections.length
      ) {
        // TODO: remove duplication of chat and push to respective chat array by its type
        store.dispatch(pushChatRoom(chatroomByType));
      }
    }
  });
};

export default Subscribe_GeneralQueue_CompanyID;

export const fr_tour = {
  tourWelcome:
    'Bonjour <user>, Bienvenue sur le nouveau tableau de bord de chat.',
  tourWelcomeText:
    'Jetons un rapide tour du nouveau tableau de bord de chat pour vous montrer et de vous familiariser avec les fonctionnalités et les changements que vous pouvez faire maintenant.',
  tourAnalytics:
    'Bienvenue dans le nouveau tableau de bord analytique. Plus est à venir!',
  tourMessaging:
    "La nouvelle zone de messagerie arrive bientôt. S'il vous plaît utiliser le bouton de redirection pour traiter les chats dans la plate-forme actuelle.",
  tourCompanies:
    "L'onglet entreprise vous permet d'apporter des modifications à votre compte.",
  tourChats: 'Chats entrants traités pour les six derniers mois.',
  tourLeads: 'Clients de chat entrants pour les six derniers mois.',
  tourReports:
    "L'onglet déclaration arrive bientôt. Vous pouvez afficher ou rediriger vers le portail existant pour la fonctionnalité de reporting.",
  tourOnboarding:
    "L'onglet Onboarding arrive bientôt. Vous pouvez afficher ou rediriger vers la zone d'approvisionnement dans le portail existant pour modifier ou soumettre un nouveau compte.",
  tourHelp:
    '<p><b>SUPPORT BOT</b> - Le support bot est là pour répondre aux questions et envoyer un ticket à notre équipe interne qui vous répondra.</p><p><b>VISITE DE LANCEMENT</b> - Faites une visite rapide et familiarisez-vous avec la nouvelle mise en page.</p>',
  tourSwitch: 'Vous pouvez basculer entre les systèmes nouveaux et existants.',
  tourEnd: "C'est la fin de la tournée",
  tourEnd2:
    "Nous espérons que vous avez apprécié la visite et nous nous réjouissons d'apporter plus de fonctionnalités au nouveau tableau de bord de chat bientôt. Si vous avez des questions, veuillez joindre à nous. Nous sommes toujours là pour vous aider.",
  btn1: 'Peut-être plus tard',
  btn2: 'Tour initial',
  btn3: 'Précédent',
  btn4: 'Finir',
  btn5: 'Suivant'
};

export default fr_tour;

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ChatItem from './ChatItem';
import {
  formatTime,
  calculateTimeDifferenceBetweenChat
} from 'helpers/generic.methods';

const ChatBox = ({ title, chatHistory = [] }) => {
  const chatRenderer = chatHistory.map((chat, index) => (
    <>
      <span className="float-left mr-2">
        [
        {`${formatTime(
          chat.createdOn,
          'hh:mm:s a'
        )} ${calculateTimeDifferenceBetweenChat(
          chat.createdOn,
          chatHistory[index - 1]?.createdOn,
          '(formats)'
        )}`}
        ]
      </span>
      <ChatItem key={index} chat={chat} />
    </>
  ));
  return (
    <>
      <div>
        <h5 className="text-darkgray-2 fz-15 fw-700 mt-20">{title}</h5>
        <div className="chat-container mb-20">
          <Scrollbars>
            <div className="chat-snippet">{chatRenderer}</div>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

export default ChatBox;

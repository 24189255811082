import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import { insertInArray } from 'helpers/generic.methods';

const TourQuickStatsData = () => {
  const { t } = useTranslation();
  const { roles } = useSelector(selectRoles);
  const analyticGraphs = [
    {
      key: 'analytics',
      subKey: 'quickStats',
      element: '.introJS-analytics-quickstats',
      intro: t('company.tour.tourAnalytics'),
      position: 'right'
    }
  ];
  const messaging = [
    {
      key: 'switch',
      subKey: 'allowDashboard',
      element: '#introJS-item-1',
      intro: t('company.tour.tourMessaging'),
      position: 'right',
      className: 'neww'
    }
  ];
  const tour = [
    {
      key: 'company',
      element: '#introJS-item-2',
      intro: t('company.tour.tourCompanies'),
      position: 'right'
    },
    {
      key: 'chat',
      element: '#introJS-item-3',
      intro: t('company.tour.tourChats'),
      position: 'right'
    },
    {
      key: 'lead',
      element: '#introJS-item-4',
      intro: t('company.tour.tourLeads'),
      position: 'right'
    },
    {
      key: 'report',
      element: '#introJS-item-5',
      intro: t('company.tour.tourReports'),
      position: 'right'
    },
    {
      key: 'onBoarding',
      element: '#introJS-item-6',
      intro: t('company.tour.tourOnboarding'),
      position: 'right'
    }
  ];
  const defaultItems = [
    {
      // subKey: 'default',
      element: '#introJS-item-help',
      intro: t('company.tour.tourHelp'),
      position: 'right'
    },
    // {
    //   subKey: 'allowPortal',
    //   element: '#introJS-item-switch',
    //   intro: t('company.tour.tourSwitch'),
    //   position: 'right'
    // },
    {
      // subKey: 'default',
      title: t('company.tour.tourEnd'),
      intro: `<p style='text-align: left'>${t(
        'company.tour.tourEnd2'
      )}</p><img class="finish-tour-modal" src='/finish.png' />`,
      tooltipClass: 'customTooltip'
    }
  ];
  const switchTo = [
    {
      //key: 'switch',
      subKey: 'allowPortal',
      element: '#introJS-item-switch',
      intro: t('company.tour.tourSwitch'),
      position: 'right'
    }
  ];

  const pagesData = tour.filter(item => roles[item.key].view);
  const analyticsData = analyticGraphs.filter(item => roles?.analytics.view);
  const messagingData = messaging.filter(item => roles?.switch[item.subKey]);
  const switchToData = switchTo.filter(item => roles?.switch[item.subKey]);
  const result = switchToData.length
    ? insertInArray(defaultItems, 1, switchToData[0])
    : [...defaultItems];

  return [...analyticsData, ...messagingData, ...pagesData, ...result];
};

export default TourQuickStatsData;

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import counterReducer from 'features/counter/counterSlice';
import authReducer from 'features/auth/authSlice';
import generalReducer from 'features/general/generalSlice';
import companyReducer from 'features/company/companySlice';
import companiesReducer from 'features/companies/companiesSlice';
import chatsReducer from 'features/chat/chatSlice';
import leadsReducer from 'features/lead/leadSlice';
import userReducer from 'features/users/userSlice';
import reportsReducer from 'features/report/reportsSlice';
import fetchGeneralData from 'features/fetchGeneralData/fetchGeneralDataOnAuth';
import loadingReducer from 'features/loading/loadingSlice';
import provisionReducer from 'features/provisioning/ProvisioningSlice';
import rolesReducer from 'features/roles/rolesSlice';
import scriptLoadReducer from 'features/script-load/scriptLoadSlice';
import filtersReducer from 'features/analytics/analyticSlice';
import navbarReducer from 'features/sidebar-nav/sidebarNavSlice';
import MessagingChatsReducer from 'features/messagingChats/messagingChats';

export default configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    general: generalReducer,
    company: companyReducer,
    fetchGeneralData: fetchGeneralData,
    loading: loadingReducer,
    companies: companiesReducer,
    chats: chatsReducer,
    leads: leadsReducer,
    user: userReducer,
    reports: reportsReducer,
    provisions: provisionReducer,
    roles: rolesReducer,
    scriptLoad: scriptLoadReducer,
    MessagingChats: MessagingChatsReducer,
     filters: filtersReducer,
    navbar: navbarReducer
  },
  middleware: [
    ...getDefaultMiddleware({
      // by default serializableCheck set to true by the getDefaultMiddleware middleware
      // which checks the data to be dispatched in serialized form
      // we set it to false as we have non serialized data
      serializableCheck: false
    })
  ]
});

import {
  pushChatRoom,
  pushLoadingProgress,
  setAllChatList
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import storage from 'helpers/storage';
import { IncreaseProgress } from 'pages/portal/messaging/ChatSplashScreen/SplashScreen';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';
import { Init_GeneralQueue_Group } from './listener_generalqueue_group';

const Subscribe_User_By_UserID = callbacks => {
  const user = JSON.parse(storage.getItem('Profile'));
  RTC().Subscribe({
    channels: ['/user/' + user?.UserID],
    callbacks: callbacks
  });
};

export const Init_GeneralQueue_UserID = () => {
  Subscribe_User_By_UserID({
    onSuccess: function (args) {
      IncreaseProgress({
        text: 'Subscribing general queue for users...',
        count: 10,
        hasCompleted: true
      });
      console.log('onSuccess::User By UserID Subscribe', args);
    },
    onFailure: function (args) {
      console.log('onFailure::User By UserID Subscribe', args);
      console.log('onFailure::User By UserID::Channels', args.getChannels());
    },
    onReceive: function (args) {
      console.log('onReceive UserID', args);
      var data = args.getData();
      if (data && data.type) {
        processServerMessage(data);
        return;
      }
    }
  });
};

const processServerMessage = function (message) {
  var me = this;
  if (!message) {
    return;
  }

  switch (message.type) {
    case Enums.ServerMessageType.GroupAdded:
      addGroup(message.groupId, message.group);
      // TODO: add group id into localstorage in profile.AssignedGroups
      break;

    case Enums.ServerMessageType.GroupRemoved:
      // TODO: remove group will be added soon
      // removeGroup(message.groupId);
      break;
    default:
      break;
  }
};

const addGroup = function (groupId, actualGroup) {
  // TODO: will add this in group
  //  ApexChat.Settings.Groups.push(actualGroup);

  // subscribe to the new group
  //  me.subscribeToGroup(groupId);
  Init_GeneralQueue_Group(groupId);
};

export default Subscribe_User_By_UserID;

import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import FieldError from './ui-components/FieldError';

const EditableCompanyHeader = ({
  // value of the company header
  headerDataProperty,
  // FormIK error for the specific field
  error,
  // custom error message text
  errorMessage,
  // boolean: false to make field read-only
  editable,
  // action trigger on change
  handleChange,
  // field name
  field,
  // human readable title
  titleText
}) => {
  const [value, setValue] = useState(headerDataProperty);
  const handleOnChange = element => {
    setValue(element.target.value);
    handleChange(element, field, element.target.value);
  };
  useEffect(() => {
    setValue(headerDataProperty);
  }, [headerDataProperty]);
  return (
    <>
      {editable ? (
        <h1 className="main-head text-blue-2">
          <Form.Control
            value={value}
            className={`${error ? 'is-invalid' : ''}${
              editable ? '' : 'read-only'
            }${' main-head text-darkgray-1'}`}
            onChange={e => handleOnChange(e)}
            aria-label={titleText}
            id={field}
            name={field}
          />
          {error && (
            <FieldError
              titleText={titleText}
              error={error}
              errorMessage={errorMessage}
              position="bottom"
            />
          )}
        </h1>
      ) : (
        <h1 className="main-head text-blue-2">{value}</h1>
      )}
    </>
  );
};
export default EditableCompanyHeader;

import React from 'react';
import moment from 'moment';
import CopyButton from 'CellRenderer/CopyButton';

const StatusButton = ({ value }) => {
  const date = moment(new Date(value)).format('DD-MMMM-YYYY');
  return <CopyButton value={date} />;
};

export default StatusButton;

import store from 'store';
import { Init_GeneralQueue } from './listener_generalqueue';
import { Init_GeneralQueue_CompanyID } from './listener_generalqueue_companyid';
import { Init_GeneralQueue_UserID } from './listener_user_by_userid';
import { Init_Shelved } from './listener_shelved';
import { Init_DefaultQueue } from './listener_defaultqueue';
import { Init_GeneralQueue_Group } from './listener_generalqueue_group';

export const InitializeDashboardConnections = () => {
  const init = () => {
    let {
      roles: { roles },
      auth: { profile }
    } = store.getState();

    Init_GeneralQueue();

    if (profile?.AssginedGroups) {
      for (let groupId of profile.AssginedGroups) {
        Init_GeneralQueue_Group(groupId);
      }
    }

    if (roles.isAgent) {
      Init_DefaultQueue();
      Init_Shelved(); // only agent can view shelved chats section
    }

    if (!roles.isAdminCompany) {
      Init_GeneralQueue_CompanyID();
    }
    Init_GeneralQueue_UserID();
  };
  return { init };
};

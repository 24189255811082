const FilterPanelInfoTooltip = ({ toolTipQuickStats, toolTipGraphs }) => (
  <div style={{ textAlign: 'left' }}>
    <div style={{ fontWeight: '700' }} className="mb-3">
      Changes in this section are applied to:
    </div>
    <div style={{ fontWeight: '700' }}>Quick Stats : </div>
    <ul className="mb-2">
      {toolTipQuickStats.split(',').map((ttq, index) => (
        <li key={index}>{ttq}</li>
      ))}
    </ul>
    <div style={{ fontWeight: '700' }}>Charts : </div>
    <ul className="mb-2">
      {toolTipGraphs.split(',').map((ttg, index) => (
        <li key={index}>{ttg}</li>
      ))}
    </ul>
  </div>
);
export default FilterPanelInfoTooltip;

import React, { useEffect, useState } from 'react';
import { Badge, Dropdown, OverlayTrigger } from 'react-bootstrap';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import UilCopy from '@iconscout/react-unicons/icons/uil-copy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  getPlainStringFromHTMLElementAsString,
  validateEmailFromSentence,
  validatePhoneFromSentence
} from 'helpers/validate';
import { dateTimerFormat, dayAgo } from 'helpers/generic.methods';
import { getHighlightedText } from 'helpers/messaging/message.methods';

import { useDispatch } from 'react-redux';
import { setLeadFormUserCredentials } from 'features/lead/leadSlice';
import { duration } from 'helpers/variables';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import { isNumber } from 'helpers/schemas/genericSchemas';
const Message = ({ message, userType }) => {
  const dispatch = useDispatch();

  const [messageDropDownText, setMessageDropDownText] = useState('');
  const [validatedPhoneNumber, setValidatedPhoneNumber] = useState(
    validatePhoneFromSentence(message.text)
  );
  const [validatedEmailAddress, setValidatedEmailAddress] = useState(
    validateEmailFromSentence(
      getPlainStringFromHTMLElementAsString(message.text)
    )
  );
  const [highLightedText, setHighLightedText] = useState('');
  const [copiedToClipBoard, setCopiedToClipBoard] = useState(false);
  const [animateBadge, setAnimateBadge] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  useEffect(() => {
    if (copiedToClipBoard) {
      setTimeout(() => {
        setAnimateBadge(false);
      }, duration.copiedBadge.duration - duration.copiedBadge.delay);
      setTimeout(() => {
        setCopiedToClipBoard(false);
      }, duration.copiedBadge.duration);
    }
  }, [copiedToClipBoard]);

  /**
   * @name @handleCopyFromMessage
   * @description there are two conditions one is check the email type other is phone type
   *              then it dispatches the action to set the lead form data accordingly
   *              also it will copies the data to clipboard
   * */
  const handleCopyFromMessage = msg => {
    if (
      validatedEmailAddress.hasEmailAddress ||
      validatedPhoneNumber.hasPhoneNumber
    ) {
      dispatch(setLeadFormUserCredentials(msg));
    } else if (messageDropDownText) {
      setMessageDropDownText('selection');
      dispatch(setLeadFormUserCredentials({ name: highLightedText }));
    }
  };

  /**
   * @name @handleCopyFromMessage
   * @description there are four conditions one is check the phone type second is email type
   *              third is checking if the text is highlighted fourth is fall back
   *              the method is setting the message dropdown link text
   * @requires    message text string
   * */
  const toggleMessageDropDown = msg => {
    if (msg) {
      if (validatedPhoneNumber?.hasPhoneNumber) {
        setMessageDropDownText('phone number');
        return;
      }
      if (validatedEmailAddress?.hasEmailAddress) {
        setMessageDropDownText('email address');
        return;
      }
      let highlighted = getHighlightedText();
      //if messeage contains only number then copy button will shown for only copy not fill in field
      setHighLightedText(highlighted.length ? highlighted : msg);
      if (!isNumber(msg)) {
        if (highlighted?.length) {
          setMessageDropDownText('selection as name');
        } else {
          setMessageDropDownText('message as name');
        }
      }
    }
  };

  /**
   * @name @handleClipboardCopy
   * @description extract text to be copied to clipboard
   * @return    text to be copied to clipboard
   * */
  const handleClipboardCopy = () => {
    if (validatedEmailAddress?.hasEmailAddress) {
      return validatedEmailAddress?.email;
    } else if (validatedPhoneNumber?.hasPhoneNumber) {
      return validatedPhoneNumber?.phoneNumber;
    } else {
      return highLightedText;
    }
  };

  /**
   * @name @onCopyToClipBoard
   * @description set couple of states to show and animate the copied badge
   * */
  const onCopyToClipBoard = () => {
    setCopiedToClipBoard(true);
    setTimeout(() => {
      setAnimateBadge(true);
    }, duration.copiedBadge.delay);
  };

  return (
    <>
      <div className="chat_message position-relative">
        <div className="chat-message-text">
          <p>{getPlainStringFromHTMLElementAsString(message.text)}</p>
          <span
            className={`copy-to-form ${dropdownOpened ? 'opened' : 'closed'}`}
            onClick={() => toggleMessageDropDown(message.text)}
          >
            <Dropdown onToggle={e => setDropdownOpened(e)}>
              <Dropdown.Toggle variant="default" size="sm">
                <UilEllipsisV size={14} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <CopyToClipboard
                  text={handleClipboardCopy()}
                  onCopy={onCopyToClipBoard}
                >
                  <Dropdown.Item
                    as="div"
                    title="Copy to clipboard"
                    className="copy-buttons-icon"
                    onClick={e => handleCopyFromMessage(message.text)}
                  >
                    <>
                      <UilCopy size="20" className="mr-4" />
                      Copy {messageDropDownText}
                    </>
                  </Dropdown.Item>
                </CopyToClipboard>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
        <span
          className="chat_message_time"
          // title={dateTimerFormat(message.createdOn)}
        >
          {/* MD-157 Removed Message Sender */}
          <OverlayTrigger
            placement={'auto'}
            overlay={renderToolTip(dateTimerFormat(message.createdOn))}
          >
            <span>{dayAgo(message.createdOn)}</span>
          </OverlayTrigger>
          {copiedToClipBoard && (
            <Badge
              pill
              className={`bg-success-3 border-0 px-2 copied-to-clipboard-success text-success-1 ${
                animateBadge ? 'active' : undefined
              }`}
            >
              Copied!
            </Badge>
          )}
        </span>
      </div>
    </>
  );
};

export default Message;

import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { stopPropogation } from 'features/general/generalSlice';
import SSO_Redirection from 'app/redirection-SSO';
import { UilExchange } from '@iconscout/react-unicons';
import './redirectComp.scss';
import RenderSidebarTooltip from 'helpers/ui-controls/renderSidebarTooltip';

const RedirectComp = ({ showSideBar }) => {
  const [menuShow, setMenuShow] = useState(false);
  const dispatch = useDispatch();

  const redirectHandler = () => {
    // redirection here..
    SSO_Redirection();
  };

  const useCloseOnClickedOutside = async ref => {
    await dispatch(stopPropogation(ref)).then(res => {
      if (res) setMenuShow(false);
    });
  };

  // use ref on wrapper element
  const wrapperRef = useRef(null);
  // calling the method to check if the clicked event
  // happened inside / outside the wrapper
  // and pass the wrapper ref
  useCloseOnClickedOutside(wrapperRef);

  const toggleDropdownHandler = e => {
    let show = !menuShow;
    setMenuShow(show);
  };
  return (
    <>
      <div className="profile-nav" ref={wrapperRef}>
        <div
          id="introJS-item-switch"
          className="avatar-nav d-block"
          onClick={e => toggleDropdownHandler(e)}
        >
          {/* conditional toggle avtar/usericon */}
          <div className="userIcon d-flex align-items-center userTextPrimary">
            <div className="m-4">
              <RenderSidebarTooltip
                showSidebar={showSideBar}
                title={'Switch to'}
                icon={<UilExchange size="24" className="sidebar-link" />}
              />
            </div>
            {showSideBar ? (
              <span className="fw-600 ml-2">Switch to</span>
            ) : null}
          </div>
        </div>
        {/* toggle the menu on condition check */}
        {menuShow && (
          <div
            className={`sidebar-bottom-menu-second-last${
              showSideBar ? '-open' : ''
            }`}
          >
            <ul className="dropdow-list">
              <li className="notification-list-item heading cursor-pointer fw-600">
                Switch to
              </li>
              <li
                className="notification-list-item option cursor-pointer"
                onClick={redirectHandler}
              >
                Existing Portal
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default RedirectComp;

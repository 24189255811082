import React from 'react';
import { UilUser } from '@iconscout/react-unicons';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import { getCSSClassAccordingToMessage } from 'helpers/messaging/message.methods';
import Message from '../Message';

const MessageContainer = ({
  msg,
  index,
  userImage,
  isChatEnded,
  messagesLength
}) => {
  const {
    roles: { isOperator, isAgent }
  } = useSelector(selectRoles);
  const role = isAgent ? 'AGENT' : isOperator ? 'OPERATOR' : '';

  const cssClass = getCSSClassAccordingToMessage(msg, role).cssClass;
  const userType = getCSSClassAccordingToMessage(msg, role).type;

  return (
    <div
      className={`chat_message_wrapper ${cssClass} ${
        isChatEnded && messagesLength - 1 === index ? 'chat-message-ended' : ''
      }`}
    >
      <div className="chat_user_avatar mt-2">
        {/* MD-177 removed operator/agent image, will be added in v2 ticket */}
        {/* {(userType === 'AGENT' || userType === 'OPERATOR') && userImage ? (
          <img
            src={userImage}
            alt="agent-logo"
            className="user-avatar rounded-circle"
          />
        ) : (
          <UilUser />
          )} */}
        <UilUser />
      </div>
      <Message message={msg} userType={userType} />
    </div>
  );
};

export default MessageContainer;

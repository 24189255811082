import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import dropdownEnums from 'helpers/dropdown-enums';
import { useTranslation } from 'react-i18next';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { OverlayTrigger } from 'react-bootstrap';
import { getEnumValue, toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';

const Budget = ({ company, clickHandlerMenu }) => {
  const [companyObj, setCompanyObj] = useState({});
  const { t } = useTranslation();
  const updateData = (key, value) => {
    let _tempCompanyObj = {};
    _tempCompanyObj[key] = value;
    setCompanyObj({ ...companyObj, ..._tempCompanyObj });
  };
  return (
    <>
      <Form>
        <div className="page-section">
          <section>
            <ToggleMenuButton
              heading={t('company.pacingLeadsAndTraffic.heading1')}
              clickHandlerMenu={clickHandlerMenu}
            />
            {!!company.pacingEnabled && (
              <div className="section-body">
                <ul className="editable-fields-lists">
                  <ToggleToolTip
                    heading={t(
                      'company.pacingLeadsAndTraffic.campaignPaused.label'
                    )}
                    toolTipFor="budgetPauseEnabled"
                    flag={company.budgetPauseEnabled}
                    titleText={t(
                      'company.pacingLeadsAndTraffic.campaignPaused.info'
                    )}
                  />
                  <ToggleToolTip
                    heading={t(
                      'company.pacingLeadsAndTraffic.recycleBudgetAfterItEnds.label'
                    )}
                    toolTipFor="recycleBudget"
                    flag={company.recycleBudget}
                    titleText={t(
                      'company.pacingLeadsAndTraffic.recycleBudgetAfterItEnds.info'
                    )}
                  />
                  <ToggleToolTip
                    heading={t(
                      'company.pacingLeadsAndTraffic.resetBudget.label'
                    )}
                    toolTipFor="resetBudgetOnFirstOfEveryMonth"
                    flag={company.resetBudgetOnFirstOfEveryMonth}
                    titleText={t(
                      'company.pacingLeadsAndTraffic.resetBudget.info'
                    )}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.pacingLeadsAndTraffic.totalPeriod.label'
                    )}
                    field="budgetPeriodDays"
                    dataProperty={company['budgetPeriodDays']}
                    titleText={t(
                      'company.pacingLeadsAndTraffic.totalPeriod.info'
                    )}
                    onChange={updateData}
                  />
                  <li>{t('company.pacingLeadsAndTraffic.pacingType.label')}</li>
                  <li>
                    <div className="d-flex justify-content-between align-items-center">
                      {' '}
                      {getEnumValue(
                        dropdownEnums.enum_PacingType,
                        company.pacingType
                      )}
                      {/* <Form.Control
                      as="select"
                      onChange={() => updateData('pacingType', 2)}
                      value={company.pacingType}
                      //role - permission
                      disabled={true}
                    >
                      {dropdownEnums.enum_PacingType.map((e, key) => {
                        return (
                          <option key={key} value={e.value}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Form.Control> */}
                      <OverlayTrigger
                        placement={toolTipPlacement}
                        overlay={renderToolTip(
                          t('company.pacingLeadsAndTraffic.pacingType.info')
                        )}
                      >
                        <UilInfoCircle className="list-icon-after-text ml-1" />
                      </OverlayTrigger>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </section>
        </div>
        <div className="page-section">
          <section>
            <header className="section-head d-flex align-items-center justify-content-between">
              <h4 className="fw-600 mb-0">
                {t('company.pacingLeadsAndTraffic.heading2')}
              </h4>
            </header>
            <div className="section-body">
              <ul className="editable-fields-lists">
                <SimpleLabelTextBox
                  heading={t(
                    'company.pacingLeadsAndTraffic.numberofleads.label'
                  )}
                  field="budgetAmount"
                  dataProperty={company['budgetAmount']}
                  titleText={t(
                    'company.pacingLeadsAndTraffic.numberofleads.info'
                  )}
                  onChange={updateData}
                />
                <ToggleToolTip
                  heading={t(
                    'company.pacingLeadsAndTraffic.sendNotification.label'
                  )}
                  toolTipFor="sendNotificationOnFirstBudgetedLead"
                  flag={company.sendNotificationOnFirstBudgetedLead}
                  titleText={t(
                    'company.pacingLeadsAndTraffic.sendNotification.info'
                  )}
                />
                <SimpleLabelTextBox
                  heading={t(
                    'company.pacingLeadsAndTraffic.emailAddresses.label'
                  )}
                  field="firstBudgetedLeadEmailAddresses"
                  dataProperty={company['firstBudgetedLeadEmailAddresses']}
                  titleText={t(
                    'company.pacingLeadsAndTraffic.emailAddresses.info'
                  )}
                  onChange={updateData}
                />
                <ToggleToolTip
                  heading={t(
                    'company.pacingLeadsAndTraffic.hasAWarningBeenSent.label'
                  )}
                  toolTipFor="firstBudgetedLeadWarningSent"
                  flag={company.firstBudgetedLeadWarningSent}
                  titleText={t(
                    'company.pacingLeadsAndTraffic.hasAWarningBeenSent.info'
                  )}
                />
                <ToggleToolTip
                  heading={t(
                    'company.pacingLeadsAndTraffic.enableSubBudget.label'
                  )}
                  toolTipFor="enablePacedBudget"
                  flag={company.enablePacedBudget}
                  titleText={t(
                    'company.pacingLeadsAndTraffic.enableSubBudget.info'
                  )}
                />
              </ul>
            </div>
          </section>
        </div>
        <div className="page-section">
          <section>
            <header className="section-head d-flex align-items-center justify-content-between">
              <h4 className="fw-600 mb-0">
                {t('company.pacingLeadsAndTraffic.heading3')}
              </h4>
            </header>
            <div className="section-body">
              <ul className="editable-fields-lists">
                <SimpleLabelTextBox
                  heading={t(
                    'company.pacingLeadsAndTraffic.totalTrafficAllowed.label'
                  )}
                  titleText={t(
                    'company.pacingLeadsAndTraffic.totalTrafficAllowed.info'
                  )}
                  helperText={t(
                    'company.pacingLeadsAndTraffic.totalTrafficAllowed.info'
                  )}
                  field="budgetAmountTotalTraffic"
                  dataProperty={company['budgetAmountTotalTraffic']}
                  onChange={updateData}
                />
              </ul>
            </div>
          </section>
        </div>
      </Form>
    </>
  );
};

export default Budget;

import React from 'react';
import { useTranslation } from 'react-i18next';
import dropdownEnums from 'helpers/dropdown-enums';
import Weekly from './Weekly';
import MonthlyOrDaily from './MonthlyOrDaily';

const Schedule = ({ report }) => {
  const scheduleType = dropdownEnums.enum_Schedule.find(
    item => item.value === report.scheduleType
  );

  const { t } = useTranslation();
  switch (scheduleType.name) {
    case 'Monthly':
      return (
        <MonthlyOrDaily
          report={report}
          scheduleName={scheduleType.name}
          isMonthly
        />
      );
    case 'Weekly':
      return <Weekly report={report} scheduleName={scheduleType.name} />;
    case 'Daily':
      return (
        <MonthlyOrDaily report={report} scheduleName={scheduleType.name} />
      );
    default:
      return (
        <>
          <div className="page-section mt-0">
            <h4 className="sub-head">
              {t('company.reportDetails.schedule.noScheduleFound.label')}
            </h4>{' '}
          </div>
        </>
      );
  }
};

export default Schedule;

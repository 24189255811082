import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import './styles.scss';

export const Legend = ({ color, label, fontSize, direction, icon }) => (
  <div className="custom-legend">
    {icon ? (
      icon
    ) : (
      <span className="legend-box">
        <div
          className="custom-legend-block"
          style={{ background: color }}
        ></div>
      </span>
    )}

    <span
      className="custom-legend-label ml-1"
      style={fontSize ? { fontSize } : null}
    >
      {label}
    </span>
  </div>
);

const LineChart = ({
  graphData = {},
  conversionInTooltip = false,
  loading
}) => {
  const [chartData, setChartData] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (graphData && Object.keys(graphData).length) {
      let temp = {
        labels: graphData.labels || ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: []
      };
      graphData.data.forEach((dt, ind) => {
        let dataset = {
          label: dt.label || `label ${ind + 1}`,
          data: dt.values,
          fill: false,
          backgroundColor: dt.color || 'red',
          borderColor: dt.color || 'red',
          tension: 0.4,
          pointRadius: 2
        };
        temp.datasets.push(dataset);
      });
      setChartData(temp);
      setOptions({
        animation: {
          duration: 0
        },
        plugins: {
          legend: {
            align: 'end',
            display: true,
            labels: {
              usePointStyle: true,
              pointStyle: 'rectRounded',
              boxWidth: 10,
              padding: 10
            }
          },
          tooltip: {
            backgroundColor: 'rgba(69, 79, 91, 1)',
            callbacks: conversionInTooltip
              ? {
                  afterBody: data => {
                    let percentage = 0;
                    if (data.length === 2) {
                      percentage = (data[1].raw / (data[0].raw || 1)) * 100;
                    }
                    return `     Conversion  : ${
                      percentage ? percentage.toFixed(2) : 0
                    } %`;
                  }
                }
              : {}
          }
        },
        interaction: {
          intersect: true,
          mode: 'index'
        },
        // TODO
        scales: {
          x: { grid: { display: false } },
          y: {
            ticks: {
              callback: label => `${checkForLabelValues(label)}`
            },
            grid: {
              display: false
            },
            min: 0
          }
        }
      });
    }
  }, [graphData]); // eslint-disable-line react-hooks/exhaustive-deps
  const checkForLabelValues = label => {
    if (label && label >= 1000) {
      label = label / 1000 + 'k';
    }
    return label;
  };
  return (
    <div>
      {chartData && Object.keys(chartData).length ? (
        <Line data={chartData} options={options} />
      ) : null}
    </div>
  );
};

export default LineChart;

import OnRollLogoWhite from 'static/images/OnRollLogo-white.svg';
import OnRollLogo from 'static/images/OnRollLogo.svg';
import ApexchatLogoWhite from 'static/images/logo-light.png';
import ApexchatLogo from 'static/images/logo192.png';
import ApexchatLogo404 from 'static/images/logo.png';

export const LogoProvider = () => {
  const url = window.location.hostname;
  let isHostnameApexchat = url.includes('apexchat');

  let logoDetails = {
    isWhiteLabed: true,
    logoPath: '/favicon-onroll.ico',
    logo: OnRollLogo,
    logoWhite: OnRollLogoWhite,
    title: 'Live Chat'
  };

  if (isHostnameApexchat) {
    logoDetails.isWhiteLabed = false;
    logoDetails.logoPath = '/logo192.png';
    logoDetails.logo = ApexchatLogo;
    logoDetails['logo404'] = ApexchatLogo404;
    logoDetails.logoWhite = ApexchatLogoWhite;
    logoDetails.title = 'ApexChat';
  }

  return logoDetails;
};

import React, { useState, useEffect } from 'react';
import DisplayKey from 'helpers/ui-controls/DisplayKey';

const ChatHeader = ({ chat, companyId }) => {
  const [chatData, setChatData] = useState({});

  useEffect(() => {
    // mapServerValuesToInitialValues(company, companyHeader)
    //   .then(data => {
    //     setCompanyHeaderObj({
    //       ...companyHeaderObj,
    //       ...data
    //     });
    //   })
    //   .catch(err => {
    //     throw new Error(err);
    //   });
    setChatData(chat);
  }, [chat]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DisplayKey displayKey={companyId} />
      <div className="page-header">
        <div className="page-header-bottom d-flex align-items-center justify-content-between">
          <h1 className="main-head text-blue-2">{chatData.companyName}</h1>
          {/* <Button className="btn-sm btn-flex" variant="lightgray-3">
          <UILFileExport />
          View lead
        </Button> */}
        </div>
      </div>
    </>
  );
};

export default ChatHeader;

export const sp_contactInformation = {
  heading1: 'Información del contacto',
  heading2: 'Detalles primarios',
  primaryContactName: {
    label: 'Nombre',
    info:
      'El nombre del contacto principal que será el principal punto de contacto para la cuenta.'
  },
  primaryContactEmail: {
    label: 'Correo electrónico',
    info:
      'El contacto principal de correo electrónico será el principal punto de contacto para la cuenta.'
  },
  primaryContactPhone: {
    label: 'Teléfono',
    info:
      'El teléfono de contacto principal será el principal punto de contacto para la cuenta.'
  },
  primaryContactAltPhone: {
    label: 'Teléfono alternativo',
    info:
      'Un número de teléfono alternativo que puede ser contactado para la cuenta'
  },
  address: {
    label: 'Habla a',
    info: 'La dirección principal del negocio.'
  },
  city: {
    label: 'Ciudad',
    info: 'La ciudad donde se encuentra el negocio.'
  },
  state: {
    label: 'Expresar',
    info: 'El estado donde se encuentra la empresa'
  },
  zip: {
    label: 'Cremallera',
    info: 'El código postal del lugar donde se encuentra el negocio.'
  },
  serviceArea: {
    label: 'Área de servicio',
    info:
      'El área de servicio y la cobertura de la empresa. (Por ejemplo: todo el mundo, el país, estado, ciudades, etc.)'
  },
  country: {
    label: 'País',
    info: 'El país donde se encuentra el negocio.'
  }
};
export default sp_contactInformation;

import React, { useState } from 'react';
import { UilMessage } from '@iconscout/react-unicons';
import UilEnvelopeCheck from '@iconscout/react-unicons/icons/uil-envelope-check';
import UilPhone from '@iconscout/react-unicons/icons/uil-phone';
import { isCallConnectEnable } from 'helpers/messaging/message.methods';
import { useDispatch, useSelector } from 'react-redux';
import { getLeadFormUserCredentials } from 'features/lead/leadSlice';
import {
  selectChatDetailsRightPane,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';

const ActionButtons = ({ sendText, selectedChat }) => {
  const [activeDetailsTab, setActiveDetailsTab] = useState();
  let togglePanel = useSelector(selectChatDetailsRightPane);
  const leadFormUserCredentials = useSelector(getLeadFormUserCredentials);
  const dispatch = useDispatch();

  const toggleSidePane = activeTab => {
    dispatch(
      toggleChatDetailsRightPane({
        show: activeDetailsTab === activeTab ? !togglePanel.show : true,
        activeDetailsTab: activeTab
      })
    );
    setActiveDetailsTab(activeTab);
  };

  const showCallConnect =
    leadFormUserCredentials?.phoneNumber &&
    leadFormUserCredentials?.phoneNumber.length;
  const showLeadForm =
    leadFormUserCredentials?.email && leadFormUserCredentials?.email.length;

  return (
    <div className="chat-input-actions d-flex">
      <div className="input-action-icon chat_button_section">
        {/* togglePanel.activeDetailsTab */}
        {(showCallConnect || showLeadForm) && (
          <span
            className={`lead-form-trigger action-icon ${
              togglePanel.activeDetailsTab === 'lead-form' && togglePanel.show
                ? 'active'
                : ''
            }`}
            onClick={e => toggleSidePane('lead-form')}
          >
            <UilEnvelopeCheck />
          </span>
        )}
        {showCallConnect && isCallConnectEnable(selectedChat) && (
          <span
            className={`call-connect-form-trigger action-icon ${
              togglePanel.activeDetailsTab === 'call-connect' &&
              togglePanel.show
                ? 'active'
                : ''
            }`}
            onClick={e => toggleSidePane('call-connect')}
          >
            <UilPhone />
          </span>
        )}
      </div>
      <span
        className="submit-icon btn btn-primary btn-sm px-16"
        onClick={sendText}
      >
        {/* {sendingText ? (
          <i className="uil uil-spinner spinner ml-2"></i>
        ) : (
          )} */}
        {/* <UilMessage size={30} /> */}
        <i className="uil uil-message"></i>
      </span>
    </div>
  );
};

export default ActionButtons;

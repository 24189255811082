import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import FieldError from './FieldError';
import storage from 'helpers/storage';

const SimpleSelectBox = ({
  // label of the field
  heading,
  // field name
  field,
  // value of the field
  dataProperty,
  // human readable title
  titleText,
  // FormIK error for the specific field
  error,
  // custom error message text
  errorMessage,
  // boolean: false to make field read-only
  editable,
  // read-only field never editable
  readOnly,
  // action trigger on change
  handleChange,
  // for Dropdown
  options,
  // for full width
  fullWidth = false
}) => {
  const [value, setValue] = useState(dataProperty);
  const [selectOpen, setSelectOpen] = useState(false);
  // const selectedLanguage = JSON.parse(storage.getItem('language'));
  const handleOnChange = element => {
    handleOnClick(true);
    setValue(element.target.value);
    handleChange(element, field, element.target.value);
  };
  const handleOnClick = value => {
    setSelectOpen(value);
  };
  useEffect(() => {
    setValue(dataProperty);
  }, [dataProperty]);
  return (
    <>
      <div className={'select-wrapper mt-1' + fullWidth && 'w-100'}>
        {heading && (
          <Form.Label title={titleText} htmlFor={field}>
            {heading}
          </Form.Label>
        )}

        <Form.Control
          as="select"
          className={`select ${error ? 'is-invalid' : ''}${
            editable ? '' : 'read-only'
          }`}
          value={value}
          title={value}
          readOnly={!editable || readOnly}
          onChange={e => handleOnChange(e)}
          onClick={e => handleOnClick(!selectOpen)}
          id={field}
          name={field + '_'}
          aria-label={titleText}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Form.Control>
        <span className={`select-arrow ${selectOpen ? 'toggled' : ''}`}>
          <UilAngleDown size="24" />
        </span>
        {error && (
          <FieldError
            titleText={titleText}
            error={error}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </>
  );
};
export default SimpleSelectBox;

export const companiesFilterConfig = [
  {
    name: 'companyName',
    operator: 'contains',
    type: 'companyName',
    value: ''
  },
  { name: 'companyKey', operator: 'contains', type: 'companyKey', value: '' },
  { name: 'active', type: 'select', value: '' },
  { name: 'createdOn', operator: 'eq', type: 'createdOn', value: '' },
  { name: 'quickConnectEnabled', type: 'select', value: '' }
];

export const companiesFilterTypeConfig = {
  companyName: {
    name: 'companyName',
    operators: [
      {
        name: 'contains'
      },
      {
        name: 'startsWith'
      },
      {
        name: 'endsWith'
      }
    ]
  },
  companyKey: {
    name: 'companyKey',
    operators: [
      {
        name: 'contains'
      },
      {
        name: 'startsWith'
      },
      {
        name: 'endsWith'
      }
    ]
  },
  createdOn: {
    name: 'createdOn',
    operators: [
      {
        name: 'eq'
      },
      {
        name: 'before'
      },
      {
        name: 'after'
      }
    ]
  }
};
export const leadsFilterConfig = [
  { name: 'id', type: 'string', value: '' },
  { name: 'companyName', type: 'string', value: '' },
  { name: 'companyKey', type: 'string', value: '' },
  { name: 'name', type: 'string', value: '' },
  { name: 'phone', type: 'string', value: '' },
  { name: 'email', type: 'string', value: '' },
  { name: 'createdOn', operator: 'eq', type: 'createdOn', value: '' },
  { name: 'processedByCustomer', type: 'select', value: '' },
  { name: 'leadType', type: 'select', value: '' },
  { name: 'initiatedBy', type: 'select', value: '' }
];

export const leadssFilterTypeConfig = {
  createdOn: {
    name: 'createdOn',
    operators: [
      {
        name: 'eq'
      },
      {
        name: 'before'
      },
      {
        name: 'after'
      }
    ]
  }
};

export const chatFilterConfig = [
  {
    name: 'id',
    operator: 'contains',
    type: 'textType',
    value: ''
  },
  {
    name: 'companyName',
    operator: 'contains',
    type: 'textType',
    value: ''
  },
  { name: 'companyKey', operator: 'contains', type: 'textType', value: '' },
  { name: 'createdOn', operator: 'eq', type: 'createdOn', value: '' },
  { name: 'isLead', type: 'select', value: '' },
  { name: 'leadType', type: 'textType', value: '' }
];

export const chatFilterTypeConfig = {
  textType: {
    name: 'textType',
    operators: [
      {
        name: 'contains'
      },
      {
        name: 'startsWith'
      },
      {
        name: 'endsWith'
      }
    ]
  },
  createdOn: {
    name: 'createdOn',
    operators: [
      {
        name: 'eq'
      },
      {
        name: 'before'
      },
      {
        name: 'after'
      }
    ]
  }
};

export const provisionFilterConfig = [
  { name: 'id', type: 'string', value: '' },
  { name: 'companyName', operator: 'contains', type: 'companyName', value: '' },
  { name: 'hostName', operator: 'contains', type: 'hostName', value: '' },
  { name: 'status', type: 'select', value: '' },
  { name: 'createdOn', operator: 'eq', type: 'createdOn', value: '' },
  { name: 'modifiedOn', operator: 'eq', type: 'modifiedOn', value: '' }
];

export const provisionFilterTypeConfig = {
  companyName: {
    name: 'companyName',
    operators: [
      {
        name: 'contains'
      },
      {
        name: 'startsWith'
      },
      {
        name: 'endsWith'
      }
    ]
  },
  hostName: {
    name: 'hostName',
    operators: [
      {
        name: 'contains'
      },
      {
        name: 'startsWith'
      },
      {
        name: 'endsWith'
      }
    ]
  },
  createdOn: {
    name: 'createdOn',
    operators: [
      {
        name: 'eq'
      },
      {
        name: 'before'
      },
      {
        name: 'after'
      }
    ]
  },
  modifiedOn: {
    name: 'modifiedOn',
    operators: [
      {
        name: 'eq'
      },
      {
        name: 'before'
      },
      {
        name: 'after'
      }
    ]
  }
};

export const myReportFilterConfig = [
  { name: 'name', operator: 'contains', type: 'string', value: '' },
  { name: 'createdOn', operator: 'eq', type: 'createdOn', value: '' }
];

export const myReportFilterTypeConfig = {
  createdOn: {
    name: 'createdOn',
    operators: [
      {
        name: 'eq'
      },
      {
        name: 'before'
      },
      {
        name: 'after'
      }
    ]
  }
};

export const queuedReportFilterConfig = [
  { name: 'id', type: 'string', value: '' },
  { name: 'reportStatusId', type: 'string', value: '' },
  { name: 'name', operator: 'contains', type: 'string', value: '' },
  { name: 'parametersJson', type: 'select', value: '' },
  { name: 'createdOn', operator: 'eq', type: 'createdOn', value: '' }
];

export const queuedReportFilterTypeConfig = {
  createdOn: {
    name: 'createdOn',
    operators: [
      {
        name: 'eq'
      },
      {
        name: 'before'
      },
      {
        name: 'after'
      }
    ]
  }
};

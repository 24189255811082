import React from 'react';
import Image from 'react-bootstrap/Image';
import { LogoProvider } from './helper';

const Logo = ({ login = false }) => {
  let favicon = document.getElementById('favicon');
  let applyTouchIcon = document.getElementById('apple-touch-icon');
  const { logo, logoWhite, logoPath, isWhiteLabed, title } = LogoProvider();
  window.document.title = title;
  applyTouchIcon.setAttribute('href', logoPath);
  favicon.setAttribute('href', logoPath);
  if (login) return <Image src={logo} />;
  return <Image className={isWhiteLabed && 'img-w'} src={logoWhite} />;
};

export default Logo;

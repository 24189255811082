import store from 'store';
import i18n from 'app/i18n';
import { logout } from 'features/auth/authSlice';
import { setRolesToState } from 'features/roles/rolesSlice';
import connectionService from 'helpers/connectionIdentifier';
import { setScript } from 'features/script-load/scriptLoadSlice';
import { resetChatsOnLogout } from 'features/messagingChats/messagingChats';
import { UnSubscribeAllChannels } from 'app/communication/services/chat/pub_sub_chat';

export const globalLogout = (shouldRedirect = false) => {
  i18n.changeLanguage('en-US');
  store.dispatch(
    setScript({
      isLoaded: false,
      src: ''
    })
  );
  store.dispatch(setRolesToState({}));
  store.dispatch(logout());

  //   if (!storage.getItem('return-url')) {
  //     storage.setItem('return-url', window.location.pathname);
  //   }

  if (shouldRedirect) {
    setTimeout(() => {
      window.location.href = window.location.origin + '/login';
    }, 1000);
  }

  //Reset MD States & Channels
  clearInterval(window.inter);
  store.dispatch(resetChatsOnLogout());
  if (window.RTC.status) {
    UnSubscribeAllChannels();
    connectionService.ClearAll();
    setTimeout(() => {
      window.RTC.client.disconnect();
      window.RTC.init = false;
      window.RTC.status = false;
      window.RTC.hasLoaded = false;
    }, 1000);
  }
};

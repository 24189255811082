import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { UilTimesCircle } from '@iconscout/react-unicons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Divider from '@material-ui/core/Divider';
import { clearAll, isClearAll } from 'features/analytics/analyticSlice';
import { useDispatch } from 'react-redux';
import storage from 'helpers/storage';
const AppliedFilters = ({
  data,
  title = '',
  note = '',
  handleClose,
  closeTitle = 'Clear All',
  setSelectedFilters
}) => {
  const dispatch = useDispatch();
  const handleOnChange = (e, ind) => {
    let parseData = JSON.parse(JSON.stringify(data));
    let newData = parseData[e];
    if (newData.length) newData.splice(ind, 1);
    parseData[e] = newData;
    setSelectedFilters(parseData);
    storage.setItem('analytics-selected-filters', parseData);
  };

  const handleClearAll = () => {
    let obj = {
      businessTypes: [],
      devices: [],
      features: [],
      hours: [],
      items: [],
      userTypes: []
    };
    // setFilterChangeKey('');
    setSelectedFilters(obj);
    storage.setItem('analytics-selected-filters', obj);
    dispatch(clearAll(true));
  };

  const generateChips = key => {
    if (Object.keys(data).length) {
      return (
        <>
          {data[key].map((feature, child_index) => (
            <Chip
              className="chips-background"
              deleteIcon={<UilTimesCircle />}
              key={child_index}
              label={feature.key}
              color="secondary"
              onDelete={e => {
                e.preventDefault();
                handleOnChange(key, child_index);
              }}
            />
          ))}
        </>
      );
    }
  };
  return (
    <div className="cards-container filter-tags">
      <div className="d-flex justify-content-between">
        <div className="heading mb-16">
          <span>{title}</span>
        </div>
        <div className="filter-clear">
          <span onClick={handleClearAll}>{closeTitle}</span>
          {/* <span onClick={handleClosePanel} className="ml-8">
          <UilTimes size={30} />
        </span> */}
        </div>
      </div>
      <div className="filters-chips">
        <Row>
          <div className="tags-container">
            <Col sm={12}>
              {generateChips('features')}
              {generateChips('devices')}
              {generateChips('userTypes')}
              {generateChips('items')}
              {generateChips('businessTypes')}
              {generateChips('hours')}
            </Col>
          </div>

          {/* {Object.values(data)[2] && Object.values(data)[2].length > 0 && (
            <Col sm={8}>
              <span className="filter-title">Features</span>{' '}
              {generateChips('features')}
            </Col>
          )}
          {Object.values(data)[1] && Object.values(data)[1].length > 0 && (
            <Divider orientation="vertical" flexItem />
          )}
          {Object.values(data)[1] && Object.values(data)[1].length > 0 && (
            <Col sm={3}>
              <span className="filter-title">Devices</span>{' '}
              {generateChips('devices')}
            </Col>
          )}
        </Row>
        {Object.values(data)[5] && Object.values(data)[5].length > 0 && (
          <Divider variant="middle" />
        )}
        <Row>
          {Object.values(data)[4] && Object.values(data)[4].length > 0 && (
            <Col>
              <span className="filter-title">Company Key</span>{' '}
              {generateChips('items')}
            </Col>
          )}
          {Object.values(data)[5] && Object.values(data)[5].length > 0 && (
            <Divider orientation="vertical" flexItem />
          )}
          {Object.values(data)[5] && Object.values(data)[5].length > 0 && (
            <Col sm="2">
              <span className="filter-title">User Types</span>{' '}
              {generateChips('userTypes')}
            </Col>
          )}
          {Object.values(data)[0] && Object.values(data)[0].length > 0 && (
            <Divider orientation="vertical" flexItem />
          )}
          {Object.values(data)[0] && Object.values(data)[0].length > 0 && (
            <Col>
              <span className="filter-title">Type of Business</span>{' '}
              {generateChips('businessTypes')}
            </Col>
          )}
          {Object.values(data)[3] && Object.values(data)[3].length > 0 && (
            <Divider orientation="vertical" flexItem />
          )}
          {Object.values(data)[3] && Object.values(data)[3].length > 0 && (
            <Col>
              <span className="filter-title">Hours</span>{' '}
              {generateChips('hours')}
            </Col>
          )} */}
        </Row>
      </div>
      <div className="tags-note">{note}</div>
    </div>
  );
};

export default AppliedFilters;

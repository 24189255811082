import Skeleton from 'react-loading-skeleton';
import { Card } from 'react-bootstrap';
import { camelize } from 'helpers/generic.methods';
import { useTranslation } from 'react-i18next';

const makeTitleFromKey = key =>
  key ? key.match(/[A-Z][a-z]+/g)?.join(' ') : '';

const CardComponent = ({
  title = '',
  icon,
  value = '',
  footer = '',
  bgColor,
  loading = false,
  showPercentSign = false
}) => {
  const { t } = useTranslation();

  return !loading ? (
    <Card
      className="card-border card-container"
      style={{ background: bgColor }}
    >
      <Card.Title>
        <span className="d-flex align-items-center">
          {icon}{' '}
          <span className="card-heading">
            {' '}
            {t(`company.analytics.${camelize(makeTitleFromKey(title || ''))}`)}
          </span>
        </span>
      </Card.Title>
      <Card.Body className="card-body-custom">
        <div>
          <div className="value">
            {value}
            {showPercentSign ? '%' : ''}
          </div>
          <div className="value position-value">
            {(value || 0).toString().substring(0, 2)}
          </div>
        </div>
        {footer ? (
          <div>Since {t(`company.analytics.${footer || ''}`)}</div>
        ) : null}
      </Card.Body>
    </Card>
  ) : (
    <Card
      className="card-border card-container"
      style={{ background: 'white' }}
    >
      <Card.Title>
        <Skeleton />
      </Card.Title>
      <Card.Body className="card-body-custom">
        <div>
          <Skeleton />
        </div>
        <div>
          <Skeleton />
        </div>
      </Card.Body>
    </Card>
  );
};
export default CardComponent;

import RTC from 'app/communication/rtc';
import { getRealTimeCommunicationChannels } from '../chatroom-service';
import { chatOnPublishCallback } from './callbacks/chat_subscription_callbacks';

const PubhlishToChat = options => {
  options.meta = options.meta || {
    campanyId: options.selectedChat.getCompanyId(),
    chatId: options.selectedChat.getId()
  };
  options.data = options.data || {
    text: options.text,
    participantId: options.selectedChat.getCurrentUserId()
  };

  options.callbacks = options.callbacks || {
    onSuccess: function (args) {
      chatOnPublishCallback.onSuccess({
        data: args.getData(),
        onMessageArrived: options.onMessageArrived
      });
      // checking callback parameter have value and also check callback parameter type is function
      if (options.callback && typeof options.callback == 'function') {
        options.callback();
      }
    },
    onFailure: function (args) {
      chatOnPublishCallback.onFailure(args);
    }
  };
  RTC().Publish({
    meta: options.meta,
    callbacks: options.callbacks,
    channel: `/chatroom/${options.meta.chatId}`,
    data: options.data
  });
};
export const PublishTypingIndicatorToChat = options => {
  console.log('PublishTypingIndicatorToChat::', options.typing);
  options.meta = options.meta || {
    campanyId: options.selectedChat.getCompanyId(),
    chatId: options.selectedChat.getId()
  };
  options.data = options.data || {
    text: 'typing...',
    indicator: options.typing,
    participantId: options.selectedChat.getCurrentUserId(),
    participantDisplayName: options.selectedChat.getPickedUpByUsername()
  };

  options.callbacks = options.callbacks || {
    onSuccess: function () {},
    onFailure: function (args) {
      chatOnPublishCallback.onFailure(args);
    }
  };
  RTC().Publish({
    callbacks: options.callbacks,
    channel: `/chatroom/${options.meta.chatId}/typing`,
    data: options.data
  });
};

export const UnSubscribeChat = chatId => {
  const channels = getRealTimeCommunicationChannels(chatId);
  RemoveChannelsFromRTCCleint(chatId); // remove channels from global variables also
  RTC().UnSubscribe(channels);
};

export const UnSubscribeAllChannels = () => {
  RTC().UnSubscribe(window.RTC.subscribedChannels);
  window.RTC.subscribedChannels = [];
};

export const RemoveChannelsFromRTCCleint = chatId => {
  const channels = getRealTimeCommunicationChannels(chatId);
  let subscribedChannels = window.RTC.subscribedChannels;
  if (subscribedChannels && subscribedChannels.length) {
    let tempsubscribedChannels = subscribedChannels.filter(
      channel => !channels.includes(channel)
    );
    window.RTC.subscribedChannels = tempsubscribedChannels;
  }
};

export default PubhlishToChat;

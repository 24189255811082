import { camelCaseStringToNormalText } from './generic.methods';

export const TimeIntervalsEnum = {
  Day: 1,
  Week: 2,
  Month: 3,
  SixMonths: 4,
  Year: 5
};

const dropdownEnums = {
  /*createEnum takes an Array of object having property "value" & "name" and creates Enum
  example: createEnum([{ value: 1, name: 'enum1' },{ value: 2, name: 'enum2' }])
  Reason behind this effort is we need to have the Enum for dropdown which requies .map for rendering,
  we are using the dropdown_enums and generating Enums so avoiding duplications.*/
  createEnum: array => {
    return Object.freeze(
      array.reduce((obj, props) => {
        if (props && typeof props.name === 'string') {
          obj[props.name] = props.value;
        }
        return obj;
      }, {})
    );
  },
  enum_UserType: [
    { value: 1, name: 'Default' },
    { value: 2, name: 'Agent' },
    { value: 3, name: 'Operator' },
    { value: 4, name: 'VirtualAssistant' },
    { value: 5, name: 'ScriptBot' }
  ],
  enum_Relationship: [
    { value: 1, name: 'Customer' },
    { value: 2, name: 'Partner' },
    { value: 3, name: 'Administrator' }
  ],
  enum_LogLevel: [
    { value: 1, name: 'Debug' },
    { value: 2, name: 'Warn' },
    { value: 3, name: 'Error' }
  ],
  enum_ChatFlowEnableFlag: [
    { value: 0, name: 'Disabled' },
    { value: 1, name: 'Auto_Send' },
    { value: 2, name: 'Suggest_Agent' }
  ],
  enum_CompanyType: [
    { value: 1, name: 'Default' },
    { value: 2, name: 'Dealership' }
  ],
  enum_PacingType: [
    { value: 1, name: 'Leads' },
    { value: 2, name: 'Traffic' }
  ],
  enum_AnalyticsTracking: [
    { value: 1, name: 'None' },
    { value: 2, name: 'GoogleUniversal' },
    { value: 3, name: 'FacebookPixel' },
    { value: 4, name: 'Custom' },
    { value: 5, name: 'GoogleAnalyticsClientId' },
    { value: 6, name: 'GoogleAnalyticsCrossDomainTracking' }
  ],
  enum_LeadType: [
    { value: 1, name: 'Sales' },
    { value: 2, name: 'Service' },
    { value: 3, name: 'Other' },
    { value: 4, name: 'All' }
  ],
  enum_InitiatedBy: [
    // { value: 0, name: 'Unknown', selectorType: 'web' },
    { value: 1, name: 'Bar', selectorType: 'web' },
    // { value: 2, name: 'Dom', selectorType: 'web' },
    // { value: 3, name: 'Window', selectorType: 'web' },
    { value: 4, name: 'Static', selectorType: 'web' },
    { value: 5, name: 'Standard', selectorType: 'web' },
    { value: 6, name: 'Tab', selectorType: 'web' },
    { value: 7, name: 'Exit Popup', selectorType: 'web' },
    { value: 8, name: 'Sms Static', selectorType: 'sms' },
    { value: 9, name: 'Sms Container', selectorType: 'sms' },
    { value: 10, name: 'Facebook', selectorType: 'social' },
    // { value: 11, name: 'Video' },  this option is removed as per requirement in ticket UIUX-646
    { value: 12, name: 'PreChat', selectorType: 'web' },
    { value: 13, name: 'SmsWeb', selectorType: 'sms' },
    // { value: 14, name: 'WebApi' },
    { value: 15, name: 'GMB', selectorType: 'social' }
  ],
  enum_Devices: [
    { value: 1, name: 'Desktop' },
    { value: 2, name: 'Mobile' },
    { value: 3, name: 'Tablet' },
    { value: 4, name: 'Android' },
    { value: 5, name: 'IOS' }
  ],
  enum_UserAgentOperator: [
    { value: 2, name: 'Agent' },
    { value: 3, name: 'Operator' }
  ],
  enum_Workflow: [
    { value: 1, name: 'Standard' },
    { value: 2, name: 'Simple Schedule' }
  ],
  enum_Schedule: [
    { value: 0, name: 'No schedule' },
    { value: 1, name: 'Daily' },
    { value: 2, name: 'Weekly' },
    { value: 3, name: 'Monthly' }
  ],
  enum_Country: [
    { value: 1, name: 'Other' },
    { value: 2, name: 'United States' },
    { value: 3, name: 'Canada' },
    { value: 4, name: 'Australia' }
  ],
  enum_Provision: [
    { value: 1, name: 'Adding Information' },
    { value: 2, name: 'Waiting For Setup' },
    { value: 3, name: 'Need More Information' },
    { value: 4, name: 'Pending Addition To Website' },
    { value: 5, name: 'Completed' }
  ],
  enum_ChatStatus: [
    /// The chat has been initiated by the visitor, but not picked up by the agent
    { value: 1, name: 'AwaitingPickup' },
    /// The chat has been picked up by an agent
    { value: 2, name: 'Active' },
    /// All users have left the chat
    { value: 3, name: 'Inactive' },
    /// A chat instance has triggered, but we don't want to show in the dash yet
    { value: 4, name: 'Requested' },
    /// The chat is currently being handled by the Virtual Assistant
    { value: 5, name: 'BotHandling' },
    /// The chat is currently being handled by the Script Bot
    { value: 6, name: 'ScriptBotHandling' },
    /// The chat is idled for certain amount of time (which is less then chat end time) is marked as shelved
    { value: 7, name: 'Shelved' }
  ],
  enum_TransferChatType: [
    { value: 1, name: 'OperatorToOperator' },
    { value: 2, name: 'TransferToLanguage' },
    { value: 3, name: 'AgentToOperatorWeb' },
    { value: 4, name: 'AgentToOperatorSMS' },
    { value: 5, name: 'ScriptBotTransferToLiveAgent' }
  ],
  enum_Reporting: [
    { value: 0, name: 'Queued' },
    { value: 1, name: 'Running' },
    { value: 2, name: 'Completed' },
    { value: 3, name: 'Completed Without Data' },
    { value: 4, name: 'Failed' }
  ],
  enum_Roles: [
    { value: 'SuperAdministrator', name: 'SuperAdministrator' },
    { value: 'Administrator', name: 'Administrator' },
    { value: 'LimitedAdministrator', name: 'LimitedAdministrator' },
    { value: 'PartnerAdministrator', name: 'PartnerAdministrator' },
    { value: 'PartnerSupervisor', name: 'PartnerSupervisor' },
    { value: 'Reporter', name: 'Reporter' },
    { value: 'CompanyViewer', name: 'CompanyViewer' },
    { value: 'CompanyEditor', name: 'CompanyEditor' },
    {
      value: 'CompanyContactInformationEditor',
      name: 'CompanyContactInformationEditor'
    },
    { value: 'ChatViewer', name: 'ChatViewer' },
    { value: 'ChatEditor', name: 'ChatEditor' },
    {
      value: 'ChatContactInformationViewer',
      name: 'ChatContactInformationViewer'
    },
    { value: 'LeadViewer', name: 'LeadViewer' },
    { value: 'LeadEditor', name: 'LeadEditor' },
    { value: 'ProvisioningRequestViewer', name: 'ProvisioningRequestViewer' },
    { value: 'ProvisioningRequestEditor', name: 'ProvisioningRequestEditor' },
    { value: 'ContactInformationEditor', name: 'ContactInformationEditor' }
  ],
  enum_SlideTypes: {
    companies: {
      value: 'hasCompanyTourViewed'
    },
    chat: {
      value: 'hasChatTourViewed'
    },
    customer: {
      value: 'hasCustomerTourViewed'
    },
    onboarding: {
      value: 'hasOnBoardingTourViewed'
    },
    report: {
      value: 'hasReportTourViewed'
    }
  },
  enum_QuickStats: {
    TotalVisits: { percentage: false },
    TotalChats: { percentage: false },
    TotalLeads: { percentage: false },
    TotalBillableLeads: { percentage: false },
    ClickThroughRate: { percentage: true },
    LeadConversionRate: { percentage: true },
    BlankChats: { percentage: false },
    ActiveCallConnect: { percentage: false },
    CallConnectAttempts: { percentage: false },
    CallConnectDuration: { percentage: false },
    ActiveChats: { percentage: false }
  },
  enum_ImageSource: [
    { src: '/undraw_Hello_re_3evm.png', alt: 'Welcome tour Image' },
    { src: '/finish.png', alt: 'Finish tour Image' }
  ],

  enum_GraphTypeEnum: [
    { name: 'Chats vs Leads', value: 10 },
    { name: 'Features Usage', value: 1 },
    { name: 'Chat Stats By', value: 2 },
    { name: 'Top/Bottom Lead Accounts', value: 5 },
    // non-filterable-graphs
    { name: 'Chat Referrals', value: 6 },
    { name: 'Accounts Activation', value: 3 },
    { name: 'Top Inactive Billers', value: 4 },
    { name: 'Budget/Pacing', value: 7 },
    { name: 'Agent Images CTR', value: 8 },
    { name: 'Accounts', value: 9 }
  ],
  enum_TimeIntervalsEnum: [
    { name: 'Last 24 Hours', value: TimeIntervalsEnum.Day },
    { name: 'Last 7 Days', value: TimeIntervalsEnum.Week },
    { name: 'Last 30 Days', value: TimeIntervalsEnum.Month },
    { name: 'Last 6 Months', value: TimeIntervalsEnum.SixMonths },
    { name: 'Last 12 Months', value: TimeIntervalsEnum.Year }
  ],
  enum_CallStatus: [
    { value: -1, name: 'Idle' },
    { value: 1, name: 'Queued' },
    { value: 2, name: 'Ringing' },
    { value: 3, name: 'In Progress' },
    { value: 4, name: 'Completed' },
    { value: 5, name: 'Busy' },
    { value: 6, name: 'Failed' },
    { value: 7, name: 'No Answer' },
    { value: 8, name: 'Canceled' },
    { value: 9, name: 'Scheduled' },
    { value: 10, name: 'Initializing' }
  ],
  enum_chat_questionTypes: [
    { value: 0, name: 'None' },
    { value: 1, name: 'MultipleChoice' },
    { value: 2, name: 'TrueOrFalse' },
    { value: 3, name: 'Text' },
    { value: 4, name: 'Time' },
    { value: 5, name: 'TimeCalendar' },
    { value: 6, name: 'CalendarDate' },
    { value: 7, name: 'AllThatApply' },
    { value: 8, name: 'FullText' },
    { value: 9, name: 'AppointmentScheduler' }
  ],
  enum_chatEndedMsgs: [
    'The chat session has ended.',
    'This chatroom has been closed due to inactivity.'
  ],
  enum_chatLeftMsgs: [
    'The visitor has left the chat.',
    'All visitors have left the chat.'
  ]
};

export const APIStatus = {
  OK: 200,
  NoContent: 204,
  ExpectationFailed: 417,
  NotFound: 404
};

export const getObjectKeyByValue = (object, value, textCase) => {
  let formatedText = Object.keys(object).find(
    key => object[key] === parseInt(value)
  );
  if (textCase) return camelCaseStringToNormalText(formatedText, textCase);
  else return formatedText;
};
export const RoomType = {
  ActiveChat: 1,
  AllChat: 2,
  IncomingChat: 3,
  ShelvedChat: 4
};

const ServerMessageType = {
  GroupAdded: 1,
  GroupRemoved: 2
};

const ChatMessageType = {
  Simulated: 2147483647,
  Visitor: 0,
  System: -1,
  Bot: -2,
  Prechat: -3,
  Predefined: -4,
  NonVisitorSystem: -6, // system message to be shown on only dashboard end, and not on visitor end
  PokeMessage: -7
};

const ChatPriority = {
  Normal: 1,
  Medium: 2,
  High: 3
};

const _enums = {
  AnalyticsTracking: dropdownEnums.createEnum(
    dropdownEnums.enum_AnalyticsTracking
  ),
  Relationship: dropdownEnums.createEnum(dropdownEnums.enum_Relationship),
  UserType: dropdownEnums.createEnum(dropdownEnums.enum_UserType),
  Roles: dropdownEnums.createEnum(dropdownEnums.enum_Roles),
  graphType: dropdownEnums.createEnum(dropdownEnums.enum_GraphTypeEnum),
  durations: dropdownEnums.createEnum(dropdownEnums.enum_TimeIntervalsEnum),
  ChatStatus: dropdownEnums.createEnum(dropdownEnums.enum_ChatStatus),
  LeadType: dropdownEnums.createEnum(dropdownEnums.enum_LeadType),
  InitiatedBy: dropdownEnums.createEnum(dropdownEnums.enum_InitiatedBy),
  TransferChatType: dropdownEnums.createEnum(
    dropdownEnums.enum_TransferChatType
  ),
  CallConnectStatus: dropdownEnums.createEnum(dropdownEnums.enum_CallStatus),
  ChatQuestionType: dropdownEnums.createEnum(
    dropdownEnums.enum_chat_questionTypes
  ),
  RoomType: RoomType,
  ServerMessageType: ServerMessageType,
  ChatMessageType: ChatMessageType,
  ChatPriority: ChatPriority,
  ChatEndedMessages: dropdownEnums.enum_chatEndedMsgs,
  ChatLeftMsgs: dropdownEnums.enum_chatLeftMsgs
};

export const enum_Relationship = dropdownEnums.enum_Relationship;
export const enum_LogLevel = dropdownEnums.enum_LogLevel;
export const enum_ChatFlowEnableFlag = dropdownEnums.enum_ChatFlowEnableFlag;
export const Enums = _enums;
export default dropdownEnums;

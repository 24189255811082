import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubSidebar from 'components/SubSidebar/index-v2';
import companyRoutes from 'routes/companyRoutes';
import {
  getCompanyAsync,
  // selectCompanies,
  selectCompany
} from 'features/companies/companiesSlice';
import CompanyHeader from 'components/company/CompanyHeader';
import { companySideBarMenu } from 'components/SubSidebar/constants';
import { Scrollbars } from 'react-custom-scrollbars';
import { selectRoles } from 'features/roles/rolesSlice';
import storage from 'helpers/storage';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

const Company = ({
  match,
  selectMenu,
  selectedCompany,
  selectedTab,
  removeSelectedCompanies
}) => {
  const routes = companyRoutes[0] ? companyRoutes[0].subNav : null;
  const dispatch = useDispatch();
  // const { companies } = useSelector(selectCompanies);
  const companyId = selectedCompany.id;
  const { roles } = useSelector(selectRoles);
  const permissions = roles.company || {};
  const { company } = useSelector(e => selectCompany(e, companyId));
  const checkMenu = JSON.parse(storage.getItem('showSubMenu'));
  const [checkSubMenu, setSubMenuCheck] = useState(checkMenu);

  const clickHandlerMenu = () => {
    storage.setItem('showSubMenu', !checkSubMenu);
    setSubMenuCheck(!checkSubMenu);
  };

  useEffect(() => {
    if (
      (!company || !Object.keys(company).length) &&
      companyId === Number(selectedTab)
    ) {
      let result = dispatch(
        getCompanyAsync({
          params: { id: parseInt(companyId), isCrmRequired: true }
        })
      );

      result.catch(err => {
        if (
          err &&
          typeof err === 'string' &&
          err === 'You do not have the permission to view this company..'
        ) {
          removeSelectedCompanies();
          toast.error(`You don't have access to specified company`);
        }
      });
    }
  }, [companyId, selectedTab]);

  useEffect(() => {
    if (!isMobile) {
      storage.setItem('showSubMenu', true);
    } else {
      storage.setItem('showSubMenu', false);
    }
  }, []);

  return (
    <>
      <div>
        <Scrollbars
          className={`${isMobile && checkMenu && 'hide-scroll'}`}
          style={{ borderRadius: '0 0 0 24px', height: 'calc(100vh - 70px)' }}
        >
          <div className="page has-sidebar">
            <CompanyHeader
              companyId={companyId}
              permissionEdit={!!permissions.edit}
            />
          </div>
          <div
            className={`page has-sidebar d-flex `}
            style={{ height: 'calc(100vh - 225px)' }}
          >
            <div
              className={`page-sidebar border-bottom-0 border-right-0 ${
                isMobile && 'subMenu-visibility'
              } ${!checkMenu && ' display-none'}`}
            >
              <SubSidebar
                selectedMenu={
                  (selectedCompany && selectedCompany.selectedMenu) || ''
                }
                selectMenu={selectMenu}
                matchedRoute={match}
                routes={routes}
              />
            </div>
            <div
              className="page-content col px-0"
              style={{
                borderLeft: '1px solid #dfe3e8'
              }}
            >
              <div
                className="page-body"
                style={{
                  borderLeft: '1px solid #dfe3e8'
                }}
              >
                {companySideBarMenu && Object.keys(company).length
                  ? companySideBarMenu.map((menuItem, key1) =>
                      menuItem.subItems.map((submenuItem, key2) =>
                        submenuItem.id === selectedCompany.selectedMenu
                          ? submenuItem.child({
                              company: company,
                              permissionEdit: !!permissions.edit,
                              key: key1 + key2,
                              clickHandlerMenu: clickHandlerMenu
                            })
                          : null
                      )
                    )
                  : null}
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default Company;

import React from 'react';
import './dashboard.scss';
import CountPercentage from './count-percentage';
import { camelize, abbrNum, toolTipPlacement } from 'helpers/generic.methods';
import { useTranslation } from 'react-i18next';
import { TimeIntervalsEnum } from 'helpers/dropdown-enums';
import { OverlayTrigger } from 'react-bootstrap';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import {
  UilInfoCircle,
  UilExclamationTriangle
} from '@iconscout/react-unicons';
const makeTitleFromKey = key =>
  key ? key.match(/[A-Z][a-z]+/g)?.join(' ') : '';

const alreadyCalculatedCards = ['LeadConversionRate', 'ClickThroughRate'];
const noComaprisionCards = [8, 11];
const AnalyticsCard = ({
  widgetId,
  widgetName,
  arrowDirection,
  previous,
  current,
  clickMoreOptions,
  duration,
  info,
  hasInfo,
  errorMsg = ''
}) => {
  let percentage = 0;
  if (alreadyCalculatedCards.includes(widgetName)) {
    percentage = previous;
  } else
    percentage =
      !previous && !current
        ? 0
        : !previous
        ? 100
        : ((current - previous) / previous) * 100;
  const { t } = useTranslation();
  // Fluent Counter work
  // const [count, setCount] = useState(0);
  // FluentCounter(current, setCount);
  return (
    <div className={`analytics-card ${errorMsg ? 'error-border' : ''}`}>
      <div className="analytics-card-header">
        <span className="heading">
          {' '}
          {t(
            `company.analytics.${camelize(makeTitleFromKey(widgetName || ''))}`
          )}
        </span>
        {hasInfo ? (
          <span>
            <OverlayTrigger
              placement={toolTipPlacement}
              overlay={renderToolTip(info)}
            >
              <UilInfoCircle className="" />
            </OverlayTrigger>
          </span>
        ) : null}
      </div>
      {errorMsg ? (
        <div className="error-msg">
          <UilExclamationTriangle />

          <label className="ml-1 mb-0">{errorMsg}</label>
        </div>
      ) : (
        <CountPercentage
          count={abbrNum(Math.round(current), 2)}
          exactValue={current.toFixed(1)}
          heading={t(
            `company.analytics.${camelize(makeTitleFromKey(widgetName || ''))}`
          )}
          arrowDirection={
            alreadyCalculatedCards.includes(widgetName)
              ? arrowDirection
              : percentage > 0
              ? 'up'
              : 'down'
          }
          percentage={Math.abs(Number(percentage).toFixed(2))}
          calculatedInPercentage={alreadyCalculatedCards.includes(widgetName)}
          duration={duration}
          showStatistics={
            duration < TimeIntervalsEnum.SixMonths &&
            !noComaprisionCards.includes(widgetId)
          }
        />
      )}
    </div>
  );
};

export default AnalyticsCard;

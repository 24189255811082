import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Card } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import UserDetails from '../UserDetails';
import Suggestions from '../Suggestions';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChat } from 'features/messagingChats/messagingChats';
import EndChatComfirmationModal from '../EndChatConfirmationModal';
import './UserSuggestions.scss';
import {
  selectChatDetailsRightPane,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import ScrollBar from 'components/Scrollbars';

const UserSuggestions = ({
  setToggleChatDetail,
  setToggleChatSuggestion,
  defaultSuggestionTab,
  setDefaultMessageText
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let selectedChat = useSelector(getSelectedChat);
  const dispatch = useDispatch();
  const onBackButtonHandler = () => {
    setToggleChatDetail(true);
    setToggleChatSuggestion(false);
  };

  const endChat = () => {
    // setLoading(true);
    // setTimeout(() => {
    setToggleChatSuggestion(false);
    setToggleChatDetail(false);
    // setLoading(false);
    // }, 4000);
  };

  const onOpen = () => {
    setOpenModal(true);
  };
  const onClose = () => {
    setOpenModal(false);
  };
  let togglePanel = useSelector(selectChatDetailsRightPane);

  const closeSidePane = () => {
    dispatch(
      toggleChatDetailsRightPane({
        show: false
      })
    );
  };

  return (
    <>
      <div
        className={`mb-3 chat-details-right-pane ${
          togglePanel.show ? 'show' : 'hide'
        }-panel`}
      >
        <div className="close-panel-wrap position-relative">
          <div className="close-panel" onClick={closeSidePane}>
            {isMobile ? (
              <UilArrowLeft />
            ) : // <UilArrowRight />
            null}
          </div>
        </div>
        <Card className="card-rounded grey-card-panel chat-suggestion-header pr-4r">
          <ScrollBar horizontalScroll={false} style={{ margin: '1rem 0' }}>
            <div className="user-details-wrap">
              <UserDetails selectedChat={selectedChat} endChat={onOpen} />
            </div>
            {/* <Tabs
              defaultActiveKey={
                !defaultSuggestionTab ? 'user-details' : 'suggestions'
              }
              id="uncontrolled-tab-example"
              className="tab-right-panel mt-3"
            >
              <Tab
                eventKey="user-details"
                title="User Details"
                className="flex-column align-items-center"
              >
                <UserDetails selectedChat={selectedChat} endChat={onOpen} />
              </Tab>
              <Tab
                eventKey="suggestions"
                title="Suggestions"
                className="flex-column align-items-center"
              >
                <Suggestions
                  setDefaultMessageText={setDefaultMessageText}
                  setToggleChatDetail={setToggleChatDetail}
                  setToggleChatSuggestion={setToggleChatSuggestion}
                />
              </Tab>
            </Tabs> */}
          </ScrollBar>
        </Card>
      </div>

      <EndChatComfirmationModal
        onHide={onClose}
        onSubmit={endChat}
        showModal={openModal}
        loading={loading}
      />
    </>
  );
};

export default UserSuggestions;

import EmojiPopUp from 'components/EmojiPopup';
import React from 'react';
import ActionButtons from './ActionButtons';

const BottomSection = ({
  isChatEnded,
  onEmojiClick,
  inputMessage,
  handleInputChange,
  handleKeyPress,
  sendText,
  selectedChat
}) => {
  let actionButtonsProps = { sendText, selectedChat };

  return (
    <div className={`row chat-message-input ${isChatEnded && 'chat-disabled'}`}>
      <div className="input-action-icon emojis-wrap">
        <EmojiPopUp
          onEmojiClick={onEmojiClick}
          pickerStyle={{ position: 'absolute', bottom: '58px', left: '0' }}
        />
      </div>
      <input
        id="msg-input"
        type="text"
        name="message-input"
        placeholder="Type your message here"
        className="form-control col"
        value={inputMessage || ''}
        onChange={handleInputChange}
        // disabled={isChatEnded}
        onKeyPress={handleKeyPress}
      />
      <ActionButtons {...actionButtonsProps} />
    </div>
  );
};

export default BottomSection;

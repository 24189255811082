import {
  RemoveChannelsFromRTCCleint,
  UnSubscribeChat
} from 'app/communication/services/chat/pub_sub_chat';
import {
  endChatAsync,
  removeSelectedChat
} from 'features/messagingChats/messagingChats';
import { errors, success } from 'helpers/variables';
import react, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { UilSignout } from '@iconscout/react-unicons';
import ButtonWithConfirmLoader from 'components/ButtonWithConfimLoader';
import './ChatEndButton.scss';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const ChatEndButton = ({
  selectedChat,
  isDisabled,
  setToggleChatDetail,
  setToggleChatSuggestion,
  setToggleActiveChats
}) => {
  const [chatEnding, setChatEnding] = useState(false);
  const dispatch = useDispatch();

  const onConfirmEndChat = async () => {
    setChatEnding(true);
    let chatEnded = await dispatch(endChatAsync(selectedChat.getId()));
    if (chatEnded) {
      toast.success(success.chatEnd);
      UnSubscribeChat(selectedChat.getId());
    } else toast.error(errors.failure);
    setChatEnding(false);
  };

  const onCloseChat = () => {
    setToggleChatSuggestion(false);
    setToggleChatDetail(false);
    RemoveChannelsFromRTCCleint(selectedChat.getId());
    if (isMobile) {
      setToggleActiveChats(false);
    }

    dispatch(removeSelectedChat());
  };

  return (
    <>
      {!isDisabled && (
        <ButtonWithConfirmLoader
          title="End Chat"
          loading={chatEnding}
          isDisabled={isDisabled}
          onConfirm={onConfirmEndChat}
          buttonIcon={<UilSignout />}
          loaderDuration={3000}
          loaderMode="determinate"
          classes="btn-end-chat"
          variant={'outline-danger'}
          confirmVariant={'outline-success'}
          confirmButtonType={'button'}
          showConfirmButton={true}
        />
      )}

      <Button
        variant={'outline-danger'}
        size="sm"
        className={'chat-close-btn ml-2'}
        onClick={onCloseChat}
      >
        <i className="uil uil-times cross-icon"></i>
      </Button>
    </>
  );
};

export default ChatEndButton;

export const fr_tour = {
  tourWelcome:
    'Hola <user>, bienvenido al nuevo tablero de instrumentos de chat.',
  tourWelcomeText:
    'Echemos un rápido recorrido por el nuevo tablero de instrumentos de chat de mostrarle los alrededores y familiarizarse con la funcionalidad y cambios que puede hacer ahora.',
  tourAnalytics: 'Bienvenido al nuevo panel de análisis. Pronto habrá más!',
  tourMessaging:
    'La nueva zona de mensajería es muy pronto. Utilice el botón de redirección para manejar los chats en la plataforma actual.',
  tourCompanies:
    'La pestaña de empresa le permite realizar cambios en su cuenta.',
  tourChats: 'Chats entrantes manejados durante los últimos seis meses.',
  tourLeads: 'Clientes de chat entrantes durante los últimos seis meses.',
  tourReports:
    'La pestaña de informes estará disponible en breve. Puede ver o redirigir al portal existente para la funcionalidad de informes.',
  tourOnboarding:
    'La ficha de incorporación es muy pronto. Puede ver o redirigir a la zona de aprovisionamiento dentro del portal existente para editar o presentar una nueva cuenta.',
  tourHelp:
    '<p><b>BOT DE SOPORTE</b> - el bot de soporte está aquí para responder preguntas y enviar un ticket a nuestro equipo interno, que se comunicará contigo.</p><p><b>VISITA DE LANZAMIENTO</b> - haz un recorrido rápido y familiarízate con el nuevo diseño.</p>',
  tourSwitch: 'Puede cambiar entre los sistemas nuevos y existentes.',
  tourEnd: 'Ese es el final de la gira.',
  tourEnd2:
    'Esperamos que hayan disfrutado de la gira y mira adelante a traer más características para el nuevo tablero de instrumentos de chat pronto. Si tiene alguna pregunta, por favor llegar a nosotros. Siempre estamos aquí para ayudar.',
  btn1: 'Quizas mas tarde',
  btn2: 'Empezar recorrido',
  btn3: 'Anterior',
  btn4: 'Terminar',
  btn5: 'próximo'
};

export default fr_tour;

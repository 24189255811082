import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export const Front = props => {
  return <div className="front tile">{props.children}</div>;
};

export const Back = props => {
  return <div className="back tile">{props.children}</div>;
};

const Flipper = ({ orientation, flipped, children, flipperStyles }) => {
  return (
    <div className={'flipper-container ' + orientation} style={flipperStyles}>
      <div className={'flipper' + (flipped ? ' flipped' : '')}>{children}</div>
    </div>
  );
};

Flipper.propTypes = {
  orientation: PropTypes.string,
  flipped: PropTypes.bool.isRequired,
  flipperStyles: PropTypes.object,
  children: PropTypes.node.isRequired
};

Flipper.defaultProps = {
  orientation: 'horizontal', //vertical
  flipped: false,
  flipperStyles: {}
};

export default Flipper;

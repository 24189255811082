export const errors = {
  empty: 'Should not be empty',
  samePassword: 'Password must match',
  changepassword: 'Password can not be changed',
  login: 'You have entered invalid user information, please try again',
  fieldError: name => `The ${name.toLowerCase()} field should not be empty`,
  fieldCorrection: name => `Please use the correct ${name}`,
  inValidAuthToken: `Your session has expired, please login again.`,
  saveError: name => `Something went wrong while saving the changes in ${name}`,
  notValid: {
    email: 'Should be a valid email address',
    phone: 'should be a valid phone number',
    phoneNumber:
      'Should be a valid phone number, minimum 10 digits e.g., 123-456-7890',
    url: 'Should be a valid URL',
    zip: 'Must only contain numbers',
    zipLength: 'Max 15 Characters Allowed',
    cityLength: 'City exceeds the maximum length of 50 characters',
    phoneLength: 'Max 17 Characters Allowed',
    specialCharacter: 'This special character is not allowed',
    text: 'Must only contain alphabets',
    name: 'Should be a valid name',
    address: 'Should be a valid address',
    multiemail: 'Enter valid email address with , without space for other email'
  },
  networkError: 'Network error, please check your connectivity',
  failure: 'Something went wrong'
};
export const success = {
  forgotPassword:
    'Password has been successfully reset, please check your email',
  saveSuccess: name => `${name} has been saved successfully`,
  sendSuccess: name => `${name} has been sent successfully`,
  callConnectSuccess:
    'Your request has been received the call will be connected soon',
  changePassword: 'Your password has been changed successfully',
  chatEnd: `Chat has been ended successfully`,
  statusChange: `Status has been changed successfully`,
  updateLead: chatId => `${chatId} updated`
};

export const duration = {
  timeout5s: 5000,
  copiedBadge: { duration: 2600, delay: 300 }
};

export const theme = {
  blue: '#2d2d95',
  primary: '#2d2d95',
  darkgray: '#212b36',
  secondary: '#212b36',
  red: '#bf0711',
  danger: '#bf0711'
};

export const breakpoints = {
  tabletAndSmallerDevices: 991
};

export const domainExceptionList = [
  'http://testportal.vialivechat.com/',
  'http://192.168.0.132/'
];

export const unAuthorizedErrors = [
  'Invalid JWT token',
  "Authorization header sent, but was not in the format 'Bearer {token}'"
];

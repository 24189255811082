// import SidebarRight from 'components/Sidebar/sidebar-right';
import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { getUserById, getUsersByCompany } from 'features/users/userSlice';
import { Row, Col, Button } from 'react-bootstrap';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import './styles.scss';
import { UilEye } from '@iconscout/react-unicons';
import UserModal from '../../modal/UserModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';

const gridStyle = {
  minHeight: 'calc(100vh - 320px)',
  marginTop: 10,
  boxShadow: '0px 3px 10px 0 rgba(0, 0, 0, 0.08)',
  overflow: 'hidden'
};

const Users = ({ company, clickHandlerMenu }) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const selectedData = {};
  const { t } = useTranslation();

  const handleShow = id => {
    setShow(true);
    if (id)
      getUserById({
        params: { id }
      }).then(res => {
        if (res.data) {
          const { data } = res.data;
          setSelected(data);
        }
      });
  };
  const handleClose = () => {
    setSelected({});
    setShow(false);
  };

  const gridColumns = [
    {
      name: 'id',
      header: 'ID',
      className: 'hide-filter-button',
      defaultFlex: 2
    },
    { name: 'username', header: 'User name', defaultFlex: 3 },
    {
      name: 'displayName',
      header: 'Display name',
      defaultFlex: 2
    },
    {
      name: 'email',
      header: 'Email',
      defaultFlex: 2
      // render: ({ value }) => moment(value).format('DD MMMM YYYY')
    },
    {
      name: 'phone',
      header: 'Phone',
      defaultFlex: 2
    },
    {
      name: 'skills',
      header: 'Skills',
      defaultFlex: 2
    },
    {
      name: 'action',
      header: 'Action',
      defaultFlex: 2,
      sortable: false,
      render: value => (
        <Button
          className="btn-sm btn-flex"
          variant="lightgray-3"
          // onClick={e => viewHandler(value.data)}
        >
          <UilEye className="mr-1" />
          View
        </Button>
      )
    }
  ];

  const filterValue = [
    {
      name: 'id',
      operator: 'contains',
      type: 'textType',
      value: ''
    },
    { name: 'username', operator: 'contains', type: 'textType', value: '' },
    {
      name: 'displayName',
      operator: 'contains',
      type: 'textType',
      value: ''
    },
    { name: 'email', operator: 'contains', type: 'textType', value: '' },
    { name: 'phone', operator: 'contains', type: 'textType', value: '' },
    { name: 'skills', operator: 'contains', type: 'textType', value: '' }
    // { name: 'active', type: 'select', value: '' },
    // { name: 'createdOn', type: 'date', value: '' },
    // { name: 'quickConnectEnabled', type: 'select', value: '' }
  ];

  const filterTypes = Object.assign(
    {},
    ReactDataGrid.defaultProps.filterTypes,
    {
      textType: {
        name: 'textType',
        operators: [
          {
            name: 'contains'
          },
          {
            name: 'startsWith'
          },
          {
            name: 'endsWith'
          }
        ]
      }
    }
  );

  const getFilterValue = data => {
    let updatedValue = data.value;

    if (data.name === 'createdOn') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'id') {
      updatedValue = Number(updatedValue);
    } else if (data.operator === 'contains') {
      updatedValue = `%${updatedValue}%`;
    } else if (data.operator === 'startsWith') {
      updatedValue = `${updatedValue}%`;
    } else if (data.operator === 'endsWith') {
      updatedValue = `%${updatedValue}`;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setDataLimit(skip);
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(v),
          propertyValue2: '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        companyId: company.id,
        query: {
          start: skip,
          limit: limit,
          filter: JSON.stringify(updatedValue),
          dir: sortInfo && sortInfo.dir === 1 ? 'ASC' : 'DESC',
          sort: (sortInfo && sortInfo.name) || 'id'
        }
      };
      return getUsersByCompany(payload).then(response => {
        const totalCount = 2147483647;
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  const handleSelect = payload => {
    const { data } = payload;
    let user = data[0];
    handleShow(user.id);
  };

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);
  return (
    <>
      <div className="users-container">
        <Row>
          <Col className="m-3">
            <ToggleMenuButton
              heading={t('company.general.menuItem5')}
              clickHandlerMenu={clickHandlerMenu}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mobile_view">
              <ReactDataGrid
                idProperty="id"
                style={gridStyle}
                columns={gridColumns}
                limit={20}
                pagination
                livePagination
                dataSource={dataSource}
                // onLoadingChange={setLoading}
                defaultFilterValue={filterValue}
                filterTypes={filterTypes}
                showHoverRows={false}
                showCellBorders={false}
                showColumnMenuTool={true}
                loadingText={
                  dataLimit === 0 ? 'Loading...' : 'Loading next 20 records...'
                }
                renderColumnContextMenu={renderColumnContextMenu}
                selected={selectedData}
                onSelectionChange={handleSelect}
              />
            </div>
          </Col>
        </Row>
      </div>
      <UserModal
        title={'User Information'}
        editData={selected}
        eventHideModal={handleClose}
        showModal={show}
      />
    </>
  );
};

export default Users;

import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { ChatAPI } from 'api/endpoints';
import { Enums } from 'helpers/dropdown-enums';
import UseNotification from '../../components/notification/notification';
import { getPlainStringFromHTMLElementAsString } from 'helpers/validate';
import Chatroom from 'app/communication/services/chatroom-service';
import storage from 'helpers/storage';
import { camelCaseStringToNormalText } from 'helpers/generic.methods';

const initialState = {
  incomingChats: [],
  allChats: [],
  activeChats: [],
  shelvedChats: [],
  unsentLeadChats: [],
  selectedChat: {},
  chats: {},
  splashScreen: { text: 'Loading components...', count: 0 },
  chatEnded: [],
  enableNotificationSound: true,
  fetchingUnsentLeads: false,
  messageRecived: false
};
/**
 * @name @createSlice
 * @description create redux store slice for Messaging Chats
 **/

export const MessagingChatSlice = createSlice({
  name: 'MessagingChats',
  initialState: { ...initialState },

  reducers: {
    /**
     * @description set Messaging Chats instance into store
     **/

    setIncomingChatList: (state, action) => {
      //  add chats in incomingChats State
      state.incomingChats = action.payload;
    },

    setActiveChatList: (state, action) => {
      //  add chats in activeChats State
      // console.log(state, action);
      state.activeChats = action.payload;
    },

    setAllChatList: (state, action) => {
      //  add chats in allChats State
      // console.log(state, action);
      state.allChats = action.payload;
    },

    setShelvedChatList: (state, action) => {
      //  add chats in shelvedChat State
      state.shelvedChats = action.payload;
    },

    setUnsentLeadChatList: (state, action) => {
      //  add chats in allChats State
      // console.log(state, action);
      state.unsentLeadChats = action.payload;
    },

    setSelectedChat: (state, action) => {
      //  add chat in selectedChat State
      let chatInfo = action.payload;
      let isFromIncomingSection = chatInfo.isFromIncomingSection;
      let selectedId = chatInfo.getId();
      let chatState = JSON.parse(JSON.stringify(state, undefined, 2));
      state.selectedChat = action.payload;
      if (!(selectedId in chatState.chats)) {
        let _incomingChats = state.incomingChats;
        let chat = _incomingChats.filter(
          (ch, id, obj) => ch.data.chatId === selectedId
        );
        let chatIndex = _incomingChats.findIndex(
          ch => ch.data.chatId === selectedId
        );
        if (chat.length) {
          chat[0]['hasSubscribed'] = true;
          console.log('_activeChats:: chat ', chat);
          state.incomingChats[chatIndex] = chat[0];
        }

        state.chats[`${selectedId}`] = {
          chatInfo: {
            ...chatInfo,
            callConnectStatus: Enums.CallConnectStatus.Idle
          },
          messages: [],
          inputMessage: '',
          isTyping: false,
          unreadMsgsCount: 0,
          status: true
        };
      } else if (
        selectedId in chatState.chats &&
        !chatState.chats[selectedId]['chatInfo']
      ) {
        state.chats[`${selectedId}`]['chatInfo'] = {
          ...chatInfo,
          callConnectStatus: Enums.CallConnectStatus.Idle
        };
        state.chats[`${selectedId}`]['unreadMsgsCount'] = 0;
        state.chats[`${selectedId}`]['status'] = true;
      } else {
        state.chats[`${selectedId}`]['unreadMsgsCount'] = 0;
        state.chats[`${selectedId}`]['status'] = true;
      }

      //  Remove unused references from chats object
      let activeIds = [];
      let allIds = [];
      let shelvedIds = [];
      let unsentLeadsIds = [];
      if (state.activeChats.length) {
        activeIds = state.activeChats.map(chat => chat.getId());
      }

      if (state.allChats.length) {
        allIds = state.allChats.map(chat => chat.getId());
      }

      if (state.shelvedChats.length) {
        shelvedIds = state.shelvedChats.map(chat => chat.getId());
      }

      if (state.unsentLeadChats.length) {
        unsentLeadsIds = state.unsentLeadChats.map(chat => chat.chatId);
      }

      let mergedIds = new Set([
        ...activeIds,
        ...allIds,
        ...shelvedIds,
        ...unsentLeadsIds
      ]);
      for (let chatId in state.chats) {
        if (
          !mergedIds.has(Number(chatId)) &&
          state.selectedChat.getId() != chatId &&
          !isFromIncomingSection
        ) {
          delete state.chats[chatId];
        }
      }

      //  Remove ended chat references from chats object
      if (state.chatEnded.length) {
        state.chatEnded.map(chatId => {
          if (chatId in state.chats && !unsentLeadsIds.includes(chatId)) {
            delete state.chats[chatId];
          }
        });

        state.chatEnded = [];
      }
    },
    setUnreadMessage: (state, action) => {
      let chatId = action.payload;

      if (
        chatId in state.chats &&
        (!state.selectedChat.getId ||
          (state.selectedChat.getId && state.selectedChat.getId() !== chatId))
      ) {
        state.chats[chatId]['unreadMsgsCount'] += 1;
      }
    },

    saveChatMessage: (state, action) => {
      //  add chat in selectedChat State
      let data = action.payload;
      let selectedId = data.chatId;
      if (selectedId) {
        if (!(selectedId in state.chats)) {
          state.chats[`${selectedId}`] = {
            messages: [data],
            //  TODO:unreadMsgsCount:1 was added for MD-27, but now set to zero '0' for MD-169-subscribed-on-refresh scenario
            unreadMsgsCount: 0
          };
        } else {
          let chatState = JSON.parse(JSON.stringify(state, undefined, 2));
          let msgs = chatState.chats[selectedId].messages;
          console.log('saveChatMessages::', data.id);
          if (msgs.length > 0) {
            // let lastIndex = msgs.length - 1;
            // let lastMsg = msgs[lastIndex];
            // if (lastMsg.id !== data.id) {
            if (!msgs.some(msg => msg.id === data.id)) {
              msgs.push(data);
              state.chats[selectedId].messages = msgs;
            }
          } else if (msgs.length === 0) {
            msgs.push(data);
            state.chats[selectedId].messages = msgs;
          }
        }
      }
    },
    pushSplashScreenMeta: (state, action) => {
      state.splashScreen.text = action.payload.text;
      state.splashScreen.count += action.payload.count;
      console.log(
        'LOADING PROGRESS::',
        state.splashScreen.text,
        state.splashScreen.count
      );
    },
    saveInputMessage: (state, action) => {
      //  add input text in current selected chat
      let data = action.payload;
      let selectedId = data.id;
      if (selectedId in state.chats) {
        state.chats[`${selectedId}`].inputMessage = data.text;
      }
    },
    saveChatTypingIndicator: (state, action) => {
      //  add input text in current selected chat
      let data = action.payload;
      let selectedId = data.id;
      if (selectedId in state.chats) {
        state.chats[`${selectedId}`].isTyping = data.typing;
      }
    },

    setVisitorStatus: (state, action) => {
      // update visitor status on chats
      let data = action.payload;
      let selectedId = data.chatId;
      if (selectedId in state.chats) {
        state.chats[`${selectedId}`].status = data.status;
      }
    },

    pushChatRoom: (state, action) => {
      // TODO: remove duplication of chat and push to respective chat array by its type
      let { room, includeInSections, excludeInSections } = action.payload;
      console.log('pushChatRoom::', includeInSections);

      // add chat to active chat list
      if (includeInSections.includes(Enums.RoomType.ActiveChat)) {
        if (room.getId() in state.chats && state.chats[room.getId()].chatInfo) {
          let tempInfo = state.chats[room.getId()].chatInfo;
          state.chats[room.getId()].chatInfo = { ...tempInfo, ...room };
        }

        if (!state.activeChats.some(item => item.getId() === room.getId())) {
          state.activeChats.push(room);
        }
      }

      // add chat to all chat list
      if (includeInSections.includes(Enums.RoomType.AllChat)) {
        if (!state.allChats.some(item => item.getId() === room.getId())) {
          state.allChats.push(room);
        }
      }

      // add chat to incoming chat list
      if (includeInSections.includes(Enums.RoomType.IncomingChat)) {
        if (!state.incomingChats.some(item => item.getId() === room.getId())) {
          state.incomingChats.push(room);
        }
      }

      // add chat to shelved chat list
      if (includeInSections.includes(Enums.RoomType.ShelvedChat)) {
        if (!state.shelvedChats.some(item => item.getId() === room.getId())) {
          state.shelvedChats.push(room);
        }
      }

      // remove chat from incoming chat list
      if (excludeInSections.includes(Enums.RoomType.IncomingChat)) {
        state.incomingChats = state.incomingChats.filter(
          chat => room.getId() !== chat.getId()
        );
      }

      // remove chat from active chat list
      if (excludeInSections.includes(Enums.RoomType.ActiveChat)) {
        state.activeChats = state.activeChats.filter(
          chat => room.getId() !== chat.getId()
        );
        // remove chat's unread message count from chats state
        if (room.getId() in state.chats) {
          state.chats[room.getId()].unreadMsgsCount = 0;
        }
      }

      // remove chat from all chat list
      if (excludeInSections.includes(Enums.RoomType.AllChat)) {
        state.allChats = state.allChats.filter(
          chat => room.getId() !== chat.getId()
        );
      }

      // remove chat from shelved chat list
      if (excludeInSections.includes(Enums.RoomType.ShelvedChat)) {
        state.shelvedChats = state.shelvedChats.filter(
          chat => room.getId() !== chat.getId()
        );
      }
    },
    chatEnded: (state, action) => {
      if (!state.chatEnded.includes(action.payload)) {
        state.chatEnded.push(action.payload);
      }
    },
    setEnableNotificationSound: (state, action) => {
      state.enableNotificationSound = !!action.payload;
    },
    setMessagesRecived: (state, action) => {
      state.messageRecived = !!action.payload;
    },
    endChatReference: (state, action) => {
      let id = action.payload;
      let incoming = state.incomingChats;
      let active = state.activeChats;
      let all = state.allChats;
      let shelved = state.shelvedChats;
      // let unsentLead = state.unsentLeadChats;

      incoming = incoming.filter(chat => chat.getId() !== id);
      active = active.filter(chat => chat.getId() !== id);
      all = all.filter(chat => chat.getId() !== id);
      shelved = shelved.filter(chat => chat.getId() !== id);
      // unsentLead = unsentLead.filter(chat => chat.chatId !== id);
      state.incomingChats = incoming;
      state.activeChats = active;
      state.allChats = all;
      state.shelvedChats = shelved;
      // state.unsentLeadChats = unsentLead;

      let endedIdIndex = state.chatEnded.indexOf(id);
      if (endedIdIndex) {
        state.chatEnded.splice(endedIdIndex, 1);
      }

      state.selectedChat = {};

      if (id in state.chats) {
        delete state.chats[id];
      }
    },

    removeFromIncomingSection: (state, action) => {
      let id = action.payload;
      let incoming = state.incomingChats.filter(chat => chat.getId() !== id);
      state.incomingChats = incoming;
    },

    resetChatsOnLogout: (state, action) => {
      // console.log('resetChatsOnLogout::', initialState);
      // state = { ...initialState };
      state.allChats = [];
      state.activeChats = [];
      state.incomingChats = [];
      state.shelvedChats = [];
      state.unsentLeadChats = [];
      state.selectedChat = {};
      state.chats = {};
      state.splashScreen = { text: 'Loading components...', count: 0 };
      state.chatEnded = [];
      console.log('state::', JSON.parse(JSON.stringify(state)));
      //  also reset global variable RTC //
      // window.RTC.status = false;
      // window.RTC.subscribedChannels = [];
      // window.RTC.client = null;
    },

    setUnsentLeadFetching: (state, action) => {
      state.fetchingUnsentLeads = action.payload;
    },
    saveChatToShelved: (state, action) => {
      let id = action.payload;
      let data = state.chats[id]?.chatInfo;
      state.activeChats = state.activeChats.filter(chat => chat.getId() !== id);
      if (!state.shelvedChats.some(item => item.getId() === id) && data) {
        state.shelvedChats.push(data);
      }
    },

    removeChatFromShelved: (state, action) => {
      let data = action.payload;
      state.shelvedChats = state.shelvedChats.filter(
        chat => chat.getId() !== data.id
      );
    },

    setCallConnectStatus: (state, action) => {
      let data = action.payload;
      let selectedId = data.chatId;
      if (selectedId in state.chats) {
        state.chats[`${selectedId}`]['chatInfo']['callConnectStatus'] =
          data.status;
      }
    },
    setChatInfoDataInChats: (state, action) => {
      // let data = action.payload;
      let allChats = state.chats;
      let { chatId, chatInfoData } = action.payload;
      if (
        chatId in allChats &&
        allChats[chatId]?.chatInfo &&
        allChats[chatId]?.chatInfo?.data
      ) {
        state.chats[`${chatId}`]['chatInfo']['data'] = {
          ...allChats[chatId]?.chatInfo?.data,
          ...chatInfoData
        };
      }
    },

    updateSelectedChat: (state, action) => {
      if ('data' in state.selectedChat) {
        state.selectedChat['data'] = {
          ...state.selectedChat['data'],
          ...action.payload
        };
      }
    },

    //  reset SelectedChat state to empty object {}
    removeSelectedChat: (state, action) => {
      state.selectedChat = {};
    }
  }
});

export const {
  setAllChatList,
  setActiveChatList,
  setIncomingChatList,
  setShelvedChatList,
  setSelectedChat,
  saveChatMessage,
  pushChatRoom,
  pushSplashScreenMeta,
  saveInputMessage,
  chatEnded,
  saveChatTypingIndicator,
  setEnableNotificationSound,
  endChatReference,
  removeFromIncomingSection,
  setUnreadMessage,
  setVisitorStatus,
  setUnsentLeadChatList,
  setUnsentLeadFetching,
  resetChatsOnLogout,
  updateSelectedChat,
  removeSelectedChat,
  saveChatToShelved,
  removeChatFromShelved,
  setMessagesRecived,
  setCallConnectStatus,
  setChatInfoDataInChats
} = MessagingChatSlice.actions;

/* called a selector and allows to select values from the state */
export const getAssignedGroups = () => {
  return ApiServices.WCF_get(ChatAPI.getGroups);
};

export const getIncomingChatList = state => {
  return state.MessagingChats.incomingChats;
};
export const getActiveChatList = state => {
  return state.MessagingChats.activeChats;
};

export const getShelvedChatList = state => {
  return state.MessagingChats.shelvedChats;
};

export const getUnsentLeads = state => {
  return state.MessagingChats.unsentLeadChats;
};

export const getUnsentLeadsLoading = state => {
  return state.MessagingChats.fetchingUnsentLeads;
};

export const getUnSubscribedIncomingChatList = state => {
  return state.MessagingChats.incomingChats.filter(chat => !chat.hasSubscribed);
};

export const isActiveChatUnsubscribed = state => chatId => {
  let allChats = state.MessagingChats.chats;
  let isSubscribed = false;
  if (chatId in allChats && allChats[chatId].chatInfo) {
    isSubscribed = true;
  }

  return isSubscribed;
};
export const getAllChatList = state => {
  return state.MessagingChats.allChats;
};
export const getSplashScreenMeta = state => {
  return state.MessagingChats.splashScreen;
};
export const getSelectedChat = state => {
  return state.MessagingChats.selectedChat;
};
export const getSelectedChatId = state => {
  let selectedChat = state.MessagingChats.selectedChat;
  if (Object.keys(selectedChat).length) {
    return selectedChat.getId();
  } else {
    return null;
  }
};
export const getSelectedChatById = chatId => state => {
  // console.log('getbyid func chat id :: ', chatId);
  return state.MessagingChats.chats[chatId];
};
export const getChatInfoById = chatId => state => {
  // console.log('getbyid func chat id :: ', chatId);
  return state.MessagingChats.chats[chatId]?.chatInfo || {};
};

export const getSelectedChatCallStatus = chatId => state => {
  let status = Enums.CallConnectStatus.Idle;
  if (state.MessagingChats.chats[chatId]) {
    let info = state.MessagingChats.chats[chatId].chatInfo;
    if (info) {
      status = info.callConnectStatus;
    }
  }

  return status;
};

export const getSelectedChatLastMessage = state => chatId => {
  let lastMessage = { text: '' };
  if (state.MessagingChats.chats[chatId]) {
    let messages = state.MessagingChats.chats[chatId].messages;
    if (messages && messages.length) {
      lastMessage = messages[messages.length - 1];
      // lastMessage.text = getPlainStringFromHTMLElementAsString(lastMessage.text);
    }
  }

  return lastMessage;
};

export const endChatAsync = payload => async dispatch => {
  return ApiServices.WCF_post(ChatAPI.endChat(payload))
    .then(async response => {
      await dispatch(chatEnded(payload));
      return Promise.resolve(response.data.success);
    })
    .catch(async err => {
      return Promise.resolve('Error:' + err);
    });
};

export const changeMessagingDashboardStatusAsync =
  payload => async dispatch => {
    return ApiServices.WCF_post(ChatAPI.messagingDashboardStatus(payload))
      .then(async response => {
        // await dispatch(setStatus(response.data.success));
        return Promise.resolve(response.data.success);
      })
      .catch(async err => {
        return Promise.resolve('Error:' + err);
      });
  };

export const updateUnsentLeadStatus = payload => async dispatch => {
  return ApiServices.WCF_post(ChatAPI.updateUnsentLead(payload))
    .then(async response => {
      if (response.data.success) {
        setTimeout(() => {
          dispatch(getUnsentLeadListAsync());
        }, 5000);
        dispatch(updateSelectedChat({ isLead: payload.isLead }));
        return Promise.resolve(response.data.success);
      }
    })
    .catch(async err => {
      return Promise.resolve('Error:' + err);
    });
};

export const getUnsentLeadListAsync = () => async dispatch => {
  dispatch(setUnsentLeadFetching(true));
  return ApiServices.WCF_get(ChatAPI.getUnsentLeads)
    .then(async response => {
      if (response.data.success) {
        await dispatch(setUnsentLeadChatList(response.data.data));
        dispatch(setUnsentLeadFetching(false));
        dispatch(updateSelectedChat({ fetchLeads: false }));
        return Promise.resolve(response.data.success);
      }
    })
    .catch(async err => {
      dispatch(setUnsentLeadFetching(false));
      return Promise.resolve('Error:' + err);
    });
};

export const getChatRoomData = payload => async dispatch => {
  return ApiServices.WCF_get(ChatAPI.getChatRoomData(payload))
    .then(async response => {
      if (response.data.success) {
        let chat = new Chatroom(response.data.data);
        return Promise.resolve(chat);
      }
    })
    .catch(async err => {
      return Promise.reject('Error:' + err);
    });
};

export const getSelectedChatFirstMessage = state => chatId => {
  let firstMessage = null;
  if (state.MessagingChats.chats[chatId]) {
    let messages = state.MessagingChats.chats[chatId].messages;
    if (messages && messages.length) {
      firstMessage = messages[0];
    }
  }

  return firstMessage;
};

export const getSelectedChatLastVisitorMessage = state => chatId => {
  let visitorLastMessage = null;
  if (state.MessagingChats.chats[chatId]) {
    let messages = state.MessagingChats.chats[chatId].messages;
    if (messages && messages.length) {
      visitorLastMessage = messages
        .slice()
        .reverse()
        .find(msg => msg.userId === 0);
    }
  }

  return visitorLastMessage;
};

export const getSelectedChatLastVisitorOrUserMessage = state => chatId => {
  let visitorLastMessage = null;
  if (state.MessagingChats.chats[chatId]) {
    let messages = state.MessagingChats.chats[chatId].messages;
    if (messages && messages.length) {
      visitorLastMessage = messages
        .slice()
        .reverse()
        .find(msg => msg.userId >= 0);
    }
  }

  return visitorLastMessage;
};

export const getSelectedChatStarted = state => chatId => {
  let isStarted = false;
  if (state.MessagingChats.chats[chatId]) {
    let messages = state.MessagingChats.chats[chatId].messages;
    if (messages && messages.length) {
      isStarted = true;
    }
  }

  return isStarted;
};

export const getSelectedChatTypingIndicator = state => chatId => {
  let typing = false;
  if (state.MessagingChats.chats[chatId]) {
    typing = state.MessagingChats.chats[chatId].isTyping;
  }

  return typing;
};

export const getSelectedVisitorStatus = state => chatId => {
  let status = false;
  if (state.MessagingChats.chats[chatId]) {
    status = state.MessagingChats.chats[chatId].status;
  }

  return status;
};

export const getEnableNotificationSound = state => {
  return state.MessagingChats.enableNotificationSound;
};
export const getUnreadMsgsCountByChatId = state => chatId => {
  let count = 0;
  if (state.MessagingChats.chats[chatId]) {
    count = state.MessagingChats.chats[chatId].unreadMsgsCount;
  }

  return count;
};
export const getAllUnreadMsgsCounts = state => {
  let count = 0;
  let chatsLength = Object.keys(state.MessagingChats.chats).length;
  if (chatsLength) {
    for (let chat in state.MessagingChats.chats) {
      count += state.MessagingChats.chats[chat].unreadMsgsCount;
    }
  }

  return count;
};
export const getMessageRecived = state => {
  // console.log(state.MessagingChats, 'aaaaa');
  return state.MessagingChats.messageRecived;
};

export const getVisitorInfoName = state => chat => {
  let visitorName = '';
  let chatId = chat.chatId ? chat.chatId : chat.getId();
  let allChats = state.MessagingChats.chats;

  let getTitleCase = name =>
    camelCaseStringToNormalText(name, 'heading').trim();
  if (
    chatId in allChats &&
    allChats[chatId].chatInfo &&
    allChats[chatId].chatInfo.data &&
    allChats[chatId].chatInfo.data?.visitorInfoName
  ) {
    visitorName = allChats[chatId]?.chatInfo?.data?.visitorInfoName;
    return getTitleCase(visitorName);
  } else if (chat && chat?.data?.visitorInfoName) {
    visitorName = chat?.data?.visitorInfoName;
    return getTitleCase(visitorName);
  } else {
    return visitorName;
  }
};

export default MessagingChatSlice.reducer;

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { changeTourGuideFlag } from 'helpers/generic.methods';
import storage from 'helpers/storage';
import { useTranslation } from 'react-i18next';
import { camelize, camelCaseStringToNormalText } from 'helpers/generic.methods';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';

const TourModal = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { roles } = useSelector(selectRoles);

  const userDetails = JSON.parse(storage.getItem('Profile'));
  const userDisplayName = camelize(userDetails?.DisplayName);
  const userNameCap = userDisplayName
    ? camelCaseStringToNormalText(userDisplayName, 'sentence')
    : '';

  const userName = t('company.tour.tourWelcome').replace('<user>', userNameCap);
  const startTour = () => {
    storage.setItem('Tour', {
      tourModeEnabled: true,
      pages: {
        hasAnalticsTourViewed: false,
        hasCompanyTourViewed: false,
        hasChatTourViewed: false,
        hasCustomerTourViewed: false,
        hasReportTourViewed: false,
        hasOnBoardingTourViewed: false
      }
    });
    props.toggelTourHandler(true);
    props.onHide();
    if (roles && roles.analytics && roles.analytics.view) {
      history.push('/analytics');
    }
    changeTourGuideFlag();
  };

  const exitTour = () => {
    changeTourGuideFlag();
    storage.setItem('Tour', {
      tourModeEnabled: false,
      pages: {
        hasAnalticsTourViewed: false,
        hasCompanyTourViewed: false,
        hasChatTourViewed: false,
        hasCustomerTourViewed: false,
        hasReportTourViewed: false,
        hasOnBoardingTourViewed: false
      }
    });
    props.toggelTourHandler(false);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      {/* <button className="modal-cancel-btn" onClick={props.onHide}>
        <UilTimes />
      </button> */}
      <Modal.Body>
        <h4 style={{ marginTop: '15px' }}>{userName}</h4>
        <p>{t('company.tour.tourWelcomeText')}</p>
        <img src="/undraw_Hello_re_3evm.png" style={{ width: '100%' }} alt="" />
        <div className="tour-btn-wrapper">
          <Button
            variant="primary"
            className="dismiss-tour-btn"
            onClick={exitTour}
          >
            {t('company.tour.btn1')}
          </Button>
          <Button variant="primary" onClick={startTour}>
            {t('company.tour.btn2')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TourModal;

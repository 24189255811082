import React, { useEffect, useState } from 'react';
import {
  UilCornerUpRightAlt,
  UilMapMarker,
  UilSignout,
  UilUser
} from '@iconscout/react-unicons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from 'react-bootstrap';
import Flipper, { Back, Front } from 'components/Flipper';
import { toast } from 'react-toastify';
import {
  endChatAsync,
  getSelectedChatLastMessage,
  getSelectedVisitorStatus
} from 'features/messagingChats/messagingChats';
import { useDispatch } from 'react-redux';
import { errors, success } from 'helpers/variables';
import { isMobile } from 'react-device-detect';
import { UnSubscribeChat } from 'app/communication/services/chat/pub_sub_chat';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  getSelectedChatFirstMessage,
  getSelectedChatLastVisitorMessage
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import {
  calculatePickupTime,
  calculateTotalDuration,
  calculateIdleTime,
  getIsChatEnded
} from './Helper';
import { theme } from '../../../helpers/variables';
import { camelCaseStringToNormalText } from 'helpers/generic.methods';

const UserInfo = ({ selectedChat, endChat }) => {
  const dispatch = useDispatch();
  const [flipped, setFlipped] = React.useState(false);
  const [chatEnding, setChatEnding] = React.useState(false);
  const [idleTime, setIdleTime] = React.useState(0);
  const [totalTime, setTotalTime] = React.useState('00:00');
  const firstMsg = useSelector(getSelectedChatFirstMessage);
  const lastMsg = useSelector(getSelectedChatLastMessage);
  const lastVisitorMsg = useSelector(getSelectedChatLastVisitorMessage);

  let selectedChatCreatedOn = selectedChat.data.createdOn;
  let firstMessageCreatedOn = firstMsg(selectedChat.getId());
  let _lastVisitorMsg = lastVisitorMsg(selectedChat.getId());
  let getVisitorStatus = useSelector(getSelectedVisitorStatus);
  let visitorStatus = getVisitorStatus(selectedChat?.getId());

  const endChatHandler = async () => {
    setChatEnding(true);
    let chatEnded = await dispatch(endChatAsync(selectedChat.getId()));
    if (chatEnded) {
      toast.success(success.chatEnd);
      UnSubscribeChat(selectedChat.getId());
    } else toast.error(errors.failure);
    setChatEnding(false);
  };

  const pickupWaitAmount = () => {
    let inSeconds = calculatePickupTime(
      firstMessageCreatedOn?.createdOn,
      selectedChatCreatedOn
    );
    return inSeconds;
  };

  const timeSinceLastVisitorMessage = () => {
    let idle = calculateIdleTime(_lastVisitorMsg?.createdOn);
    setIdleTime(idle);
  };

  const totalDuration = () => {
    let timer = calculateTotalDuration(firstMessageCreatedOn?.createdOn);
    setTotalTime(timer);
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      timeSinceLastVisitorMessage();
      totalDuration();
    }, 1000);

    if (getIsChatEnded(lastMsg(selectedChat.getId()))) {
      clearInterval(myInterval);
    }

    return () => {
      clearInterval(myInterval);
    };
  }, [_lastVisitorMsg]);

  const formatTime = (time, format = 'hh:mmA') => {
    return moment(time).format(format);
  };

  const visitorTime = () => {
    let utcTime = moment(selectedChatCreatedOn)
      .utcOffset(selectedChat.data.visitorTimezoneOffset)
      .format('hh:mmA');
    return utcTime;
  };

  const companyTime = () => {
    if (selectedChat.data.companyTimezoneOffset) {
      let utcTime = moment(selectedChatCreatedOn)
        .utcOffset(selectedChat.data.companyTimezoneOffset)
        .format('MMM DD, YYYY, hh:mmA');
      return utcTime;
    }
  };

  const setPriority = priority => {
    switch (priority) {
      case Enums.ChatPriority.Normal:
        return 'Normal priority';
      case Enums.ChatPriority.Medium:
        return 'Medium priority';
      case Enums.ChatPriority.High:
        return 'High priority';
      default:
        return null;
    }
  };

  return (
    <>
      <Flipper
        orientation="horizontal"
        flipped={flipped}
        flipperStyles={{
          width: '100%',
          minHeight: '250px',
          height: flipped && isMobile ? '350px' : '250px'
        }}
      >
        <Front>
          <div
            className="user-avatar d-flex flex-column justify-content-center align-items-end"
            style={{ margin: '20px auto' }}
          >
            <UilUser />
            <div
              className={`oval ${visitorStatus ? 'active' : 'inactive'}`}
            ></div>
          </div>
          <div className="user-name-address">
            <h4>
              {selectedChat?.getCompanyName()} <Dot size="5" />{' '}
              {selectedChat?.getId()}
            </h4>
            {selectedChat?.getParticipantNames() ? (
              <div className="user-address">
                <span>Agents: {selectedChat?.getParticipantNames()}</span>
              </div>
            ) : null}
          </div>
          <div className="user-name-address">
            <div className="user-address">
              <span>
                <UilMapMarker />
                {selectedChat?.getLocation()}
                <Dot size="3" />
                {setPriority(selectedChat.getPriority())}
                <Dot
                  size="3"
                  visibility={setPriority(selectedChat.getPriority())}
                />
                {camelCaseStringToNormalText(
                  selectedChat?.getLanguage(),
                  'heading'
                )}
                <Dot size="3" />
                <a
                  href={selectedChat.getReferrer()}
                  target="_blank"
                  style={{ color: '#3B3BBA' }}
                >
                  Referrer
                </a>
              </span>
              <div>
                <span className="more-details">
                  <a onClick={() => setFlipped(!flipped)}>See more details</a>
                </span>
              </div>
            </div>
          </div>
        </Front>
        <Back>
          <div className="flip-back-container">
            <div className="initiation">
              <h4>Chat Initiation Times</h4>
              <div className="back-detail">
                <span>
                  <b>Local</b>
                </span>
                <span>{formatTime(selectedChatCreatedOn)}</span>
              </div>
              <div className="back-detail">
                <span>
                  <b>Visitor</b>
                </span>
                <span>{visitorTime()}</span>
              </div>
              <div className="back-detail">
                <span>
                  <b>Company</b>
                </span>
                <span>{companyTime()}</span>
              </div>
              <div className="back-detail">
                <span>
                  <b>Timezone</b>
                </span>
                <span>{selectedChat.data.officeHoursTimezone}</span>
              </div>
            </div>
            <div className="timers">
              <h4>Timers</h4>
              <div className="back-detail">
                <span>
                  <b>Pickup</b>
                </span>
                <span>{pickupWaitAmount()}s</span>
              </div>
              <div className="back-detail">
                <span>
                  <b>Idle</b>
                </span>
                <span>{idleTime}s</span>
              </div>
              <div className="back-detail">
                <span>
                  <b>Total</b>
                </span>
                <span>{totalTime}</span>
              </div>
            </div>
          </div>
          <span className="flip-back-btn">
            <a onClick={() => setFlipped(!flipped)}>Back</a>
          </span>
        </Back>
      </Flipper>

      <div className="action-btn">
        {/* <Button variant="outline-primary" size="md" className="btn-chat mr-2">
          <UilCornerUpRightAlt />
          Transfer Chat
        </Button> */}
        {/* <Button
          variant="outline-danger"
          size="md"
          className="btn-end-chat"
          onClick={endChatHandler}
          disabled={chatEnding}
        >
          {chatEnding ? (
            <i className="uil uil-spinner spinner mr-1"></i>
          ) : (
            <UilSignout />
          )}
          End Chat
        </Button> */}
      </div>
    </>
  );
};

export default UserInfo;

const useDotStyles = makeStyles({
  dotSeparator: {
    borderRadius: '50%',
    backgroundColor: theme.blue,
    display: 'inline-block',
    margin: '3px'
  },
  dotSize: {
    width: props => `${props.size}px`,
    height: props => `${props.size}px`
  }
});
const Dot = ({ size, visibility = true }) => {
  const classes = useDotStyles({ size });
  return (
    visibility && (
      <span className={`${classes.dotSeparator} ${classes.dotSize}`}></span>
    )
  );
};

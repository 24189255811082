import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { ProvisionAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const provisionSlice = createSlice({
  name: 'provision',
  initialState: {
    provision: []
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setProvisionToState: (state, action) => {
      state.provision = [...state.provision, action.payload.data];
    },

    removeProvisionFromState: (state, action) => {
      let provision = [...state.provision];
      let index = provision.findIndex(com => com.id === action.payload.id);
      provision.splice(index, 1);
      state.provision = provision;
    },

    removeAllProvisionFromState: (state, action) => {
      state.provision = [];
    }
  }
});

export const {
  setProvisionToState,
  removeProvisionFromState,
  removeAllProvisionFromState
} = provisionSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const getProvisionAsync = payload => (dispatch, getState) => {
  let provisionAPI = ProvisionAPI.get + '/' + payload.params.id;
  return ApiServices.getWithParams(provisionAPI, payload)
    .then(async ({ data }) => {
      if (data?.success && data?.data) {
        await dispatch(setProvisionToState(data));
        return Promise.resolve(data);
      }
      return Promise.reject('rejected');
    })
    .catch(error => {
      return Promise.reject(error);
    });
  // }
};

export const selectProvision = (state, id) => {
  let provision = state.provisions.provision;
  if (id && provision.length) {
    let temp = provision.filter(c => c.id === id);

    if (temp.length) {
      return { provision: temp[0] };
    } else {
      return { provision: {} };
    }
  } else {
    return { provision: {} };
  }
};
/* called a selector and allows to select values from the state */

export const getAllProvisionList = payload => {
  let provisionAPI = ProvisionAPI.getAll;
  return ApiServices.getWithParams(provisionAPI, { params: payload });
};

export default provisionSlice.reducer;

import React from 'react';
import Button from 'react-bootstrap/Button';
import { UilCheckCircle, UilTimesCircle } from '@iconscout/react-unicons';

const StatusButton = ({ value, variant }) => {
  const getButtonValue = (value, variant) => {
    if (variant === 'enable-status') {
      return value ? 'Enable' : 'Disable';
    } else {
      return value ? 'Active' : 'Inactive';
    }
  };

  return (
    <Button
      className="btn-sm btn-flex btn-status"
      variant={value ? 'success-4' : 'danger-4'}
    >
      {value ? (
        <UilCheckCircle className="mr-1" />
      ) : (
        <UilTimesCircle className="mr-1" />
      )}
      {getButtonValue(value, variant)}
    </Button>
  );
};

export default StatusButton;

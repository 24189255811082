import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SidebarRight from 'components/Sidebar/sidebar-right';
import {
  getAllProvisionList,
  removeAllProvisionFromState,
  removeProvisionFromState
} from 'features/provisioning/ProvisioningSlice';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import storage from 'helpers/storage';
import {
  TabComponent,
  TabsContainer,
  TabNav
} from '../../../components/TabsComponent';
import ProvisionContainer from './details/index';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { provisionTableConfig } from 'utils/tableConfig';
import {
  provisionFilterConfig,
  provisionFilterTypeConfig
} from 'utils/filterConfig';
import HeaderToExistingPortal from 'components/headerToExistingPortal';
import { selectRoles } from 'features/roles/rolesSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GridWrapper } from '../../../components/GridWrapper';
const Provision = () => {
  const { t } = useTranslation();
  const [openColapse, setOpenColapse] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { roles } = useSelector(selectRoles);
  const permissions = (roles && roles.switch) || {};
  // Colapsable panel Open/Close Handler
  const handleOpen = status => {
    setOpenColapse(status);
  };

  // Sppend Close button to TabTitle
  const tabTitle = (index, company) => {
    return (
      <TabNav
        index={index}
        id={company.id}
        title={company.companyName}
        removeTab={removeCompany}
      />
    );
  };

  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    setSelectedTab(JSON.parse(storage.getItem('provisions-selectedTab')));
    let dataProvisioning =
      JSON.parse(storage.getItem('provisions-selectedProvisions')) || [];
    setSelectedCompanies(dataProvisioning);
    updateSelectedData(dataProvisioning);
  }, []);

  // select Company
  const handleSelect = payload => {
    const { data } = payload;
    if (data.length || Object.keys(data).length) {
      let temp = data;
      if (data.length) temp = data[0];
      let selected = selectedCompanies.filter(sc => sc.id === Number(temp.id));
      if (selected.length === 0) {
        let chats = [temp, ...selectedCompanies];
        updateCompaniesAndSaveToLS(chats);
      } else if (data instanceof Object && !Array.isArray(data)) {
        if (!Object.keys(selected).includes(data.id)) {
          let index = selectedCompanies.findIndex(com => com.id === data.id);
          removeCompany(index, data.id);
        }
      }

      updateTabAndSaveToLS(temp.id);
      if (Array.isArray(data)) handleOpen(true);
    } else {
      clearAll();
    }
  };

  // Select Tab Handler
  const handleTabSelect = tabKey => {
    if (selectedTab !== tabKey) {
      updateTabAndSaveToLS(Number(tabKey));
    }
  };

  // Remove  Tab From Selected Companies
  const removeCompany = (index, id) => {
    let chats = [...selectedCompanies];
    chats.splice(index, 1);

    if (chats.length) {
      updateTabAndSaveToLS(chats[chats.length - 1].id);
    } else {
      updateTabAndSaveToLS('');
    }
    updateCompaniesAndSaveToLS(chats);
    dispatch(removeProvisionFromState({ id: id }));
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = companies => {
    setSelectedCompanies(companies);
    storage.setItem('provisions-selectedProvisions', companies);
    updateSelectedData(companies);
  };

  const updateSelectedData = companies => {
    let checked = {};
    companies.forEach(com => {
      checked[com.id] = com;
    });
    setSelectedData(checked);
  };

  // update Tabs and save To Local Storage
  const updateTabAndSaveToLS = tabKey => {
    setSelectedTab(tabKey);
    storage.setItem('provisions-selectedTab', tabKey);
  };

  // update Selected Tab Menu For get Back to same menu after Redirecting
  const selectTabMenu = menuKey => {
    let index = selectedCompanies.findIndex(sc => sc.id === selectedTab);
    if (index > -1) {
      let companies = selectedCompanies;
      companies[index] = { ...companies[index], selectedMenu: menuKey };
      updateCompaniesAndSaveToLS(companies);
    }
  };

  const filterTypes = Object.assign(
    {},
    ReactDataGrid.defaultProps.filterTypes,
    provisionFilterTypeConfig
  );

  const getFilterValue = data => {
    let updatedValue = data.value;

    if (
      data.name === 'createdOn' &&
      (data.operator === 'eq' || data.operator === 'after')
    ) {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = '2000-01-01T08:00:00.000Z';
    } else if (data.operator === 'contains') {
      updatedValue = `%${updatedValue}%`;
    } else if (data.operator === 'startsWith') {
      updatedValue = `${updatedValue}%`;
    } else if (data.operator === 'endsWith') {
      updatedValue = `%${updatedValue}`;
    }
    return updatedValue;
  };

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      setDataLimit(skip);
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(v),
          propertyValue2:
            v.name === 'createdOn' &&
            (v.operator === 'before' || v.operator === 'after')
              ? getDateFilterValue(v)
              : '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        start: skip,
        limit: limit,
        filter: JSON.stringify(updatedValue),
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'createdOn'
      };
      return getAllProvisionList(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;
        setTotalRecords(totalCount);
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  const clearAll = () => {
    updateCompaniesAndSaveToLS([]);
    updateTabAndSaveToLS('');
    dispatch(removeAllProvisionFromState());
  };

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');
  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="m-3">
            <h2>
              Onboarding{' '}
              <Badge variant="primary p-1 badge-align">
                {loading ? '...' : `${dataLimit} / ${totalRecords}`}
              </Badge>
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className={loading && 'blux-text'}>
            {permissions.allowPortal ? (
              <HeaderToExistingPortal msg={t('company.general.oldPortalTag')} />
            ) : null}
            <div className="mobile_view">
              <GridWrapper
                columns={provisionTableConfig}
                gridClass={
                  permissions.allowPortal ? 'provisioning-table-container' : ''
                }
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                defaultFilterValue={provisionFilterConfig}
                filterTypes={filterTypes}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                handleSelect={handleSelect}
              />
            </div>
          </Col>
        </Row>
      </Container>

      {selectedCompanies.length ? (
        <SidebarRight handleOpen={handleOpen} open={openColapse}>
          <TabsContainer
            activeKey={
              selectedCompanies.filter(sc => sc.id === selectedTab).length
                ? selectedTab
                : selectedCompanies[0].id
            }
            clearAll={clearAll}
            openColapse={openColapse}
            selectedData={selectedCompanies}
            onTabSelect={e => handleTabSelect(e)}
          >
            {selectedCompanies.map((company, index) => (
              <TabComponent
                tabClassName={'tab-ui'}
                key={index}
                eventKey={company.id}
                title={tabTitle(index, company)}
                id={`tab-${company.id}`}
                // TODO close button
                // closeButton={() => {
                //   console.log(index, 'close tab');
                // }}
              >
                <ProvisionContainer
                  selectMenu={selectTabMenu}
                  selectedCompany={company}
                />
              </TabComponent>
            ))}
          </TabsContainer>
        </SidebarRight>
      ) : null}
    </>
  );
};

export default Provision;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeZoneData } from 'app/TimeZoneData';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';

const Parameters = ({ report, clickHandlerMenu }) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="fz-12 text-warning-2 fw-600 p-3 mb-0 warning-box">
        {t('company.reportDetails.note')}
      </p>
      <div className="page-section mt-0">
        <ToggleMenuButton
          heading={t('company.reportDetails.heading1')}
          clickHandlerMenu={clickHandlerMenu}
        />
        <section>
          <div className="section-body">
            <ul className="editable-fields-lists">
              <SimpleLabelTextBox
                heading={t('company.reportDetails.parameters.name.label')}
                field="companyName"
                dataProperty={report['name']}
                titleText={t('company.reportDetails.parameters.name.info')}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.reportDetails.parameters.notificationEmail.label'
                )}
                field="companyName"
                dataProperty={report['email']}
                titleText={t(
                  'company.reportDetails.parameters.notificationEmail.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.reportDetails.parameters.notificationEndPoint.label'
                )}
                field="companyName"
                dataProperty={report['endPoint'] || '-'}
                titleText={t(
                  'company.reportDetails.parameters.notificationEndPoint.info'
                )}
              />
              <ToggleToolTip
                heading={t(
                  'company.reportDetails.parameters.emailReport.label'
                )}
                toolTipFor="emailReport"
                flag={report.addReportAttachment}
                titleText={t(
                  'company.reportDetails.parameters.emailReport.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.reportDetails.parameters.companyKeys.label'
                )}
                field="companyName"
                dataProperty={report['companyKey'] || '-'}
                titleText={t(
                  'company.reportDetails.parameters.companyKeys.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t('company.reportDetails.parameters.timeZone.label')}
                field="companyName"
                dataProperty={
                  timeZoneData.find(item => item.id === report['timezone'])
                    .displayName
                }
                titleText={t('company.reportDetails.parameters.timeZone.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.reportDetails.parameters.period.label')}
                field="companyName"
                dataProperty={report['period'] || '-'}
                titleText={t('company.reportDetails.parameters.period.info')}
              />
              <ToggleToolTip
                heading={t(
                  'company.reportDetails.parameters.groupByCompany.label'
                )}
                toolTipFor="groupByCompany"
                flag={true}
                titleText={t(
                  'company.reportDetails.parameters.groupByCompany.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t('company.reportDetails.parameters.host.label')}
                field="companyName"
                dataProperty={report['host'] || '-'}
                titleText={t('company.reportDetails.parameters.host.info')}
              />
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default Parameters;

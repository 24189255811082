import React, { useState } from 'react';
import portalRoutes from 'routes/portalRoutes';
import SubMenu from './SubMenu';
import SideBarBottom from './SideBarBottom';
import Navbar from 'react-bootstrap/Navbar';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import { theme, breakpoints } from 'helpers/variables';
import { ActiveLink } from 'helpers/hoc/Link';
import { Link } from 'react-router-dom';
import storage from 'helpers/storage';
import { Scrollbars } from 'react-custom-scrollbars';
import Logo from './Logo';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import ScrollBar from 'components/Scrollbars';
import RenderSidebarTooltip from 'helpers/ui-controls/renderSidebarTooltip';
import {
  setNavbarStatus,
  getNavbarStatus
} from 'features/sidebar-nav/sidebarNavSlice';
import { useEffect } from 'react';

const Sidebar = () => {
  const user = JSON.parse(storage.getItem('Profile'));
  const { roles } = useSelector(selectRoles);
  const dispatch = useDispatch();
  const switchToPermission = (roles && roles.switch) || {};
  const deviceWidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const { navbar } = useSelector(getNavbarStatus);
  const [showSidebar, setShowSidebar] = useState(navbar);
  const [selectedSubmMenu, setSelectedSubmMenu] = useState({});
  const [subnav, setSubnav] = useState(false);

  const showSidebarHandler = () => {
    // dispatch(setNavbarStatus(!showSidebar));
    setShowSidebar(!showSidebar);
  };
  useEffect(() => {
    dispatch(setNavbarStatus(showSidebar));
  }, [showSidebar]);

  const showSubnav = selectedSubMenu => {
    if (deviceWidth <= breakpoints.tabletAndSmallerDevices) {
      showSidebarHandler();
    }
    setSubnav(!!selectedSubMenu?.subNav?.length);
    setSelectedSubmMenu(selectedSubMenu);
  };

  return (
    <Navbar
      id="sidebar"
      className={
        'sidebar-nav sidebar-nav-' + (showSidebar ? 'open active' : 'close')
      }
    >
      <a href="#" className="navbar-ham" onClick={showSidebarHandler}>
        {showSidebar ? (
          <UilAngleLeft size="28" color={theme.primary} />
        ) : (
          <UilAngleRight size="28" color={theme.primary} />
        )}
      </a>
      <div className="sidebar-wrapper">
        <Link className="navbar-logo d-block" to="/">
          <Logo />
        </Link>
        <ScrollBar
          horizontalScroll={false}
          className="scrollbar-wrap"
          style={{
            width: '100%',
            height: 'calc(100% - 215px)'
          }}
          renderThumbVertical={() => <div className="scrollbar-rail" />}
        >
          <div className="sidebar-primary bg-primary">
            <ul>
              {roles &&
                Object.keys(roles).length &&
                portalRoutes.map((item, index) => {
                  let permission = roles[item.name] || roles.messaging;
                  return (
                    <li key={'item-' + index}>
                      {item.path && permission && permission.view ? (
                        <ActiveLink
                          id={'introJS-item-' + index}
                          activeClassName="active-link"
                          href={item.path}
                          onClick={() => showSubnav(item)}
                        >
                          <div className="sidebar-link">
                            <RenderSidebarTooltip
                              showSidebar={showSidebar}
                              title={item.title}
                              icon={item.icon}
                            />
                            <span className="link-text">{item.title}</span>
                          </div>
                        </ActiveLink>
                      ) : permission && permission.allowMessaging ? (
                        <ActiveLink
                          id={'introJS-item-' + index}
                          activeClassName="active-link"
                          href={item.path}
                          onClick={() => showSubnav(item)}
                        >
                          <div className="sidebar-link">
                            <RenderSidebarTooltip
                              showSidebar={showSidebar}
                              title={item.title}
                              icon={item.icon}
                            />
                            <span className="link-text">{item.title}</span>
                          </div>
                        </ActiveLink>
                      ) : null}
                    </li>
                  );
                })}
            </ul>
          </div>
          {subnav && (
            <div className="sidebar-secondary bg-lightgray-2">
              <SubMenu item={selectedSubmMenu} />
            </div>
          )}
        </ScrollBar>
      </div>
      <SideBarBottom
        user={user}
        showSideBar={showSidebar}
        switchTo={switchToPermission}
        setShowSidebar={setShowSidebar}
      />
    </Navbar>
  );
};

export default Sidebar;

import React from 'react';
import ToggleText from 'helpers/ui-controls/toggleText';
import StaticFeild from 'helpers/ui-controls/staticFeild';
import { useTranslation } from 'react-i18next';

const Weekly = ({ report, scheduleName }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="page-section mt-0">
        <h4 className="sub-head">
          {t('company.reportDetails.schedule.schedule.label')}
        </h4>{' '}
        <section>
          <div className="section-body">
            <ul className="editable-fields-list">
              <StaticFeild
                heading={t('company.reportDetails.schedule.schedule.label')}
                field="companyName"
                dataProperty={scheduleName || '-'}
              />
              <li>{t('company.reportDetails.schedule.sunday.label')}</li>
              <li>
                <ToggleText flag={report.scheduleDaysOfWeek.includes(0)} />
              </li>
              <li>{t('company.reportDetails.schedule.monday.label')}</li>
              <li>
                <ToggleText flag={report.scheduleDaysOfWeek.includes(1)} />
              </li>
              <li>{t('company.reportDetails.schedule.tuesday.label')}</li>
              <li>
                <ToggleText flag={report.scheduleDaysOfWeek.includes(2)} />
              </li>
              <li>{t('company.reportDetails.schedule.wednesday.label')}</li>
              <li>
                <ToggleText flag={report.scheduleDaysOfWeek.includes(3)} />
              </li>
              <li>{t('company.reportDetails.schedule.thursday.label')}</li>
              <li>
                <ToggleText flag={report.scheduleDaysOfWeek.includes(4)} />
              </li>
              <li>{t('company.reportDetails.schedule.friday.label')}</li>
              <li>
                <ToggleText flag={report.scheduleDaysOfWeek.includes(5)} />
              </li>
              <li>{t('company.reportDetails.schedule.saturday.label')}</li>
              <li>
                <ToggleText flag={report.scheduleDaysOfWeek.includes(6)} />
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default Weekly;

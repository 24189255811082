import React, { useState } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';

const gridStyle = {
  minHeight: 'calc(100vh - 100px)',
  marginTop: 10,
  boxShadow: '0px 3px 10px 0 rgba(0, 0, 0, 0.08)',
  overflow: 'hidden'
};

export const GridWrapper = ({
  columns,
  setLoading,
  totalRecords,
  dataSource,
  defaultFilterValue,
  filterTypes,
  loadingMessage,
  renderColumnContextMenu,
  selectedData,
  handleSelect,
  gridClass
}) => {
  const [limit, setLimit] = useState(50);
  return (
    <ReactDataGrid
      idProperty="id"
      style={gridStyle}
      className={gridClass ? gridClass : ''}
      columns={columns}
      limit={limit}
      onLimitChange={setLimit}
      pagination={totalRecords > limit ? true : false}
      rowHeight={50}
      dataSource={dataSource}
      onLoadingChange={setLoading}
      defaultFilterValue={defaultFilterValue}
      filterTypes={filterTypes}
      showHoverRows={false}
      showCellBorders={false}
      showColumnMenuTool={true}
      loadingText={loadingMessage}
      renderColumnContextMenu={renderColumnContextMenu}
      selected={selectedData}
      onSelectionChange={handleSelect}
    />
  );
};

import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Button from 'react-bootstrap/Button';
import { UilEye } from '@iconscout/react-unicons';
import dropdownEnums from 'helpers/dropdown-enums';
import CopyButton from 'CellRenderer/CopyButton';
import Hover from 'CellRenderer/Hover';
import StatusButton from 'CellRenderer/StatusButton';
import TimeStamp from 'CellRenderer/TimeStamp';
import StatusIndicator from 'CellRenderer/StatusIndicator';
import MyReportStatus from 'CellRenderer/MyReportStatus';

export const companiesTableConfig = [
  {
    name: 'companyName',
    header: 'Name',
    defaultFlex: 3,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyKey',
    header: 'Key',
    defaultFlex: 1,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'active',
    header: 'Status',
    defaultFlex: 2,
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Active'
        },
        {
          id: 'false',
          label: 'Inactive'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'createdOn',
    header: 'Start Date',
    defaultFlex: 2,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'quickConnectEnabled',
    header: 'Call Connect',
    defaultFlex: 2,
    filterEditor: SelectFilter,
    headerProps: { className: 'hide-filter-button' },
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Enable'
        },
        {
          id: 'false',
          label: 'Disable'
        }
      ]
    },
    render: ({ value }) => (
      <StatusButton value={value} variant="enable-status" />
    )
  },
  {
    name: 'id',
    header: 'Action',
    defaultFlex: 2,
    sortable: false,
    render: value => (
      <Button
        id={`company-btn-${value.rowIndex}`}
        className={`btn-sm btn-flex introJs-companies-grid-view-${value.rowIndex}`}
        variant="lightgray-3"
      >
        <UilEye className="mr-1" />
        View
      </Button>
    )
  }
];

export const leadsTableConfig = [
  {
    name: 'id',
    header: 'ID',
    defaultFlex: 2,
    headerProps: { className: 'hide-filter-button' },
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyName',
    header: 'Company Name',
    defaultFlex: 2,
    headerProps: { className: 'hide-filter-button' },
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyKey',
    header: 'Company Key',
    defaultFlex: 1,
    headerProps: { className: 'hide-filter-button' },
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 2,
    headerProps: { className: 'hide-filter-button' },
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'phone',
    header: 'Phone',
    defaultFlex: 2,
    headerProps: { className: 'hide-filter-button' },
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'email',
    header: 'Email',
    defaultFlex: 2,
    headerProps: { className: 'hide-filter-button' },
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'processedByCustomer',
    header: 'Processed',
    defaultFlex: 2,
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <span>{value ? 'Yes' : 'No'}</span>
  },
  {
    name: 'createdOn',
    header: 'Start Date',
    defaultFlex: 2,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'leadType',
    header: 'Type',
    defaultFlex: 2,
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '1',
          label: 'Sales'
        },
        {
          id: '2',
          label: 'Service'
        },
        {
          id: '3',
          label: 'Other'
        }
      ]
    },
    render: ({ value }) =>
      dropdownEnums.enum_LeadType.map(item => item.value === value && item.name)
  },
  {
    name: 'initiatedBy',
    header: 'Initiated By',
    defaultFlex: 2,
    headerProps: { className: 'hide-filter-button' },
    filterDelay: 800,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        { id: '', label: 'All' },
        { id: '0', label: 'Unknown' },
        { id: '1', label: 'Bar' },
        { id: '2', label: 'Dom' },
        { id: '3', label: 'Window' },
        { id: '4', label: 'Static' },
        { id: '5', label: 'Standard' },
        { id: '6', label: 'Tab' },
        { id: '7', label: 'ExitPopup' },
        { id: '8', label: 'SmsStatic' },
        { id: '9', label: 'SmsContainer' },
        { id: '10', label: 'FacebookMessenger' },
        { id: '11', label: 'Video' },
        { id: '12', label: 'PreChat' },
        { id: '13', label: 'SmsWeb' },
        { id: '14', label: 'WebApi' },
        { id: '15', label: 'GoogleMyBusiness' }
      ]
    },
    cellProps: { className: 'copy-button' },
    render: ({ value }) =>
      dropdownEnums.enum_InitiatedBy.map(
        item => item.value === value && item.name
      )
  }
];

export const chatTableConfig = [
  {
    name: 'id',
    header: 'ID',
    className: 'hide-filter-button',
    defaultFlex: 2,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyName',
    header: 'Company Name',
    defaultFlex: 3,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyKey',
    header: 'Company Key',
    defaultFlex: 1,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'createdOn',
    header: 'Created',
    defaultFlex: 2,
    cellProps: { className: 'copy-button' },
    dateFormat: 'DD-MMMM-YYYY',
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'isLead',
    header: 'Lead',
    defaultFlex: 2,
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'action',
    header: 'Action',
    defaultFlex: 2,
    sortable: false,
    render: value => (
      <Button className="btn-sm btn-flex" variant="lightgray-3">
        <UilEye className="mr-1" />
        View
      </Button>
    )
  }
];

export const provisionTableConfig = [
  {
    name: 'id',
    header: 'ID',
    defaultFlex: 1,
    filterDelay: 800,
    headerProps: { className: 'hide-filter-button' },
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyName',
    header: 'Company Name',
    defaultFlex: 2,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'hostName',
    header: 'Host',
    defaultFlex: 2,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'status',
    header: 'Status',
    className: 'hide-filter-button',
    defaultFlex: 2,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '1',
          label: 'Adding Information'
        },
        {
          id: '2',
          label: 'Waiting For Setup'
        },
        {
          id: '3',
          label: 'Need More Information'
        },
        {
          id: '4',
          label: 'Pending Addition To Website'
        },
        {
          id: '5',
          label: 'Completed'
        }
      ]
    },
    render: ({ value }) => <StatusIndicator value={value} />
  },
  {
    name: 'createdOn',
    header: 'Created',
    defaultFlex: 2,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'modifiedOn',
    header: 'Modified',
    defaultFlex: 2,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'action',
    header: 'Action',
    defaultFlex: 2,
    sortable: false,
    render: value => (
      <Button className="btn-sm btn-flex" variant="lightgray-3">
        <UilEye className="mr-1" />
        View
      </Button>
    )
  }
];

export const myReportTableConfig = [
  {
    name: 'name',
    header: 'Reports',
    defaultFlex: 4,
    filterDelay: 800,
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'createdOn',
    header: 'Created',
    defaultFlex: 2,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'id',
    header: '',
    defaultFlex: 2,
    sortable: false,
    render: value => (
      <Button className="btn-sm btn-flex" variant="lightgray-3">
        <UilEye className="mr-1" />
        View
      </Button>
    )
  }
];

export const queuedReportTableConfig = [
  {
    name: 'id',
    header: 'ID',
    className: 'hide-filter-button',
    defaultFlex: 1,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'reportStatusId',
    header: 'Status',
    className: 'hide-filter-button',
    defaultFlex: 2,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '0',
          label: 'Queued'
        },
        {
          id: '1',
          label: 'Running'
        },
        {
          id: '2',
          label: 'Completed'
        },
        {
          id: '3',
          label: 'Completed Without Data'
        },
        {
          id: '4',
          label: 'Failed'
        }
      ]
    },
    render: ({ value }) => <MyReportStatus value={value} />
  },
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 3,
    filterDelay: 800,
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'parametersJson',
    header: 'Parameters',
    defaultFlex: 3,
    filterDelay: 800,
    className: 'hide-filter-button disabled-field',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => {
      return <Hover value={value} />;
    }
  },
  {
    name: 'createdOn',
    header: 'Created',
    defaultFlex: 2,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  }
];

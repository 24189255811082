import React, { useRef, useState } from 'react';
import { Overlay, Form } from 'react-bootstrap';
import UilElipsisH from '@iconscout/react-unicons/icons/uil-ellipsis-h';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';

const CardWrapperPopOver = ({ closeCard }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const handleShow = e => {
    e.preventDefault();
    setShow(!show);
  };

  return (
    <>
      <div
        // className="btn-addmore"
        // variant="primary"
        className="ml-2"
        title="Filters"
        ref={target}
        onClick={e => handleShow(e)}
      >
        <UilElipsisH />
      </div>
      <Overlay
        target={target.current}
        show={show}
        placement="bottom"
        rootClose={true}
        onHide={() => setShow(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              backgroundColor: 'white',
              padding: '0px 3px 1px 5px',
              fontWeight: 600,
              border: '1px solid lightgrey',
              borderRadius: 5,
              ...props.style
            }}
          >
            <Form.Label
              className={'cursor-pointer'}
              style={{ marginBottom: '0px' }}
              onClick={closeCard}
              title={'titleText'}
              aria-label={'close'}
            >
              Close
              <UilTimes size={20} />
            </Form.Label>

            {/* {options.map((option, index) => (
              <Form.Check
                key={index}
                custom
                type="checkbox"
                name="checkbox"
                id={option.key + index}
                label={option.key}
                onChange={e => handleOnChange(e, index)}
                checked={option.value}
                style={{
                  paddingTop: '10px',
                  color: '#637381',
                  fontSize: '13px',
                  fontWeight: '600'
                }}
              />
            ))} */}
          </div>
        )}
      </Overlay>
    </>
  );
};

export default CardWrapperPopOver;

import {
  getAllUnreadMsgsCounts,
  getUnSubscribedIncomingChatList
} from 'features/messagingChats/messagingChats';
import React from 'react';
import { OverlayTrigger, Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import renderToolTip from './renderToolTip';
import './renderSidebarTooltip.scss';
import AnimateScrollArrow from './animate-scroll-arrow';

const RenderSidebarTooltip = ({ showSidebar, title, icon }) => {
  const unSubscribedChat = useSelector(getUnSubscribedIncomingChatList);
  const allUnReadMsgCounts = useSelector(getAllUnreadMsgsCounts);
  let incomingChatsCount = unSubscribedChat.length;
  return (
    <>
      {!showSidebar ? (
        <OverlayTrigger placement={'auto'} overlay={renderToolTip(title)}>
          {icon}
        </OverlayTrigger>
      ) : (
        <>{icon}</>
      )}
      {title === 'Messaging' && (
        <>
          {allUnReadMsgCounts > 0 && (
            <OverlayTrigger
              placement={'auto'}
              overlay={renderToolTip(
                `Unread messages count: ${allUnReadMsgCounts}`
              )}
            >
              <Badge
                pill
                bg="secondary"
                className={`count-badge ${
                  allUnReadMsgCounts > 9 && 'count-greater-than-9'
                }`}
              >
                {allUnReadMsgCounts}
              </Badge>
            </OverlayTrigger>
          )}
          {/* {incomingChatsCount > 0 && (
          <Badge pill bg="secondary" className="text-badge">
            {incomingChatsCount} NEW CHAT
          </Badge>
          )} */}
          {incomingChatsCount > 0 && (
            <OverlayTrigger
              placement={'auto'}
              overlay={renderToolTip(
                `Incoming chat count: ${incomingChatsCount}`
              )}
            >
              <Badge
                bg="secondary"
                className={`incoming-chat-vertical-indicator ${
                  incomingChatsCount > 9 && 'incoming-count-greater-than-9'
                }`}
              >
                <AnimateScrollArrow />
                {incomingChatsCount}
              </Badge>
            </OverlayTrigger>
          )}
        </>
      )}
    </>
  );
};
export default RenderSidebarTooltip;

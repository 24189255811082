import { Enums } from 'helpers/dropdown-enums';

/**
 * @name @getSelectedText
 * @description get highlighted text
 * @requires string => optional and toTitleCase function
 * @returns highlighted text
 * */
export const getHighlightedText = (str = '') => {
  let highlightedText = '';
  let hasSelection = false;

  if (window.getSelection) {
    highlightedText = window.getSelection()?.toString();
  } else if (document.getSelection) {
    highlightedText = document.getSelection()?.toString();
  } else if (document.selection) {
    highlightedText = document.selection.createRange().text;
  }
  if (!highlightedText?.trim()) {
    highlightedText = str;
  } else {
    hasSelection = true;
    highlightedText = highlightedText.trim();
  }
  return toTitleCase(highlightedText);
};

/**
 * @name @toTitleCase
 * @description string to title case
 * @requires phrase string
 * @returns title case text
 * */
export const toTitleCase = phrase => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * @name @copyToClipboard
 * @description copy string to clipboard
 * @requires phrase string
 * @returns N/A
 * */

export const copyToClipboard = phrase => {
  if (typeof phrase === 'string') {
    navigator.clipboard.writeText(phrase).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  }
};

/**
 * @name @getCSSClassAccordingToMessage
 * @description CSS class as per the message type
 * @requires message object
 * @returns class name
 * */
export const getCSSClassAccordingToMessage = (msg, role = '') => {
  let _cssClass = '';
  let _type = getMessageSenderType(msg.userId, role);
  if (
    msg.userId >
      0 /*This includes normal agent message and simulated message*/ ||
    msg.userId === Enums.ChatMessageType.Bot ||
    msg.userId === Enums.ChatMessageType.System ||
    msg.userId === Enums.ChatMessageType.Prechat ||
    msg.userId === Enums.ChatMessageType.Predefined ||
    msg.userId === Enums.ChatMessageType.NonVisitorSystem ||
    msg.userId === Enums.ChatMessageType.PokeMessage
  ) {
    _cssClass = 'chat_message_left';
  } else if (msg.userId === Enums.ChatMessageType.Visitor) {
    _cssClass = 'chat_message_right';
  } else {
    _cssClass = 'chat_message_right';
  }
  return {
    cssClass: _cssClass,
    type: _type
  };
};

/**
 * @name @getMessageSenderType
 * @description Resolve sender type as per the ID
 * @requires userId
 * @returns sender type
 * */
export const getMessageSenderType = (userId, role = '') => {
  if (userId === Enums.ChatMessageType.Bot) {
    return 'BOT';
  } else if (
    userId === Enums.ChatMessageType.Simulated ||
    userId === Enums.ChatMessageType.System ||
    userId === Enums.ChatMessageType.NonVisitorSystem
  ) {
    return 'SYSTEM';
  } else if (userId === Enums.ChatMessageType.Prechat) {
    return 'PRECHAT';
  } else if (userId === Enums.ChatMessageType.Predefined) {
    return 'PRE-DEFINED';
  } else if (userId === Enums.ChatMessageType.PokeMessage) {
    return 'POKE';
  } else if (userId > 0) {
    return role;
  }
  return false;
};

/**
 * @name @isCallConnectEnable
 * @description enable/disable call connect button
 * @requires selectedChat
 * @returns true/false
 * */
export const isCallConnectEnable = selectedChat => {
  let enabled = true;
  if (
    !selectedChat.data.quickConnectEnabled ||
    !selectedChat.data.quickConnectAvailable ||
    !selectedChat.data.quickConnectPhoneNumber
  ) {
    enabled = false;
  }
  return enabled;
};

export const callConnectCallbacks = {
  onSuccess: args => {
    console.log('CallConnectStatus callback:: onSuccess::', args);
  },
  onReceive: args => {
    console.log('CallConnectStatus callback:: onReceive::', args);
    args.onStatusArrived(args.data);
  },
  onError: args => {
    console.log('CallConnectStatus callback:: onError::');
  }
};

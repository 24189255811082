import store from 'store';
import { RemoveChannelsFromRTCCleint } from 'app/communication/services/chat/pub_sub_chat';
import {
  chatOnReceiveCallback,
  chatOnSuccessCallback
} from 'app/communication/services/chat/callbacks/chat_subscription_callbacks';
import {
  saveChatMessage,
  saveChatTypingIndicator,
  saveChatToShelved,
  setChatInfoDataInChats,
  getUnsentLeadListAsync
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import { toast } from 'react-toastify';
import { success } from 'helpers/variables';

export const chatCallbacks = options => {
  return {
    onSuccess: function (args) {
      chatOnSuccessCallback({
        meta: args.getMeta(),
        onMessageArrived:
          options._onSuccessMessageArrived ||
          options._onMessageArrived ||
          _onMessageArrived
      });
    },
    onFailure: function (e) {
      console.log('sub-failure:' + e.getException());
    },
    onReceive: function (args) {
      chatOnReceiveCallback({
        chatId: options.chatId,
        data: args.getData(),
        channel: args.getChannel(),
        onMessageArrived: options._onMessageArrived || _onMessageArrived,
        onTypingArrived: options._onTypingArrived || _onTypingArrived,
        onMetaArrived: options._onMetaArrived || _onMetaArrived
      });
    }
  };
};

export const _onMessageArrived = data => {
  store.dispatch(saveChatMessage(data));
};

export const _onTypingArrived = data => {
  const {
    auth: { profile }
  } = store.getState();
  // TODO:: here we will have 'data.indicator' property if that is true, then show typing indicator
  // dispatch to redux so all places where typing indicator needs to be appeared, they should display it.
  if (data.participantId !== profile?.UserID) {
    store.dispatch(
      saveChatTypingIndicator({
        id: data.chatId,
        typing: data.indicator
      })
    );
  }
};

export const _onMetaArrived = data => {
  // TODO:: here we will have 'data.isLead' and 'data.status' property.
  // TODO: if isLead is true, then we will add data to Unsent Lead list
  //if submitting lead form we will fetch unsent leads and stop submit button loading
  // after we get isLead = true and on chat data sendingLead = true
  if (data.isLead) {
    const {
      MessagingChats: { chats }
    } = store.getState();
    let selectedChatData = chats[data.chatId]?.chatInfo?.data;
    if (selectedChatData?.sendingLead) {
      toast.success(success.sendSuccess('The lead'));
      store.dispatch(getUnsentLeadListAsync());
      store.dispatch(
        setChatInfoDataInChats({
          chatId: data.chatId,
          chatInfoData: {
            sendingLead: false,
            leadSubmitMessage: 'Chat lead has been sent'
          }
        })
      );
    } else {
      store.dispatch(getUnsentLeadListAsync());
    }
  }

  // if status is 7(shelved), then we will add data to shelved chat list
  // dispatch to redux so all places where shelved chat needs to be appeared, they should display it.
  if (data.status === Enums.ChatStatus.Shelved) {
    store.dispatch(saveChatToShelved(data.chatId));
    // UnSubscribeChat(data.chatId);
    RemoveChannelsFromRTCCleint(data.chatId);
  }
};

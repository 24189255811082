export const en_tour = {
  tourWelcome: 'Hello <user>, Welcome to the new chat dashboard.',
  tourWelcomeText:
    "Let's take a quick tour of the new chat dashboard to show you around and familiarize yourself with the functionality and changes you can make now.",
  tourAnalytics: 'Welcome to the new analytics dashboard. More is coming soon!',
  tourMessaging:
    'The new messaging area is coming soon. Please use the redirect button to handle chats in the current platform.',
  tourCompanies:
    'The company tab allows you to make changes within your account.',
  tourChats: 'Incoming chats handled for the past six months.',
  tourLeads: 'Incoming chat customers for the past six months.',
  tourReports:
    'The reporting tab is coming soon. You can view or redirect to the existing portal for the reporting functionality.',
  tourOnboarding:
    'The onboarding tab is coming soon. You can view or redirect to the provisioning area within the existing portal to edit or submit a new account.',
  tourHelp:
    '<p><b>SUPPORT BOT</b> - The support bot is here to answer questions and submit a ticket to our internal team who will get back to you.</p><p><b>LAUNCH TOUR</b> - Take a quick tour and familiarize yourself with the new layout.</p>',
  tourSwitch: 'You can switch between the new and existing system.',
  tourEnd: "That's the end of the tour.",
  tourEnd2:
    'We hope you enjoyed the tour and look forward to bringing more features to the new chat dashboard soon. If you have any questions please reach out to us. We are always here to help.',
  btn1: 'Maybe Later',
  btn2: 'Start Tour',
  btn3: 'Previous',
  btn4: 'Finish',
  btn5: 'Next'
};
export default en_tour;
export { default as fr_tour } from '../fr-FR-TourDictionary/fr-Tour';
export { default as sp_tour } from '../sp-SP-TourDictionary/sp-Tour';

export const LOGIN = '/login';
export const JWT_VERIFY = '/auth/verifyToken';
export const RESETPASSWORD = profile => {
  return `/${profile.companyKey}/${profile.userName}/resetpassword`;
};
export const CHANGEPASSWORD = profile => {
  return `/user/${profile.userName}/changepassword`;
};
export const CompanyAPI = {
  get: '/company',
  put: '/company',
  getAll: '/companies'
};

export const ChatAPI = {
  get: '/chats',
  put: '/company',
  getAll: '/chats',
  getGroups: '/groups/assigned',
  messagingDashboardStatus: status => `users/active/${status}`,
  endChat: chatId => `chatrooms/${chatId}/close`,
  getChatRoomData: chatId => `chatrooms/${chatId}`,
  getUnsentLeads: `chatrooms/?filter=myleads`,
  updateUnsentLead: ({ chatId, isLead }) =>
    `chatrooms/${chatId}/flaglead?isLead=${isLead}`
};

export const UserAPI = {
  get: '/user',
  put: '/company',
  getAll: '/users'
};

export const LeadAPI = {
  get: '/leads',
  put: '/leads',
  getAll: '/leads',
  post: '/leads'
};

export const CallConnectAPI = {
  post: data => `/calls/?to=${data.to}&from=${data.from}&chatId=${data.chatId}`
};

export const AnalyticAPI = {
  getActivationStats: '/activationstats',
  getLeadgeneratorStats: '/leadgeneratorstats',
  getTopBottomBillersStats: '/leadgeneratorstats',
  getQuickStats: '/quick-stats',
  getTopRevenueStats: '/leadgeneratorstats',
  getTopInactiveBillerStats: '/leadgeneratorstats',
  getBudgetPacingStats: '/leadgeneratorstats',
  getAgentImagesCTRStats: '/leadgeneratorstats',
  getAccountsStats: '/leadgeneratorstats',
  getChartsAndGraphs: '/chartsNgraphs'
  // put: '/leads',
  // getAll: '/leads'
};

export const general = {
  getMobileSuggestions: '/general/mobile/suggestions',
  getBusinessCategories: 'general/businesscategories',
  getBusinessSubCategories: 'general/companies/{id}/businesssubcategories',
  getServerInfo: 'general/AppInfo'
};

export const ReportAPI = {
  get: '/report',
  put: '/report',
  getAll: '/report'
};

export const ProvisionAPI = {
  get: '/provision',
  getAll: '/provision'
};

export const MyReportAPI = {
  getAll: '/report'
};

export const QueuedReportAPI = {
  getAll: '/report/queued'
};

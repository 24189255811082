import React, { useState } from 'react';
import FilterWrapper from './filter-wrapper';
import Form from 'react-bootstrap/Form';
import { initializeVissibleCards, withOutFiltersCards } from './constants';
import { useTranslation } from 'react-i18next';
import { camelize, toolTipPlacement } from 'helpers/generic.methods';
import { Enums } from 'helpers/dropdown-enums';
import { OverlayTrigger } from 'react-bootstrap';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import { UilInfoCircle } from '@iconscout/react-unicons';
const CardsHideShow = ({
  title = '',
  onChangefilters,
  data,
  setFunction,
  onFilterClear,
  allowedCheckBoxes
  // isHours = false
}) => {
  const checkSelectAll = () => {
    return Object.values(data).every(dt => dt);
  };
  const { t } = useTranslation();
  const makeTitleFromKey = key =>
    key ? key.match(/[A-Z][a-z]+/g)?.join(' ') : '';
  const handleOnChange = (e, key) => {
    let temp = { ...data };
    temp[key] = e.target.checked;
    setFunction(temp);
  };

  const handleOnChangeSelectAllOption = e => {
    setFunction({ ...initializeVissibleCards(e.target.checked, data) });
  };
  return (
    <FilterWrapper title={title} onFilterClear={onFilterClear}>
      <div className={'features-hours'}>
        <Form.Check
          custom
          type="checkbox"
          name="checkbox"
          id="select_all"
          label={t('company.analytics.selectAll')}
          onChange={e => handleOnChangeSelectAllOption(e)}
          checked={checkSelectAll()}
          // checked={selectAllOptions}
          style={{
            paddingTop: '10px',
            color: '#454F5B',
            fontSize: '13px',
            fontWeight: '600',
            maxWidth: '35%',
            borderBottom: '1px solid #454f5b',
            paddingBottom: '5px'
          }}
        />

        {Object.keys(data).map((key, index) =>
          !withOutFiltersCards.includes(Enums.graphType[key]) ? (
            <Form.Check
              key={index}
              custom
              type="checkbox"
              name="checkbox"
              id={key + index}
              // label={camelCaseStringToNormalText(key, 'heading')}
              label={t(`company.analytics.${camelize(makeTitleFromKey(key))}`)}
              onChange={e => handleOnChange(e, key)}
              checked={data[key]}
              style={{
                paddingTop: '10px',
                color: '#637381',
                fontSize: '13px',
                fontWeight: '600'
              }}
            />
          ) : null
        )}

        <div className="non-filter-graph-filter-panel-heading mt-3">
          Non-Filtered Graphs
          <span className="ml-1">
            <OverlayTrigger
              placement={'top'}
              overlay={renderToolTip(
                'The below charts/graphs are not filtered by any available filters like feature, user, devices, etc.'
              )}
            >
              <UilInfoCircle size={18} className="" />
            </OverlayTrigger>
          </span>
        </div>
        {Object.keys(data).map((key, index) =>
          withOutFiltersCards.includes(Enums.graphType[key]) ? (
            <Form.Check
              key={index}
              custom
              type="checkbox"
              name="checkbox"
              id={key + index}
              // label={camelCaseStringToNormalText(key, 'heading')}
              label={t(`company.analytics.${camelize(makeTitleFromKey(key))}`)}
              onChange={e => handleOnChange(e, key)}
              checked={data[key]}
              style={{
                paddingTop: '10px',
                color: '#637381',
                fontSize: '13px',
                fontWeight: '600'
              }}
            />
          ) : null
        )}
      </div>
    </FilterWrapper>
  );
};

export default CardsHideShow;

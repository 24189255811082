/* eslint-disable no-use-before-define */
import React from 'react';
import * as _ from 'lodash.debounce';
// import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white'
  },
  // inputRoot: {
  //   padding: '6px 5px 4px 5px !important'
  // },
  tag: {
    color: '#454F5B !important'
    // padding: '2px 0px'
  }
}));

const AutoCompeleteComp = ({
  options,
  handleChange,
  handleTextChange,
  loading = false,
  selected = []
}) => {
  // const [inputValue, setInputValue] = useState('');

  const handleChangeInput = (e, value) => {
    if (value) handleTextChange(value);
  };
  const classes = useStyles();

  return (
    <div>
      <Autocomplete
        classes={classes}
        multiple
        limitTags={5}
        id="tags-outlined"
        options={options}
        loading={loading}
        getOptionLabel={option => option.key}
        getLimitTagsText={more => (
          <span className="cursor-pointer">{`+${more}`}</span>
        )}
        onChange={(e, data) => handleChange(data)}
        onInputChange={_(handleChangeInput, 1500)}
        value={selected}
        // inputValue={inputValue || undefined}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label=" Select Company Key"
            placeholder="Select one or more..."
            size="small"
          />
        )}
      />
    </div>
  );
};

export default AutoCompeleteComp;

import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Hover = ({ value }) => {
  let parameters = {};
  if (value) {
    parameters = JSON.parse(value);
  }

  const renderTooltip = props => (
    <Tooltip {...props}>
      <div className={'text-left'}>
        <span>Webhook:</span> {parameters?.webhook || '-'}
      </div>
      <div className={'text-left'}>
        <span>Company keys:</span>{' '}
        {parameters?.companyKeys?.toString()?.replace(/,/g, ', ') || '-'}
      </div>
      <div className={'text-left'}>
        <span>Period display:</span> {parameters?.periodDisplay || '-'}
      </div>
      <div className={'text-left'}>
        <span>Host Ids:</span>{' '}
        {parameters?.hostIds?.toString()?.replace(/,/g, ', ') || '-'}
      </div>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="auto"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <span>Hover to view</span>
    </OverlayTrigger>
  );
};

export default Hover;

import FilterWrapper from './filter-wrapper';
// import { UilPlus, UilTrashAlt } from '@iconscout/react-unicons';
// import { Form } from 'react-bootstrap';
import SimpleSelectBox from '../../../../helpers/ui-controls/ui-components/SimpleSelectBox';
import FilterPanelInfoTooltip from './filter-panel-info-tooltip';

const CustomFieldFilter = ({
  title = '',
  setFunction,
  onChangefilters,
  dataArray,
  options = [
    { value: 3, text: 'Vendor Max', name: 'vendormax' },
    { value: 2, text: 'Frozen Mountain', name: 'frozenmountain' },
    { value: 13, text: 'Reach Local', name: 'reachlocal' },
    { value: 2054, text: 'Abdul Samad', name: 'Abdul Samad' }
  ],
  isDropdown = true,
  toolTipQuickStats,
  toolTipGraphs
}) => {
  // const [dataArray, setFunction] = useState([...dataArray]);

  // const handleOnChangeText = (e, ind) => {
  //   let temp = JSON.parse(JSON.stringify(dataArray));
  //   temp[ind].value = e.target.value;
  //   setFunction(temp);
  // };

  const onFilterClear = () => {
    console.log('clear');
    setFunction([]);
    onChangefilters([]);
  };

  const handleSelect = (e, index) => {
    let temp = [];
    let obj = {
      value: e.target.value,
      key: e.target.selectedOptions[0].innerText,
      id: e.target.value
    };
    if (obj.key !== 'None' && obj.value !== 0) temp.push(obj);
    setFunction(temp);
    onChangefilters(temp.filter(t => t.value));
  };

  return (
    <FilterWrapper
      title={title}
      onFilterClear={onFilterClear}
      tooltipLabel={
        <FilterPanelInfoTooltip
          toolTipQuickStats={toolTipQuickStats}
          toolTipGraphs={toolTipGraphs}
        />
      }
    >
      <div className="items-container">
        {dataArray.length ? (
          dataArray.map((item, index) => (
            <div className="item-container" key={index}>
              {/* {isDropdown ? (  */}
              <SimpleSelectBox
                fullWidth={true}
                dataProperty={item.value}
                options={
                  index === 0
                    ? [{ value: 0, text: 'None', name: 'None' }, ...options]
                    : options
                }
                handleChange={e => handleSelect(e, index)}
              />
              {/* {index === dataArray.length - 1 && options.length > index && (
                <span onClick={onAddItem}>
                  <UilPlus size={26} className="lightGrey" />
                </span>
              )}
              {dataArray.length > 1 && (
                <span onClick={() => onRemoveItem(index)}>
                  <UilTrashAlt size={26} className="lightGrey" />
                </span>
              )} */}
            </div>
          ))
        ) : (
          <div className="item-container">
            {/* {isDropdown ? (  */}
            <SimpleSelectBox
              fullWidth={true}
              // dataProperty={item.value}
              options={[{ value: 0, text: 'None', name: 'None' }, ...options]}
              handleChange={e => handleSelect(e, 0)}
            />
            {/* <span onClick={onAddItem}>
              <UilPlus size={26} className="lightGrey" />
            </span> */}
          </div>
        )}
      </div>
    </FilterWrapper>
  );
};

export default CustomFieldFilter;

import {
  pushLoadingProgress,
  removeFromIncomingSection,
  setActiveChatList,
  setAllChatList,
  setIncomingChatList
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import { IncreaseProgress } from 'pages/portal/messaging/ChatSplashScreen/SplashScreen';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';

const Subscribe_GeneralQueue = callbacks => {
  RTC().Subscribe({
    channels: ['/generalqueue'],
    callbacks: callbacks
  });
};

export const Init_GeneralQueue = () => {
  Subscribe_GeneralQueue({
    onSuccess: function (args) {
      IncreaseProgress({
        text: 'Subscribing general queue...',
        count: 10
      });
      console.log('onSuccess::General Queue Subscribe', args);
      var meta = args.getMeta();
      console.log('general queue meta', meta);
      if (meta && meta.length) {
        let incomingList = [];
        let activeList = [];
        let allList = [];

        let removedIncomingList = [];
        let removedActiveList = [];
        let removedAllList = [];
        for (let i = 0; i < meta.length; i++) {
          var chatroomByType = chatChanged(meta[i]);
          if (
            chatroomByType.includeInSections.includes(Enums.RoomType.ActiveChat)
          ) {
            activeList.push(chatroomByType.room);
          }

          if (
            chatroomByType.includeInSections.includes(Enums.RoomType.AllChat)
          ) {
            allList.push(chatroomByType.room);
          }

          if (
            chatroomByType.includeInSections.includes(
              Enums.RoomType.IncomingChat
            )
          ) {
            incomingList.push(chatroomByType.room);
          }

          // if ((chatroomByType.excludeInSections.includes(Enums.RoomType.ActiveChat))) {
          //   removedActiveList.push(chatroomByType.room);
          // }

          // if ((chatroomByType.excludeInSections.includes(Enums.RoomType.AllChat))) {
          //   removedAllList.push(chatroomByType.room);
          // }

          if (
            chatroomByType.excludeInSections.includes(
              Enums.RoomType.IncomingChat
            )
          ) {
            store.dispatch(
              removeFromIncomingSection(chatroomByType.room.getId())
            );
            // removedIncomingList.push(chatroomByType.room);
          }
        }

        store.dispatch(setAllChatList(allList));
        store.dispatch(setActiveChatList(activeList));
        store.dispatch(setIncomingChatList(incomingList));
      }
    },
    onFailure: function (args) {
      console.log('onFailure::General Queue Subscribe', args);
      console.log('onFailure::General Queue::Channels', args.getChannels());
    },
    onReceive: function (arg) {
      console.log(arg);
      console.log('onReceive::', arg.getData().text);
    }
  });
};

export default Subscribe_GeneralQueue;

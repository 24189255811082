import FilterWrapper from './filter-wrapper';
// import { UilPlus, UilTrashAlt } from '@iconscout/react-unicons';
// import { Form } from 'react-bootstrap';
// import SimpleSelectBox from '../../../../helpers/ui-controls/ui-components/SimpleSelectBox';
import AutoCompeleteComp from './auto-complete';
import FilterPanelInfoTooltip from './filter-panel-info-tooltip';

const CustomAutoCompleteFilter = ({
  title = '',
  setFunction,
  onChangefilters,
  handleTextChange,
  loading,
  selected,
  options = [
    { value: 0, text: 'None', name: 'None' },
    { value: 3, text: 'Vendor Max', name: 'vendormax' },
    { value: 2, text: 'Frozen Mountain', name: 'frozenmountain' },
    { value: 13, text: 'Reach Local', name: 'reachlocal' },
    { value: 2054, text: 'Abdul Samad', name: 'Abdul Samad' }
  ],
  toolTipQuickStats = 'Apply to All Quick Stats',
  toolTipGraphs = 'Apply to All Charts'
}) => {
  const onFilterClear = () => {
    console.log('clear');
    let temp = [];
    setFunction(temp);
    onChangefilters([]);
  };
  const handleChange = e => {
    setFunction(e);
    onChangefilters(e);
  };
  return (
    <FilterWrapper
      title={title}
      onFilterClear={onFilterClear}
      tooltipLabel={
        <FilterPanelInfoTooltip
          toolTipQuickStats={toolTipQuickStats}
          toolTipGraphs={toolTipGraphs}
        />
      }
    >
      <div className="items-container">
        <AutoCompeleteComp
          handleChange={handleChange}
          handleTextChange={handleTextChange}
          options={options}
          loading={loading}
          selected={selected}
        />
      </div>
    </FilterWrapper>
  );
};

export default CustomAutoCompleteFilter;

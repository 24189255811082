import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilKeySkeleton from '@iconscout/react-unicons/icons/uil-key-skeleton';
import { UilUpload } from '@iconscout/react-unicons';
import { theme } from 'helpers/variables';
import { setLanguage, stopPropogation } from 'features/general/generalSlice';
import i18n from 'app/i18n';
import ChangePassword from 'pages/portal/modal/ChangePassword';
import SimpleSelectBox from 'helpers/ui-controls/ui-components/SimpleSelectBox';
import UserModal from 'pages/portal/modal/UserModal';
import storage from 'helpers/storage';
import { getUserAsync, selectUser } from 'features/users/userSlice';
import { useTranslation } from 'react-i18next';
import RenderSidebarTooltip from 'helpers/ui-controls/renderSidebarTooltip';
import { globalLogout } from 'helpers/generic-logout-method';

const ProfileNav = ({ showSideBar, onLogOutRemoveChat }) => {
  const user = JSON.parse(storage.getItem('Profile'));
  const [menuShow, setMenuShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const profile = useSelector(selectUser).user;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const languageOptions = [
    { value: 'en-US', text: 'English' },
    { value: 'sp-SP', text: 'Spanish' },
    { value: 'fr-FR', text: 'French' }
  ];

  const localStorageLanguage = JSON.parse(storage.getItem('language'));

  const logoutHandler = () => {
    onLogOutRemoveChat();
    globalLogout();
  };

  const useCloseOnClickedOutside = async ref => {
    await dispatch(stopPropogation(ref)).then(res => {
      if (res) setMenuShow(false);
    });
  };

  // use ref on wrapper element
  const wrapperRef = useRef(null);
  // calling the method to check if the clicked event
  // happened inside / outside the wrapper
  // and pass the wrapper ref
  useCloseOnClickedOutside(wrapperRef);

  const toggleDropdownHandler = e => {
    let show = !menuShow;
    setMenuShow(show);
  };
  const languageHandler = (el, field, language) => {
    let tempLanguage;
    if (language) {
      tempLanguage = language;
      setMenuShow(false);
    } else {
      tempLanguage = localStorageLanguage;
    }
    i18n.changeLanguage(tempLanguage);
    dispatch(setLanguage(tempLanguage));
  };

  const openModal = () => {
    let showModalState = !showModal;
    setShowModal(showModalState);
    setMenuShow(false);
  };

  const closeModal = () => {
    let updatedUser = JSON.parse(storage.getItem('Profile'));
    setShowModal(false);
    if (updatedUser?.RequirePasswordReset) {
      logoutHandler();
    }
  };

  const openCloseProfileModal = () => {
    let showModalState = !showProfileModal;
    setShowProfileModal(showModalState);
    if (showModalState)
      dispatch(
        getUserAsync({
          params: { id: user.UserID }
        })
      );
    setMenuShow(false);
  };

  useEffect(() => {
    languageHandler();
    if (user?.RequirePasswordReset) {
      openModal();
    }
  }, [profile, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="profile-nav" ref={wrapperRef}>
      <div
        className="avatar-nav d-block"
        onClick={e => toggleDropdownHandler(e)}
      >
        {/* conditional toggle avtar/usericon */}
        <div className="userIcon d-flex align-items-center">
          {user?.ImageUrl ? (
            <RenderSidebarTooltip
              showSidebar={showSideBar}
              title={user.DisplayName}
              icon={
                <img src={user.ImageUrl} className="avatar-img" alt="user" />
              }
            />
          ) : (
            <RenderSidebarTooltip
              showSidebar={showSideBar}
              title={user?.DisplayName}
              icon={<UilUser size="16" color="white" className="avatar-icon" />}
            />
          )}
          {showSideBar ? (
            <span className="userText fw-600">
              {user?.DisplayName ? user.DisplayName : ''}
            </span>
          ) : null}
        </div>
      </div>
      {/* toggle the menu on condition check */}
      {menuShow && (
        <div className={`sidebar-bottom-menu${showSideBar ? '-open' : ''}`}>
          <ul className="dropdow-list">
            <li className="notification-list-item">
              <span className="select-title">
                {t('company.general.portallang')}
              </span>
              <SimpleSelectBox
                dataProperty={localStorageLanguage || ''}
                options={languageOptions}
                handleChange={languageHandler}
              />
              {/* <select
                className="form-control select"
                value={language}
                onChange={e => languageHandler(e.target.value)}
              >
                <option value="en-US">English</option>
                <option value="sp-SP">Spanish</option>
                <option value="fr-FR">French</option>
              </select> */}
            </li>
            <li className="notification-list-item">
              <a
                href="#"
                style={{ color: theme.primary }}
                onClick={openCloseProfileModal}
              >
                <UilUser
                  size="16"
                  color={theme.primary}
                  className="list-icon"
                />
                {t('company.general.profile')}
              </a>
            </li>
            <li className="notification-list-item">
              <a href="#" style={{ color: theme.primary }} onClick={openModal}>
                <UilKeySkeleton
                  size="16"
                  color={theme.primary}
                  className="list-icon"
                />
                {t('company.general.changePass')}
              </a>
            </li>
            {/* <li className="notification-list-item">
              <a
                href="#"
                style={{ color: theme.danger }}
                onClick={redirectHandler}
              >
                <UilSignout
                  size="16"
                  color={theme.danger}
                  className="list-icon"
                />
                Redirect to Old Portal
              </a>
            </li> */}
            <li className="notification-list-item">
              <a
                href="#"
                style={{ color: theme.danger }}
                onClick={logoutHandler}
              >
                <UilUpload
                  size="16"
                  color={theme.danger}
                  className="list-icon"
                  style={{ transform: 'rotate(90deg)' }}
                />
                {t('company.general.logout')}
              </a>
            </li>
          </ul>
        </div>
      )}
      <UserModal
        title={'User Information'}
        eventHideModal={openCloseProfileModal}
        showModal={showProfileModal}
        editData={profile}
      />
      <ChangePassword
        showModal={showModal}
        eventHideModal={closeModal}
        requirePasswordReset={user && user.RequirePasswordReset}
      />
    </div>
  );
};

export default ProfileNav;

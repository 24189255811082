import React, { useState, useEffect, useRef } from 'react';
import * as _ from 'lodash.debounce';
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import {
  getSelectedChat,
  getSelectedChatById,
  saveChatMessage,
  saveInputMessage,
  setUnreadMessage,
  getUnsentLeads,
  getUnsentLeadListAsync,
  updateSelectedChat,
  setVisitorStatus,
  getSelectedVisitorStatus,
  setChatInfoDataInChats
} from 'features/messagingChats/messagingChats';
import {
  setLeadFormUserCredentials,
  getLeadFormUserCredentials
} from 'features/lead/leadSlice';
import Initiate_Chat from 'app/communication/services/chat/initiate_chat';
import { chatCallbacks } from 'app/communication/services/chat/callbacks/chat_default_callbacks';

import PubhlishToChat, {
  PublishTypingIndicatorToChat
} from 'app/communication/services/chat/pub_sub_chat';
import { Enums } from 'helpers/dropdown-enums';
import TypingIndicator from '../TypingIndicator';
import { selectLogin } from 'features/auth/authSlice';
import './ChatDetail.scss';

import {
  getPlainStringFromHTMLElementAsString,
  validateEmailFromSentence,
  validatePhoneFromSentence
} from 'helpers/validate';
import {
  getCountryCode,
  mapVisitorInfoInLeadForm,
  showTypingIndicatorInChat
} from '../Helper';
import BottomSection from './BottomSection';
import AllMessages from './AllMessages';
import ChatDetailHeader from './ChatDetaiHeader';

const ChatDetail = React.memo(
  ({
    setToggleActiveChats,
    setToggleChatDetail,
    setToggleChatSuggestion,
    setDefaultSuggestionTab,
    defaultMessageText,
    notifiySound
  }) => {
    const [sendingText, setSendingText] = useState(false);

    const dispatch = useDispatch();
    const selectedChat = useSelector(getSelectedChat);
    const chatId = selectedChat.getId();
    const chatById = useSelector(getSelectedChatById(chatId));
    const unsentLeadList = useSelector(getUnsentLeads);

    const leadFormUserCredentials = useSelector(getLeadFormUserCredentials);

    console.log('chatById::', chatById);
    const user = useSelector(selectLogin);

    let getVisitorStatus = useSelector(getSelectedVisitorStatus);
    let visitorStatus = getVisitorStatus(selectedChat?.getId());

    const _onSuccessMessageArrived = data => {
      data['preventNotification'] = true;
      _onMessageArrived(data);
    };

    const _onMessageArrived = data => {
      if (data.userId !== user?.profile?.UserID && !data.preventNotification) {
        notifiySound();
        dispatch(setUnreadMessage(data.chatId));
      }

      // if visitor's msg have email/number, fetch unsent lead if not in unsent lead
      if (data.userId === Enums.ChatMessageType.Visitor) {
        if (!selectedChat?.data?.isLead && !selectedChat?.data?.fetchLeads) {
          checkIfEmailOrNumExistInMsg(data.text);
        }
      }

      if (
        data.chatId === chatId &&
        data.userId === Enums.ChatMessageType.Visitor
      ) {
        dispatch(setLeadFormUserCredentials(data.text));
      }

      dispatch(saveChatMessage(data));

      // Alert: don't remove chat when chat ended
      // if (data.isChatEnded) {
      //   setTimeout(() => {
      //     setToggleChatSuggestion(false);
      //     setToggleChatDetail(false);
      //     dispatch(endChatReference(data.chatId));
      //   }, 2000);
      // }
    };

    const [isChatEnded, setIsChatEnded] = useState(false);

    useEffect(() => {
      // only runs first time when component mount
      if (chatById?.messages?.length) {
        chatById?.messages.forEach(msg => {
          if (msg.userId === Enums.ChatMessageType.Visitor) {
            dispatch(setLeadFormUserCredentials(msg.text));
            // commented out this for testing from _onMessageArrived method
            // if (!selectedChat?.data?.isLead && !selectedChat?.data?.fetchLeads) {
            //   checkIfEmailOrNumExistInMsg(msg.text);
            // }
          }
        });
      }

      // fetch unsent leads if started chat is initiated by sms static and does not exist unsentLead list
      fetchUnsentLeadOnSmSChat();
    }, [chatId]);

    useEffect(() => {
      let callbacks = chatCallbacks({
        _onMessageArrived: _onMessageArrived,
        _onSuccessMessageArrived: _onSuccessMessageArrived,
        chatId
      });
      Initiate_Chat({
        chatId: chatId,
        companyId: selectedChat.getCompanyId(),
        callbacks
      });

      if (chatById?.messages.length) {
        let lastMsg = chatById.messages[chatById.messages.length - 1];
        if (
          lastMsg &&
          lastMsg.userId === Enums.ChatMessageType.System &&
          (lastMsg.isChatEnded ||
            Enums.ChatEndedMessages.includes(lastMsg.text))
        ) {
          setIsChatEnded(true);
        } else {
          setIsChatEnded(false);
        }

        handleVisitorStatus(lastMsg);

        // set visitorIfnoName if question type is Text and auto-send-script is disabled
        handleVisitorInfoNameWithTextName(chatById.messages);
      }
    }, [selectedChat, chatById]);

    useEffect(() => {
      // save visitorInfo into LeadForm if exist in chatInfo data
      if (chatById?.chatInfo?.data) {
        let { visitorInfoName, visitorInfoEmail, visitorInfoPhone } =
          chatById?.chatInfo?.data;
        let info = {
          visitorInfoName,
          visitorInfoEmail,
          visitorInfoPhone
        };
        let visitorData = mapVisitorInfoInLeadForm(
          info,
          leadFormUserCredentials
        );
        if (visitorData && Object.keys(visitorData).length) {
          dispatch(setLeadFormUserCredentials(visitorData));
        }
      }
    }, [
      chatById?.chatInfo?.data?.visitorInfoName,
      chatById?.chatInfo?.data?.visitorInfoEmail,
      chatById?.chatInfo?.data?.visitorInfoPhone
    ]);

    // fetch unsent leads if selected chat is initiated by sms static and have visitorInfoPhone
    // and added phone number in lead form
    const fetchUnsentLeadOnSmSChat = () => {
      if (
        selectedChat?.data?.initiatedBy == Enums.InitiatedBy['Sms Static'] &&
        selectedChat?.data?.visitorInfoPhone
      ) {
        let index = unsentLeadList.findIndex(
          item => item.chatId === selectedChat.getId()
        );
        // add phone number in lead form field
        dispatch(
          setLeadFormUserCredentials({
            phoneNumber: selectedChat?.data?.visitorInfoPhone
          })
        );
        if (index === -1) {
          setTimeout(() => {
            dispatch(getUnsentLeadListAsync());
          }, 2000);
        }
      }
    };

    //  Fetch get Unsent Leads if isLead true OR fetchLeads is true
    useEffect(() => {
      getCountryCode(selectedChat?.getLocation());
      if (selectedChat?.data?.isLead || selectedChat?.data?.fetchLeads) {
        let index = unsentLeadList.findIndex(
          item => item.chatId === selectedChat.getId()
        );
        if (index === -1) {
          // dispatch(getUnsentLeadListAsync());
        }
      }
    }, [
      selectedChat.data.chatId,
      selectedChat.data.fetchLeads,
      selectedChat.data.isLead
    ]);

    // check email/number exist in visitor message if yes update selectedChat object with new key fetchLeads:true
    const checkIfEmailOrNumExistInMsg = text => {
      let validateEmail = validateEmailFromSentence(
        getPlainStringFromHTMLElementAsString(text)
      );
      let validatePhoneNumber = validatePhoneFromSentence(text);
      if (
        validateEmail?.hasEmailAddress ||
        validatePhoneNumber?.hasPhoneNumber
      ) {
        // dispatch(updateSelectedChat({ fetchLeads: true }));
      }
    };

    // Set visitor status to active/inactive globally
    const handleVisitorStatus = lastMsg => {
      if (lastMsg.userId <= 0) {
        if (
          (lastMsg &&
            lastMsg.userId === Enums.ChatMessageType.System &&
            (lastMsg.isChatEnded ||
              Enums.ChatEndedMessages.includes(lastMsg.text))) ||
          (lastMsg.userId === Enums.ChatMessageType.Bot &&
            Enums.ChatLeftMsgs.includes(lastMsg.text))
        ) {
          dispatch(setVisitorStatus({ status: false, chatId }));
        } else {
          // if visitor send msg after 'visitor left message' came from Bot, then
          // set status to true again
          if (
            !visitorStatus &&
            lastMsg &&
            lastMsg.userId === Enums.ChatMessageType.Visitor
          ) {
            dispatch(setVisitorStatus({ status: true, chatId }));
          }
        }
      }
    };

    // set second message text to visitorInfoName in chat object when questionType is Text and auto-send-script is disabled
    const handleVisitorInfoNameWithTextName = msgs => {
      if (!chatById?.chatInfo?.data?.visitorInfoName) {
        msgs.map((msg, index) => {
          if (
            msg.questionType === Enums.ChatQuestionType.Text &&
            msg.text.includes('name')
          ) {
            let nextMsg = msgs[index + 1];
            if (nextMsg) {
              dispatch(
                setChatInfoDataInChats({
                  chatId: chatId,
                  chatInfoData: { visitorInfoName: nextMsg.text }
                })
              );
            }
          }
        });
      }
    };

    const onBackButtonHandler = () => {
      setToggleChatDetail(false);
      setToggleActiveChats(false);
    };
    // const onInfoButtonSuggestionHandler = () => {
    //   setToggleChatDetail(false);
    //   setToggleChatSuggestion(true);
    //   setDefaultSuggestionTab(true);
    // };

    // const onSuggestionButtonHandler = () => {
    //   setToggleChatDetail(false);
    //   setToggleChatSuggestion(true);
    //   setDefaultSuggestionTab(false);
    // };

    useEffect(() => {
      if (isChatEnded) {
        console.log('stop sending typing inidicator value when chat is ended');
        setDebounced.current.cancel();
      }
    }, [isChatEnded]);

    const _publishTypingIndicator = chat => {
      // publish typing true first
      PublishTypingIndicatorToChat({
        selectedChat: chat,
        typing: true
      });

      // publish typing false after 3secs
      setTimeout(() => {
        console.log(
          '_publishTypingIndicator after 3sec::',
          selectedChat.getId()
        );
        PublishTypingIndicatorToChat({
          selectedChat: chat,
          typing: false
        });
      }, 3000);
    };
    const setDebounced = useRef(_(_publishTypingIndicator, 7000)); // set delay of 7secs on input change

    const handleInputChange = e => {
      let text = e.target.value;
      let id = selectedChat.getId();
      setDebounced.current(selectedChat);
      dispatch(saveInputMessage({ id, text }));
    };

    // add emoji in input field
    const onEmojiClick = (event, emojiObject) => {
      let inputElem = document.getElementById('msg-input');
      let cursorPosition = inputElem.selectionStart;
      let inputValue = inputElem.value;
      let newValue =
        inputValue.substring(0, cursorPosition) +
        emojiObject.emoji +
        inputValue.substring(cursorPosition, inputValue.length);
      // let text = chatById.inputMessage + emojiObject.emoji;
      let text = newValue;
      inputElem.focus();
      setTimeout(() => {
        inputElem.setSelectionRange(cursorPosition + 2, cursorPosition + 2);
      }, 0);

      let id = chatId;
      dispatch(saveInputMessage({ id, text }));
    };

    const handleKeyPress = event => {
      if (event.key === 'Enter' && !sendingText) {
        console.log('enter press here! ');
        sendText(event);
      }
    };

    const sendText = options => {
      if (chatById.inputMessage.trim()) {
        let text = '';
        let id = chatId;
        dispatch(saveInputMessage({ id, text }));
        setSendingText(true);
        PubhlishToChat({
          text: chatById.inputMessage,
          selectedChat: selectedChat,
          onMessageArrived: args => {
            // TODO: other ui stuffs
            setSendingText(false);
          }
        });
      }
    };

    return (
      <>
        <Card border="0" className={`chat-details-card`}>
          <ChatDetailHeader
            onBackButtonHandler={onBackButtonHandler}
            selectedChat={selectedChat}
            isChatEnded={isChatEnded}
            setToggleChatSuggestion={setToggleChatSuggestion}
            setToggleChatDetail={setToggleChatDetail}
            setToggleActiveChats={setToggleActiveChats}
          />
          {/* <div className="transferred-chat">
            Chat is transferred by an agent
          </div> */}
          <div
            id="chat"
            className="chat_box_wrapper chat_box_small chat_box_active"
            style={{
              opacity: 1,
              display: 'block',
              transform: 'translateX(0px)'
            }}
          >
            <AllMessages
              selectedChat={selectedChat}
              chatById={chatById}
              isChatEnded={isChatEnded}
              userImage={user?.profile?.ImageUrl}
            />
            <div
              className="col-12 ml-3 text-right position-absolute"
              style={{ bottom: '80px', right: '25px' }}
            >
              {showTypingIndicatorInChat(
                chatById?.chatInfo?.data?.initiatedBy
              ) ? (
                <TypingIndicator typing={chatById?.isTyping} size={10} />
              ) : null}
            </div>
            <BottomSection
              isChatEnded={isChatEnded}
              onEmojiClick={onEmojiClick}
              inputMessage={chatById?.inputMessage}
              handleInputChange={handleInputChange}
              handleKeyPress={handleKeyPress}
              sendText={sendText}
              selectedChat={selectedChat}
            />
          </div>
        </Card>
      </>
    );
  }
);

export default ChatDetail;

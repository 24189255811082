import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectProvision } from 'features/provisioning/ProvisioningSlice';
import { toast } from 'react-toastify';
import { success } from 'helpers/variables';
import { companyHeaderSchema } from 'helpers/schemas/companySchemas/schemas';
import { companyHeader } from 'helpers/schemas/companySchemas/initialValues';
import { mapServerValuesToInitialValues } from 'helpers/generic.methods';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import DisplayKey from 'helpers/ui-controls/DisplayKey';

const ProvisionHeader = ({ companyId }) => {
  const { provision } = useSelector(e => selectProvision(e, companyId));
  const [companyHeaderObj, setCompanyHeaderObj] = useState(companyHeader);

  const CompanyHeaderFormHandler = async formData => {
    //some async task here
    toast.success(success.saveSuccess('Company Header'));
  };
  const CompanyHeaderFormIk = useFormik({
    enableReinitialize: true,
    initialValues: companyHeaderObj,
    validationSchema: companyHeaderSchema,
    onSubmit: CompanyHeaderFormHandler
  });

  useEffect(() => {
    mapServerValuesToInitialValues(provision, companyHeader)
      .then(data => {
        setCompanyHeaderObj({
          ...companyHeader,
          ...data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  }, [provision]);
  return (
    <>
      <DisplayKey displayKey={provision['id']} />
      <div className="page-header">
        <div className="page-header-top d-flex align-items-center mb-2r">
          <h6 className="company-id mb-0 mr-8">{provision.companyKey}</h6>
        </div>
        <Form
          className="form"
          onSubmit={CompanyHeaderFormIk.handleSubmit}
          autoComplete="off"
        >
          <div className="page-header-bottom d-flex align-items-center justify-content-between">
            <h1 className="main-head text-blue-2">{provision.companyName}</h1>
            {/* <Button className="btn-sm btn-flex" variant="lightgray-3">
              <UilPlus />
              {t('company.provisioningDetails.openAccount.label')}
            </Button> */}
          </div>
        </Form>
      </div>
    </>
  );
};

export default ProvisionHeader;

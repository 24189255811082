import React from 'react';
import UilArrowUp from '@iconscout/react-unicons/icons/uil-arrow-up';
import UilArrowDown from '@iconscout/react-unicons/icons/uil-arrow-down';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import { getObjectKeyByValue } from '../../../helpers/dropdown-enums';
import { camelize } from 'helpers/generic.methods';
import { selectRoles } from 'features/roles/rolesSlice';
import { useSelector } from 'react-redux';
const makeTitleFromKey = key =>
  key ? key.match(/[A-Z][a-z]+/g)?.join(' ') : '';
const CountPercentage = ({
  percentage,
  arrowDirection = 'up',
  count,
  exactValue,
  heading,
  calculatedInPercentage = false,
  isDollar,
  duration,
  isStatus = undefined,
  showStatistics = true,
  hidePercentageSignInTooltip = false
}) => {
  const { t } = useTranslation();
  const { roles } = useSelector(selectRoles);
  let options = roles?.analytics?.durationOptions;
  const template = (
    <>
      <span className="value">
        {isDollar ? '$' : ' '}
        {count} {calculatedInPercentage ? '%' : ''}
      </span>
      {showStatistics ? (
        <>
          {arrowDirection === 'up' ? (
            <UilArrowUp color={'#108043'} size={18} />
          ) : (
            <UilArrowDown color={'#BF0711'} size={18} />
          )}
          <span className={`percentage-${arrowDirection}`}>
            {percentage || 0}%
          </span>
        </>
      ) : null}
    </>
  );
  return (
    <OverlayTrigger
      placement="auto-start"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id="button-tooltip" className="my-tooltip" target="span">
          <div>
            {heading} ={' '}
            {!!calculatedInPercentage
              ? !hidePercentageSignInTooltip
                ? `${exactValue}%`
                : `${exactValue}`
              : Math.round(exactValue)}{' '}
            {t('company.analytics.label4') +
              ' ' +
              (duration
                ? t(
                    `company.analytics.${camelize(
                      makeTitleFromKey(
                        getObjectKeyByValue(options, duration, 'heading')
                      )
                    )}`
                  )
                : 'Duration')}
          </div>{' '}
          {showStatistics ? (
            <div>
              {`${t('company.analytics.label5')} = ${percentage}%`}{' '}
              {arrowDirection === 'up'
                ? t('company.analytics.label6') +
                  ' ' +
                  (duration
                    ? t(
                        `company.analytics.${camelize(
                          makeTitleFromKey(
                            getObjectKeyByValue(options, duration, 'heading')
                          )
                        )}`
                      )
                    : 'Duration')
                : t('company.analytics.label7') +
                  ' ' +
                  (duration
                    ? t(
                        `company.analytics.${camelize(
                          makeTitleFromKey(
                            getObjectKeyByValue(options, duration, 'heading')
                          )
                        )}`
                      )
                    : 'Duration')}
              {/* {arrowDirection === 'up' ? (
            <UilArrowUp color={'#108043'} size={18} />
          ) : (
            <UilArrowDown color={'#BF0711'} size={18} />
          )} */}
            </div>
          ) : null}
        </Tooltip>
      }
    >
      <div
        className="cursor-pointer count-percentage"
        // style={{ lineHeight: isStatus ? 1 : 2.3 }}
        style={{ lineHeight: 2.3 }}
      >
        {isStatus ? (
          <>
            <div style={{ lineHeight: 2.3 }}>
              <div className="value text-align-right">Inactive</div>
              {template}
            </div>
            <div>
              <div className="status-dot"></div>
            </div>
          </>
        ) : (
          template
        )}
      </div>
    </OverlayTrigger>
  );
};

export default CountPercentage;

//import { getDomain } from 'helpers/generic.methods';
import packageJson from '../package.json';

const env = process.env;
// Jira FixVersion 1.0 & Date yyyymmdd e.g: 20210719 = 19-July-2021
const version = 'v-' + packageJson.version; // on every release we need to update this

const config = {
  development: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    hideConsole: false,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING
  },
  test: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    hideConsole: true,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING
  },
  staging: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    hideConsole: true,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING
  },
  beta: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    hideConsole: true,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING
  },
  /* Not yet decided */
  production: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    hideConsole: true,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING
  }
};

export default config[env.NODE_ENV];

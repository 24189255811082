import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectReport } from 'features/report/reportsSlice';
import { toast } from 'react-toastify';
import { success } from 'helpers/variables';
import { companyHeaderSchema } from 'helpers/schemas/companySchemas/schemas';
import { companyHeader } from 'helpers/schemas/companySchemas/initialValues';
import { mapServerValuesToInitialValues } from 'helpers/generic.methods';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import DisplayKey from 'helpers/ui-controls/DisplayKey';

const ReportHeader = ({ companyId }) => {
  const { report } = useSelector(e => selectReport(e, companyId));
  const [companyHeaderObj, setCompanyHeaderObj] = useState(companyHeader);

  const CompanyHeaderFormHandler = async formData => {
    //some async task here
    toast.success(success.saveSuccess('Company Header'));
  };
  const CompanyHeaderFormIk = useFormik({
    enableReinitialize: true,
    initialValues: companyHeaderObj,
    validationSchema: companyHeaderSchema,
    onSubmit: CompanyHeaderFormHandler
  });
  // const handleOnChange = (element, key, value) => {
  //   CompanyHeaderFormIk.values[key] = value;
  //   CompanyHeaderFormIk.handleChange(element);
  //   setDisabled(value ? false : true);
  // };

  useEffect(() => {
    mapServerValuesToInitialValues(report, companyHeader)
      .then(data => {
        setCompanyHeaderObj({
          ...companyHeader,
          ...data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  }, [report]);
  return (
    <>
      <DisplayKey displayKey={report['id']} />
      <div className="page-header">
        <Form
          className="form"
          onSubmit={CompanyHeaderFormIk.handleSubmit}
          autoComplete="off"
        >
          <div className="page-header-bottom d-flex align-items-center justify-content-between">
            <h1 className="main-head text-blue-2">{report.name}</h1>
            {/* <Button className="btn-sm btn-flex" variant="lightgray-3">
              <UilPlus />
              Open account
            </Button> */}
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportHeader;

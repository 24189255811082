import React, { useContext } from 'react';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import storage from 'helpers/storage';

const AccordionButton = ({ children, eventKey, callback, btnClass }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    callback && callback(eventKey);
    storage.setItem('activeAccordion', eventKey);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <a
      href="#"
      tabIndex="0"
      className={`${btnClass}${isCurrentEventKey ? ' active' : ''}`}
      onClick={decoratedOnClick}
    >
      <span>{children ? children : null}</span>
    </a>
  );
};

export default AccordionButton;

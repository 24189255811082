import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'features/auth/LoginForm';

const Login = () => {
  return (
    <div className="form-container">
      <div className="text-darkgray-1 mb-lg-48 mb-32">
        <h2 className="fz-40 mb-16 login-title">Login to dashboard</h2>
      </div>
      <LoginForm />
      <div className="text-center">
        <Link to="/forgot-password">Forgot password?</Link>
      </div>
    </div>
  );
};

export default Login;

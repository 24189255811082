import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { CallConnectAPI, LeadAPI } from 'api/endpoints';
import mapSendCallConnectData from 'api/map-api-data/mapSendCallConnectData';
import mapLeadFormData from 'api/map-api-data/mapSendLeadFormData';
import storage from 'helpers/storage';
import {
  getPlainStringFromHTMLElementAsString,
  validateEmailFromSentence,
  validatePhoneFromSentence
} from 'helpers/validate';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
let leadFormInitialState = {
  name: '',
  email: '',
  phoneNumber: '',
  phoneNumberFrom: '',
  reason: '',
  type: 'Sales'
};
export const leadSlice = createSlice({
  name: 'lead',
  initialState: {
    lead: [],
    leadForm: {
      ...leadFormInitialState
    },
    isPhoneNumberFromFieldChange: false
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setLeadToState: (state, action) => {
      state.lead = [...state.lead, action.payload.data];
    },

    updateLeadToState: (state, action) => {
      if (action.payload.data.id) {
        let leads = [...state.lead];
        const index = leads.findIndex(lead => lead.id === action.payload.id);
        state.lead[index] = action.payload.data;
      }
    },

    removeLeadFromState: (state, action) => {
      let lead = [...state.lead];
      let index = lead.findIndex(com => com.id === action.payload.id);
      lead.splice(index, 1);
      state.lead = lead;
    },

    removeAllLeadFromState: (state, action) => {
      state.lead = [];
    },

    setIsPhoneNumberFromFieldChange: (state, action) => {
      state.isPhoneNumberFromFieldChange = action.payload;
    },

    /**
     * @name @setLeadFormUserCredentials
     * @description check and save email and phone number throughout the chat
     *              and update the state with latest
     * @requires message text
     * @result update/save the lead form state
     * */
    setLeadFormUserCredentials: (state, action) => {
      Object.keys(state.leadForm).forEach(key => {
        if (action.payload[key]) {
          state.leadForm[key] = action.payload[key];
        }
      });

      let validateEmail = validateEmailFromSentence(
        getPlainStringFromHTMLElementAsString(action.payload)
      );
      if (validateEmail?.hasEmailAddress) {
        state.leadForm.email = validateEmail.email;
      }
      let validatePhoneNumber = validatePhoneFromSentence(action.payload);
      if (validatePhoneNumber?.hasPhoneNumber) {
        let country = JSON.parse(storage.getItem('MD_visitorCountry'));
        if (country && country?.dial_code) {
          state.leadForm.phoneNumber = `${country?.dial_code}-${validatePhoneNumber.phoneNumber}`;
        } else {
          state.leadForm.phoneNumber = validatePhoneNumber.phoneNumber;
        }
      }
    },
    /**
     * @name @resetLeadFormUserCredentials
     * @description reset the lead form state
     * @requires null
     * @result set lead form email and phone to null
     * */
    resetLeadFormUserCredentials: state => {
      state.leadForm = {
        ...leadFormInitialState
      };
      state.isPhoneNumberFromFieldChange = false;
    }
  }
});

export const {
  setLeadToState,
  removeLeadFromState,
  removeAllLeadFromState,
  updateLeadToState,
  setLeadFormUserCredentials,
  resetLeadFormUserCredentials,
  setIsPhoneNumberFromFieldChange
} = leadSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const getLeadAsync = payload => (dispatch, getState) => {
  let leadAPI = LeadAPI.get + '/' + payload.params.id;
  return ApiServices.getWithParams(leadAPI, payload)
    .then(async res => {
      let { data } = res;
      if (data?.success && data?.data) {
        await dispatch(setLeadToState(data));
        return Promise.resolve(data);
      }
      return Promise.reject(res);
    })
    .catch(error => {
      return Promise.reject(error);
    });
  // }
};
/* called a selector and allows to select values from the state */
export const selectLeads = state => {
  return state.lead;
};

/* called a selector and allows to select isPhoneNumberFromFieldChange from the state */
export const selectIsPhoneNumberFromFieldChange = state => {
  return state.leads.isPhoneNumberFromFieldChange;
};

export const selectLead = (state, id) => {
  let lead = state.leads.lead;
  if (id && lead.length) {
    let temp = lead.filter(c => c.id === id);

    if (temp.length) {
      return { lead: temp[0] };
    } else {
      return { lead: {} };
    }
  } else {
    return { lead: {} };
  }
};

export const getAllLeads = payload => {
  let leadAPI = LeadAPI.getAll;
  return ApiServices.getWithParams(leadAPI, { params: payload });
};

export const updateLead = payload => {
  let leadAPI = `${LeadAPI.put}/${payload.leadId}`;
  return ApiServices.put(leadAPI, payload);
};
/**
 * @name @sendLead
 * @description Send lead to the server from this action
 * @requires object of the lead form
 * @returns Promise
 * */
export const sendLead = payload => () => {
  return ApiServices.WCF_post(LeadAPI.post, mapLeadFormData(payload))
    .then(async ({ data }) => {
      if (data?.success && data?.data) {
        return Promise.resolve(data);
      }
      return Promise.reject(data?.error);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
/**
 * @name @sendLead
 * @description Send lead to the server from this action
 * @requires object of the lead form
 * @returns Promise
 * */
export const sendCallConnect = payload => () => {
  return ApiServices.WCF_post(
    CallConnectAPI.post(mapSendCallConnectData(payload)),
    mapSendCallConnectData(payload)
  )
    .then(async ({ data }) => {
      if (data?.success && data?.data) {
        return Promise.resolve(data);
      }
      return Promise.reject(data?.error);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
export const getLeadFormUserCredentials = state => {
  return state.leads.leadForm;
};

export default leadSlice.reducer;

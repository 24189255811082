export const fr_analytics = {
  analytics: 'Analytique',
  selectAll: 'Tout sélectionner',
  lastHours: 'Dernières 24 heures',
  lastDays: 'Les 30 derniers jours',
  lastMonths: '6 derniers mois',
  pleaseWait:
    'Veuillez patienter, les données sont en cours de récupération...',
  addMore: 'Ajouter plus',
  forThePastYear: 'Pour la dernière année',
  quickStatsLegendTextFor6Months:
    'La comparaison des données de 6 mois avec les 6 mois précédents n`est actuellement pas disponible.',
  chatsLeads: 'Chat contre plomb',
  featuresUsage: 'Fonctionnalités Utilisation',
  chatReferrals: 'Parrainages de chat',
  chatStatsBy: 'Statistiques de chat par',
  accountsActivation: 'Activation des comptes',
  topBottomLeadAccounts: 'Haut/Bas Comptes principaux',
  topInactiveBillers: 'Principaux facturiers inactifs',
  budgetPacing: 'Budget/rythme',
  agentImages: 'Images de l`agent CTR',
  accounts: 'Comptes',
  filters: 'Filtres',
  clearAll: 'Tout effacer',
  clear: 'Dégager',
  features: 'Caractéristiques',
  devices: 'Dispositifs',
  user: 'Utilisateur',
  companyKey: 'Clé de l`entreprise',
  typeOfBusiness: 'Types d`entreprises',
  ChartsShowHide: 'Afficher/Masquer les graphiques',
  applyFilters: 'Appliquer des filtres',
  totalVisits: 'Nombre total de visites',
  totalChats: 'Total des Causeries',
  totalLeads: 'Leads au total',
  totalBillableLeads: 'Facturables Leads',
  clickThroughRate: 'Taux de clics',
  leadConversionRate: 'Lead taux de conversion',
  blankChats: 'Discussions vierges',
  activeCallConnect: " Connexion d'appel active",
  callConnectAttempts: "Tentatives de connexion d'appel",
  callConnectDuration: "Durée de connexion d'appel",
  activeChatsForHosts: 'Chats actifs pour les hôtes',
  activeChats: 'Discussions actives',
  topLeadGenerators: 'Principaux générateurs de plomb',
  filterApplied: 'Filters applied',
  quickStats: 'Statistiques rapides',
  label1: 'Les 30 derniers jours',
  label2: 'Augmentation par rapport aux 30 jours précédents',
  label3: 'Diminution par rapport aux 30 jours précédents',
  label4: 'Dans le',
  label5: 'Pourcentage',
  label6: 'Des augmentations de la',
  label7: 'Diminue de la',
  startTour: {
    label: 'Commencer tour'
  },
  tourIntro1:
    "Ceci est la page de démarrage d'analyse et de ses widgets de Quick",
  tourIntro2: 'Graphiques ..',
  tourIntro3: 'Total visites ..',
  tourIntro4: 'Total des Causeries ..',
  tourIntro5: 'Total des Leads ..',
  tourIntro6: 'Facturables Leads ..',
  tourIntro7: 'Taux de clics..',
  tourIntro8: 'Lead taux de conversion ..'
};

export default fr_analytics;

import {
  setActiveChatList,
  setAllChatList,
  setIncomingChatList,
  setShelvedChatList
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';

const Subscribe_Shelved = callbacks => {
  RTC().Subscribe({
    channels: ['/shelvedchats'],
    callbacks: callbacks
  });
};

export const Init_Shelved = () => {
  Subscribe_Shelved({
    onSuccess: function (args) {
      var meta = args.getMeta();
      console.log('onSuccess::Shelved subscriber ', meta);
      if (meta && meta.length) {
        let incomingList = [];
        let activeList = [];
        let allList = [];
        let shelvedList = [];
        for (let i = 0; i < meta.length; i++) {
          var chatroomByType = chatChanged(meta[i]);
          if (
            chatroomByType.includeInSections.includes(Enums.RoomType.ActiveChat)
          ) {
            activeList.push(chatroomByType.room);
          }

          if (
            chatroomByType.includeInSections.includes(Enums.RoomType.AllChat)
          ) {
            allList.push(chatroomByType.room);
          }

          if (
            chatroomByType.includeInSections.includes(
              Enums.RoomType.IncomingChat
            )
          ) {
            incomingList.push(chatroomByType.room);
          }

          if (
            chatroomByType.includeInSections.includes(
              Enums.RoomType.ShelvedChat
            )
          ) {
            shelvedList.push(chatroomByType.room);
          }

          // if ((chatroomByType.excludeInSections.includes(Enums.RoomType.ActiveChat))) {
          //   removedActiveList.push(chatroomByType.room);
          // }

          // if ((chatroomByType.excludeInSections.includes(Enums.RoomType.AllChat))) {
          //   removedAllList.push(chatroomByType.room);
          // }

          // if (
          //   chatroomByType.excludeInSections.includes(
          //     Enums.RoomType.IncomingChat
          //   )
          // ) {
          //   store.dispatch(
          //     removeFromIncomingSection(chatroomByType.room.getId())
          //   );
          //   // removedIncomingList.push(chatroomByType.room);
          // }
        }

        console.log('shelved subscriber', allList, activeList, incomingList);
        // store.dispatch(setAllChatList(allList));
        // store.dispatch(setActiveChatList(activeList));
        // store.dispatch(setIncomingChatList(incomingList));
        store.dispatch(setShelvedChatList(shelvedList));
      }
    },
    onFailure: function (args) {
      console.log('Error', args.getErrorMessage());
    },
    onReceive: function (args) {
      console.log('shelved on receive--', args);
    }
  });
};

export default Subscribe_Shelved;

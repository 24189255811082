import React from 'react';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import LeadNotesBox from './LeadNotesBox';
import { useTranslation } from 'react-i18next';
import dropdownEnums from 'helpers/dropdown-enums';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import { dateTimerFormat } from 'helpers/generic.methods';

const LeadDetails = ({ selectedLead }) => {
  const { t } = useTranslation();

  const transcriptConvert = chatString => {
    if (chatString) {
      const convertedString = chatString.split('<br />');
      return convertedString;
    }
  };
  return (
    <>
      <div className="page-section">
        <h4 className="sub-head">{t('company.leadDetails.heading1')}</h4>{' '}
        <section>
          <div className="section-body">
            <ul className="editable-fields-lists">
              <SimpleLabelTextBox
                heading={t('company.leadDetails.name.label')}
                field="name"
                dataProperty={selectedLead['name']}
                titleText={t('company.leadDetails.name.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.phoneNo.label')}
                field="phoneNo"
                dataProperty={selectedLead['phone'] || '-'}
                titleText={t('company.leadDetails.phoneNo.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.email.label')}
                field="email"
                dataProperty={selectedLead['email'] || '-'}
                titleText={t('company.leadDetails.email.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.createdOn.label')}
                field="createdOn"
                dataProperty={dateTimerFormat(selectedLead['createdOn'])}
                titleText={t('company.leadDetails.createdOn.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.leadType.label')}
                field="leadType"
                dataProperty={selectedLead['leadType']}
                titleText={t('company.leadDetails.leadType.info')}
                dropdownFlag={true}
                dropdownEnum={dropdownEnums.enum_LeadType}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.initiatedBy.label')}
                field="initiatedBy"
                dataProperty={selectedLead['initiatedBy']}
                titleText={t('company.leadDetails.initiatedBy.info')}
                dropdownFlag={true}
                dropdownEnum={dropdownEnums.enum_InitiatedBy}
              />
              <ToggleToolTip
                heading={t('company.leadDetails.called.label')}
                toolTipFor="leadFlag"
                flag={selectedLead.called}
                titleText={t('company.leadDetails.called.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.attemptedCalls.label')}
                field="attemptedCalls"
                dataProperty={selectedLead['attemptedCalls']}
                titleText={t('company.leadDetails.attemptedCalls.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.completedCalls.label')}
                field="completedCalls"
                dataProperty={selectedLead['completedCalls']}
                titleText={t('company.leadDetails.completedCalls.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.leadDetails.keywords.label')}
                field="keywords"
                dataProperty={
                  selectedLead['keywords'] ? selectedLead['keywords'] : '-'
                }
                titleText={t('company.leadDetails.keywords.info')}
              />
              <LeadNotesBox
                title={t('company.leadDetails.heading2')}
                leadNotes={transcriptConvert(selectedLead.notes)}
              />
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default LeadDetails;

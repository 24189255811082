import React from 'react';
import StaticFeild from 'helpers/ui-controls/staticFeild';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const MonthlyOrDaily = ({ report, scheduleName, isMonthly = false }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="page-section mt-0">
        <h4 className="sub-head">
          {t('company.reportDetails.schedule.schedule.label')}
        </h4>{' '}
        <section>
          <div className="section-body">
            <ul className="editable-fields-list">
              <StaticFeild
                heading={t('company.reportDetails.schedule.schedule.label')}
                field="companyName"
                dataProperty={scheduleName || '-'}
              />
              <StaticFeild
                heading={t('company.reportDetails.schedule.time.label')}
                field="companyName"
                dataProperty={
                  moment(`${report.scheduleHour}`, ['HH']).format('hh A') || '-'
                }
              />
            </ul>
            {isMonthly && (
              <ul className="editable-fields-list">
                <StaticFeild
                  heading={t('company.reportDetails.schedule.dayOfMonth.label')}
                  field="companyName"
                  dataProperty={`Day ${report.scheduleDayOfMonth} of month`}
                />
              </ul>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default MonthlyOrDaily;

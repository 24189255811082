import FilterWrapper from './filter-wrapper';
import Button from 'react-bootstrap/Button';
import { camelCaseStringToNormalText } from 'helpers/generic.methods';
import FilterPanelInfoTooltip from './filter-panel-info-tooltip';

const UserType = ({
  title = '',
  onChangefilters,
  setFunction,
  dataArray,
  toolTipQuickStats,
  toolTipGraphs
}) => {
  const handleOnChange = (val, ind) => {
    let temp = [...dataArray].map((t, index) => ({
      ...t,
      value: index === ind ? !val : false
    }));
    setFunction(temp);
    onChangefilters(temp.filter(t => t.value));
  };

  const onFilterClear = (pushBackData = true) => {
    let temp = dataArray.map(t => {
      return { ...t, value: false };
    });
    setFunction(temp);
    onChangefilters([]);
  };

  return (
    <FilterWrapper
      title={title}
      onFilterClear={onFilterClear}
      tooltipLabel={
        <FilterPanelInfoTooltip
          toolTipQuickStats={toolTipQuickStats}
          toolTipGraphs={toolTipGraphs}
        />
      }
    >
      <div className="w-100">
        {dataArray.map((device, index) => (
          <Button
            key={index}
            className={
              // 'btn-sm btn-flex'
              'btn-md btn-flex custom-btn'
            }
            type="button"
            variant={device.value ? 'primary' : 'lightgray-3'}
            onClick={e => {
              e.preventDefault();
              handleOnChange(device.value, index);
            }}
          >
            {/* <UILEdit /> */}
            {device.icon || null}
            <span>{camelCaseStringToNormalText(device.key, 'heading')}</span>
          </Button>
        ))}
      </div>
    </FilterWrapper>
  );
};

export default UserType;

import { Button, Modal, Form, Row, Col, Image } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { userSchemas } from 'helpers/schemas/companySchemas/schemas';
import { usersSection } from 'helpers/schemas/companySchemas/initialValues';
import { FormGroupField, FormGroupToggleField } from '../FormGroupInputFields';
import './styles.scss';
import { TabComponent, TabsContainer } from 'components/TabsComponent';
import UilUser from '@iconscout/react-unicons/icons/uil-user';

const UserModal = ({ showModal, eventHideModal, title, editData }) => {
  //const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('userDetails');
  const [fieldErrorOnSubmit, setFieldErrorOnSubmit] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  /**
   * @name @changePasswordFormHandler
   * @description Used to connect with store and call change password api then toggle error
   * @requires  changeFormData from the form fields
   * */

  const userFormHandler = () => {
    // await dispatch(changePasswordAsync(changeFormData))
    //   .then(() => {
    //     toast.success(success.changePassword);
    //     eventHideModal(); // to close the modal when password is successfully changed
    //     resetForm({});
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     toast.error(errors.changepassword);
    //     setFieldErrorOnSubmit(true);
    //     resetForm({});
    //     setLoading(false);
    //   });
  };
  /** Init Formik */
  const userFormIk = useFormik({
    initialValues: usersSection,
    validationSchema: userSchemas.userSchemas,
    onSubmit: userFormHandler
  });

  // useEffect
  useEffect(() => {
    if (editData && Object.keys(editData).length) {
      userFormIk.setValues({
        displayName: editData.displayName,
        email: editData.email,
        userId: editData.id,
        userName: editData.username,
        confirmPassword: '',
        lockedOut: editData.isLockedOut,
        preferMobile: editData.preferMobileChats
      });
      const { imageCloudSecureUrl, imageUrl } = editData;
      setImageUrl(imageCloudSecureUrl || imageUrl);
    }
  }, [editData]);

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = e => {
    userFormIk.handleChange(e);
    setFieldErrorOnSubmit(false);
  };
  /**
   * hide and show password
   */

  /**
   * to reset the form fields when close the modal with close icon
   */
  const handleOnModalClose = () => {
    // userFormIk.handleReset();
    eventHideModal();
  };

  // Select Tab Handler
  const handleTabSelect = tabKey => {
    if (selectedTab !== tabKey) {
      setSelectedTab(tabKey);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={eventHideModal}
      size={'xl'}
      keyboard={false}
      id="userModal"
      scrollable
      dialogClassName="userModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="user-modal-header-container">
        <Modal.Header className="user-modal-header">{title}</Modal.Header>
      </div>

      <Modal.Body className="user-modal-body">
        <div className={'navbar-expand'}>
          <TabsContainer
            onTabSelect={e => handleTabSelect(e)}
            activeKey={selectedTab}
            leftHoverBtn={false}
            rightHoverBtn={false}
            closeAllBtn={false}
          >
            <TabComponent
              tabClassName={'tab-ui'}
              eventKey={'userDetails'}
              title={<div className="tab-styles">User Details</div>}
            >
              <Form
                className="form form-class white-background"
                onSubmit={userFormIk.handleSubmit}
                autoComplete="off"
              >
                <Row>
                  <Col md={6}>
                    <div className="profile-image">
                      {imageUrl ? (
                        <Image
                          src={imageUrl}
                          className="avatar-img"
                          alt="user"
                        />
                      ) : (
                        <UilUser
                          size="88"
                          color="lightgrey"
                          className="avatar-icon"
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroupField
                      controlId="userId"
                      label="User ID"
                      handleOnChange={handleOnChange}
                      dataFormIk={userFormIk}
                      fieldErrorOnSubmit={fieldErrorOnSubmit}
                    />
                  </Col>
                  <Col md={6}>
                    <FormGroupField
                      controlId="userName"
                      label="User Name"
                      handleOnChange={handleOnChange}
                      dataFormIk={userFormIk}
                      fieldErrorOnSubmit={fieldErrorOnSubmit}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroupField
                      controlId="displayName"
                      label="Display Name"
                      handleOnChange={handleOnChange}
                      dataFormIk={userFormIk}
                      fieldErrorOnSubmit={fieldErrorOnSubmit}
                    />
                  </Col>
                  <Col md={6}>
                    <FormGroupField
                      controlId="email"
                      label="Email"
                      handleOnChange={handleOnChange}
                      dataFormIk={userFormIk}
                      fieldErrorOnSubmit={fieldErrorOnSubmit}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroupToggleField
                      controlId="lockedOut"
                      label="Locked Out"
                      handleChecked={handleOnChange}
                      dataFormIk={userFormIk}
                      fieldErrorOnSubmit={fieldErrorOnSubmit}
                      disabled
                    />
                  </Col>
                  <Col md={6}>
                    <FormGroupToggleField
                      controlId="preferMobile"
                      label="Prefer Mobile"
                      handleChecked={handleOnChange}
                      dataFormIk={userFormIk}
                      fieldErrorOnSubmit={fieldErrorOnSubmit}
                      disabled
                    />
                  </Col>
                </Row>
              </Form>
            </TabComponent>
          </TabsContainer>
        </div>
      </Modal.Body>
      <Modal.Footer className="user-modal-footer">
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex float-right"
          onClick={() => handleOnModalClose()}
        >
          <span>Close</span>
        </Button>
        {/* <Button
          disabled={loading || !userFormIk.dirty}
          type="submit"
          variant="primary"
          className="passBtn btn-md btn-flex float-right"
          onClick={() => userFormHandler()}
        >
         
          <span>Save</span>
          {loading && <UILSpinner className="spinner" />}
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default UserModal;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProvisionAsync,
  selectProvision
} from 'features/provisioning/ProvisioningSlice';
import ProvisionHeader from 'components/provision/ProvisionHeader';
import { Scrollbars } from 'react-custom-scrollbars';
import ProvisionDetails from './ProvisionDetails';

const ProvisionContainer = ({ selectedCompany }) => {
  const dispatch = useDispatch();
  const provisionId = selectedCompany.id;
  const { provision } = useSelector(e => selectProvision(e, provisionId));

  useEffect(() => {
    if (!provision || !Object.keys(provision).length) {
      dispatch(
        getProvisionAsync({
          params: { id: parseInt(provisionId), isCrmRequired: true }
        })
      );
    }
  }, [provisionId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        <Scrollbars
          style={{ borderRadius: '0 0 0 24px', height: 'calc(100vh - 70px)' }}
        >
          <div className="page has-sidebar">
            <ProvisionHeader companyId={provisionId} />
          </div>
          <div className="page has-sidebar d-flex">
            <div className="page-content col px-0">
              <div className="page-body">
                <ProvisionDetails provisioning={provision} />
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default ProvisionContainer;

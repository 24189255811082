import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Form from 'react-bootstrap/Form';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import FieldError from './FieldError';
import { toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from '../renderToolTip';
import { setErrorClass } from 'helpers/generic.methods';

const SimpleLabelTextBox = ({
  id,
  // label of the field
  heading,
  // field name
  field,
  // role
  roles,
  roleBased,
  // value of the field
  dataProperty,
  // human readable title
  titleText,
  // FormIK error for the specific field
  error,
  // custom error message text
  errorMessage,
  // text to be shown in tooltip
  helperText,
  // boolean: false to make field read-only
  editable,
  // read-only field never editable
  readOnly,
  // action trigger on change
  handleChange,
  // for textarea
  multiline,
  staticField,
  dropdownEnum,
  dropdownFlag,
  disabled
}) => {
  const [value, setValue] = useState(dataProperty);

  const rolesToApply = ['isAdminCompany'];

  helperText = helperText || titleText;
  const handleOnChange = element => {
    setValue(element.target.value);
    handleChange(element, field, element.target.value);
  };
  useEffect(() => {
    setValue(dataProperty);
  }, [dataProperty]);

  let checkAccessiblePermissions = (isRoleBased, roles) => {
    let result = false;
    if (isRoleBased) {
      rolesToApply.forEach(val => {
        if (!!roles[val]) {
          result = true;
        } else {
          result = false;
        }
      });
      return result;
    } else {
      return true;
    }
  };
  return (
    <>
      {/* <li key={field}>
        <Form.Label
          title={titleText}
          htmlFor={field}
          aria-label={heading + ' - ' + titleText}
        >
          {heading.split('-').map(value => (
            <>
              {value}
              <br />
            </>
          ))}
        </Form.Label>
      </li> */}
      {checkAccessiblePermissions(roleBased, roles) ? (
        <li key={field + '-field'}>
          <Form.Group
            controlId={heading}
            className={`${value || value === 0 ? 'has-value' : ''} ${
              error ? 'is-invalid' : ''
            } ${!editable ? 'has-diabled' : ''}`}
          >
            <Form.Control
              label={heading}
              className="lined with-label"
              value={
                dropdownFlag
                  ? dataProperty === null || dataProperty === 0 || !dataProperty
                    ? 'N/A'
                    : dropdownEnum
                        ?.filter(enumType => enumType.value === dataProperty)
                        .map(item => item.name)
                  : value || ''
              }
              onChange={e => handleOnChange(e)}
              readOnly={!editable || readOnly}
              disabled={!editable ? true : false}
            />
            <Form.Label className="label-movable">{heading}</Form.Label>
            <p
              style={{ position: 'absolute' }}
              className="text-red-1 field-error"
            >
              {error}
            </p>
          </Form.Group>

          {helperText && (
            <OverlayTrigger
              placement={toolTipPlacement}
              overlay={renderToolTip(helperText)}
            >
              <UILInfoCircle className="list-icon-after-text ml-1" />
            </OverlayTrigger>
          )}
        </li>
      ) : null}
    </>
  );
};

export default SimpleLabelTextBox;

import react, { useEffect, useState } from 'react';
import { errors, success } from 'helpers/variables';
import { Button, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import UilDocumentLayoutLeft from '@iconscout/react-unicons/icons/uil-document-layout-left';
import UilAngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';

import './ToggleRightPane.scss';
import {
  selectChatDetailsRightPane,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import { isMobile } from 'react-device-detect';

const ToggleRightPane = () => {
  let togglePanel = useSelector(selectChatDetailsRightPane);
  const dispatch = useDispatch();
  const toggleRightPaneHandler = async () => {
    dispatch(toggleChatDetailsRightPane({ show: !togglePanel.show }));
  };

  return (
    <div className="right-pane-wrapper ml-8">
      <span
        className="show-detail-arrow"
        onClick={e => toggleRightPaneHandler()}
      >
        <UilAngleLeftB
          size={30}
          className={
            isMobile
              ? 'arrow-right'
              : togglePanel.show
              ? 'arrow-right'
              : 'arrow-left'
          }
        />
      </span>
      {/* <Dropdown>
        <Dropdown.Toggle variant="default" size="sm">
          <UilEllipsisV />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={e => toggleRightPaneHandler()}>
            <UilDocumentLayoutLeft size="20" className="mr-4" /> Show Details
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
    </div>
  );
};

export default ToggleRightPane;

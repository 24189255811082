export const en_leadDetails = {
  heading1: 'Lead details',
  heading2: 'Lead notes',
  leadTooltip: 'The portal displays up to 6 months of data',
  name: {
    label: 'Name',
    info: 'The name of the customer.'
  },
  phoneNo: {
    label: 'Phone no',
    info: 'The phone number of the customer.'
  },
  email: {
    label: 'Email',
    info: 'The email address of the customer.'
  },
  createdOn: {
    label: 'Created on',
    info: 'When the lead was created in our system.'
  },
  leadType: {
    label: 'Lead type',
    info: 'The type of lead classifed by the user handling the chat.'
  },
  initiatedBy: {
    label: 'Initiated by',
    info: 'Where the chat was started from.'
  },
  called: {
    label: 'Called',
    info: 'Did the user try to connect the visitor and business.'
  },
  attemptedCalls: {
    label: 'Attempted calls',
    info: 'Number of call connect attempts.'
  },
  completedCalls: {
    label: 'Completed calls',
    info: 'Number of completed call connect attempts.'
  },
  keywords: {
    label: 'Keywords',
    info: 'Provide the Keywords if the system was able to capture it.'
  },
  flagAsProcessed: {
    label: 'Flag as processed',
    info: 'Flag as processed'
  },
  flagAsUnProcessed: {
    label: 'Flag as Un-Processed',
    info: 'Flag as Un-Processed'
  },
  replyToVisitor: {
    label: 'Reply to visitor',
    info: 'Reply to visitor'
  },
  viewChat: {
    label: 'View chat',
    info: 'View chat'
  }
};
export default en_leadDetails;
export { default as fr_leadDetails } from '../fr-FR-LeadDictionary/fr-leadDetails';
export { default as sp_leadDetails } from '../sp-SP-LeadDictionary/sp-leadDetails';

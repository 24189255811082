import { ListGroup } from 'react-bootstrap';

export const LoadingComponent = ({
  loading = false,
  dataLength = 1,
  isList = true
}) =>
  loading ? (
    <div className="heading text-center">
      <span className="ml-2 mr-2 mb-5">
        <i className="uil uil-spinner spinner mr-2 fz-24"></i>
        <span className="fz-14 text-darkgray-2">
          Please wait, Data is being fetched...
        </span>
      </span>
      {/* Loading... <i className="uil uil-spinner spinner small ml-2"></i> */}
    </div>
  ) : !dataLength ? (
    isList ? (
      <ListGroup.Item>
        <div className="heading text-center">No data Available</div>
      </ListGroup.Item>
    ) : (
      <div className="heading text-center">No data Available</div>
    )
  ) : null;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { UilArrowRight } from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';
import SSO_Redirection from 'app/redirection-SSO';

const DisabledMessaging = () => {
  const { t } = useTranslation();
  const redirectHandler = () => {
    // redirection here..
    SSO_Redirection({ returnUrl: '/dashboard.aspx', subDomain: 'dashboard.' });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="m-3">
            <h2>Messaging </h2>
          </Col>
        </Row>
        <Row>
          <Col className={'blux-text'}>
            <div
              className="container-header-existing-portal"
              style={{
                borderRadius: '10px',
                borderBottom: '1px solid #e4e3e2'
              }}
            >
              <span className="msg">{t('company.general.messagingTag')}</span>
              <Button
                className="btn-sm btn-flex ml-2 btn-container"
                variant="outline-warning"
                onClick={redirectHandler}
              >
                <span className="existing-btn-text">
                  {t('company.general.clickBtn')}
                </span>
                <span>
                  <UilArrowRight className="existing-btn-arrow" />
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        <div className="messaging-dashboard-container"></div>
      </Container>
    </>
  );
};

export default DisabledMessaging;

import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';
import { UilAngleUp, UilAngleDown, UilSync } from '@iconscout/react-unicons';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getUnsentLeadListAsync } from 'features/messagingChats/messagingChats';

const Header = ({
  title,
  chatCount,
  eventKey,
  loading,
  showRefetchIcon = false
}) => {
  const [activeId, setActiveId] = useState(eventKey);
  const dispatch = useDispatch();

  const toggleActive = id => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  const refechUnsentLeads = e => {
    e.stopPropagation();
    dispatch(getUnsentLeadListAsync());
  };

  return (
    <Accordion.Toggle
      as={Card.Header}
      className="according-text"
      eventKey={eventKey}
      onClick={() => toggleActive(eventKey)}
    >
      <div className="chat-pool-accord">
        <div className="d-flex justify-content-between align-items-center">
          <span>
            <span className="tab-title">
              {loading ? (
                <Spinner animation="border" variant="secondary" size="sm" />
              ) : (
                chatCount
              )}
              &nbsp;{title}
            </span>
            {activeId === eventKey ? (
              <UilAngleUp className="collapse-caret" />
            ) : (
              <UilAngleDown className="collapse-caret" />
            )}
          </span>

          {showRefetchIcon && !loading && (
            <span>
              <UilSync onClick={refechUnsentLeads} size={15} />
            </span>
          )}
        </div>
      </div>
    </Accordion.Toggle>
  );
};

export default Header;

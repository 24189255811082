import Scrollbars from 'react-custom-scrollbars';

const ScrollBar = ({
  children,
  styles = {},
  verticalScroll = true,
  horizontalScroll = true,
  ...rest
}) => {
  return horizontalScroll ? (
    <Scrollbars {...rest}>{children}</Scrollbars>
  ) : (
    <Scrollbars
      renderTrackHorizontal={rest => (
        <div
          {...rest}
          className="track-horizontal"
          style={{ display: horizontalScroll ? 'block' : 'none' }}
        />
      )}
      renderThumbHorizontal={rest => (
        <div
          {...rest}
          className="thumb-horizontal"
          style={{ display: horizontalScroll ? 'block' : 'none' }}
        />
      )}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

export default ScrollBar;

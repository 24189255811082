/*
 * =====================================================================
 * ---------------------- Notification Navigation ----------------------
 * ------------------- Created by Samiya Batool --------------------
 * ------------------------- Dated: 16-03-2021 -------------------------
 * =====================================================================
 * */

import React from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { selectMobileSuggestions } from 'features/fetchGeneralData/fetchGeneralDataOnAuth';
import dropdownEnums from 'helpers/dropdown-enums';
import StaticFeild from 'helpers/ui-controls/staticFeild';
import { useTranslation } from 'react-i18next';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';

const Mobile = ({ company, clickHandlerMenu }) => {
  const mobileSuggestions = useSelector(selectMobileSuggestions);
  const { t } = useTranslation();
  const mobileSuggestionInStrings = () => {
    let templateNames = '';
    if (mobileSuggestions && company) {
      const intersection =
        company &&
        mobileSuggestions &&
        mobileSuggestions?.filter(element =>
          company?.mobileSuggestions?.includes(element.id)
        );
      intersection.forEach(item => {
        templateNames += item.name + ', ';
      });
    }
    return templateNames;
  };

  return (
    <>
      <Form>
        <div className="page-section">
          <ToggleMenuButton
            heading={t('company.mobileSettings.heading1')}
            clickHandlerMenu={clickHandlerMenu}
          />
          <section>
            <div className="section-body">
              <ul className="editable-fields-lists">
                {/* <ToggleToolTip
                  heading={t('company.mobileSettings.enableLive.label')}
                  toolTipFor="liveEnabled"
                  flag={company.liveEnabled}
                  titleText={t('company.mobileSettings.enableLive.info')}
                /> */}
                <ToggleToolTip
                  heading={t('company.mobileSettings.recordLiveSessions.label')}
                  toolTipFor="recordLiveSession"
                  flag={company.recordLiveSession}
                  titleText={t(
                    'company.mobileSettings.recordLiveSessions.info'
                  )}
                />
                <SimpleLabelTextBox
                  heading={t('company.mobileSettings.videoCallTimeout.label')}
                  field="Video Call Timeout"
                  dataProperty={company['liveCallTimeout']}
                  titleText={t('company.mobileSettings.videoCallTimeout.info')}
                />
                <SimpleLabelTextBox
                  heading={t('company.mobileSettings.mobileSuggestions.label')}
                  field="Mobile Suggestions"
                  dataProperty={mobileSuggestionInStrings()}
                  titleText={t('company.mobileSettings.mobileSuggestions.info')}
                />
                <SimpleLabelTextBox
                  heading={t('company.mobileSettings.workflow.label')}
                  field="Workflow"
                  dataProperty={company['liveWorkflow']}
                  titleText={t('company.mobileSettings.workflow.info')}
                  dropdownFlag={true}
                  dropdownEnum={dropdownEnums.enum_Workflow}
                />
              </ul>
              <p className="text-warning-2 fz-13 mt-3r fw-600">
                {t('company.mobileSettings.note.label')}
              </p>
            </div>
          </section>
        </div>
      </Form>
    </>
  );
};

export default Mobile;

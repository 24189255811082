import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { Tab, Tabs } from 'react-bootstrap';
import SidebarRight from 'components/Sidebar/sidebar-right';
import storage from 'helpers/storage';
import {
  fetchMobileSuggestionsAsync,
  selectMobileSuggestions
} from 'features/fetchGeneralData/fetchGeneralDataOnAuth';
import {
  TabComponent,
  TabsContainer,
  TabNav
} from '../../../components/TabsComponent';
import ReportContainer from './details/index';
import MyReports from './myReports/index';
import QueuedReports from './queuedReports';
import {
  removeAllReportsFromState,
  removeReportsFromState
} from 'features/report/reportsSlice';
import ScrollBar from 'components/Scrollbars';
const Report = () => {
  const [openColapse, setOpenColapse] = useState(false);
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const mobileSuggestions = useSelector(selectMobileSuggestions);

  // Colapsable panel Open/Close Handler
  const handleOpen = status => {
    setOpenColapse(status);
  };

  // Sppend Close button to TabTitle
  const tabTitle = (index, company) => {
    return (
      <TabNav
        index={index}
        id={company.id}
        title={company.name}
        removeTab={removeCompany}
        textLimit={12}
      />
    );
  };

  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    setSelectedTab(JSON.parse(storage.getItem('myReports-selectedTab')));
    let reportData =
      JSON.parse(storage.getItem('myReports-selectedMyReports')) || [];
    setSelectedReports(reportData);
    updateSelectedData(reportData);
  }, []);

  // Select Tab Handler
  const handleTabSelect = tabKey => {
    if (selectedTab !== tabKey) {
      updateTabAndSaveToLS(Number(tabKey));
    }
  };

  // Remove  Tab From Selected Companies
  const removeCompany = (index, id) => {
    let companies = [...selectedReports];
    companies.splice(index, 1);
    updateCompaniesAndSaveToLS(companies);
    dispatch(removeReportsFromState({ id: id }));
  };

  const updateSelectedData = companies => {
    let checked = {};
    companies.forEach(com => {
      checked[com.id] = com;
    });
    setSelectedData(checked);
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = companies => {
    setSelectedReports(companies);
    updateSelectedData(companies);
    storage.setItem('myReports-selectedMyReports', companies);
  };

  // update Tabs and save To Local Storage
  const updateTabAndSaveToLS = tabKey => {
    setSelectedTab(tabKey);
    storage.setItem('myReports-selectedTab', tabKey);
  };

  //  TODO clear all selected tabs
  const clearAll = () => {
    updateCompaniesAndSaveToLS([]);
    updateTabAndSaveToLS('');
    dispatch(removeAllReportsFromState());
  };

  // update Selected Tab Menu For get Back to same menu after Redirecting
  const selectTabMenu = menuKey => {
    let index = selectedReports.findIndex(sc => sc.id === selectedTab);
    if (index > -1) {
      let companies = selectedReports;
      companies[index] = { ...companies[index], selectedMenu: menuKey };
      updateCompaniesAndSaveToLS(companies);
    }
  };

  useEffect(() => {
    !mobileSuggestions && dispatch(fetchMobileSuggestionsAsync());
  }, [mobileSuggestions, dispatch]);

  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [key, setKey] = useState('reports');
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="m-3">
            <h2>
              Reports{' '}
              <Badge variant="primary p-1 badge-align">
                {loading ? '...' : `${dataLimit} / ${totalRecords}`}
              </Badge>
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="rounded-border" style={{ overflow: 'hidden' }}>
            <Tabs
              className="uncontrolledTabs"
              activeKey={key}
              onSelect={k => setKey(k)}
              unmountOnExit={true}
            >
              <Tab className="tab-ui" eventKey="reports" title="My Reports">
                <MyReports
                  setDataLimit={setDataLimit}
                  setTotalRecords={setTotalRecords}
                  setLoading={setLoading}
                  loading={loading}
                  selectedReports={selectedReports}
                  setSelectedReports={setSelectedReports}
                  selectedData={selectedData}
                  setOpenColapse={setOpenColapse}
                  updateCompaniesAndSaveToLS={updateCompaniesAndSaveToLS}
                  updateTabAndSaveToLS={updateTabAndSaveToLS}
                  totalRecords={totalRecords}
                />
              </Tab>
              <Tab className="tab-ui" eventKey="queue" title="Reports Queue">
                <QueuedReports
                  setDataLimit={setDataLimit}
                  setTotalRecords={setTotalRecords}
                  setLoading={setLoading}
                  loading={loading}
                  totalRecords={totalRecords}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      {selectedReports.length ? (
        <SidebarRight handleOpen={handleOpen} open={openColapse}>
          {/* <button onClick={() => clearAll()}>Clear All</button> */}
          <TabsContainer
            activeKey={
              selectedReports.filter(sc => sc.id === selectedTab).length
                ? selectedTab
                : selectedReports[0].id
            }
            clearAll={clearAll}
            openColapse={openColapse}
            onTabSelect={e => handleTabSelect(e)}
            selectedData={selectedReports}
          >
            {selectedReports.map((company, index) => (
              <TabComponent
                tabClassName={'tab-ui'}
                key={index}
                eventKey={company.id}
                title={tabTitle(index, company)}
                id={`tab-${company.id}`}
                // TODO close button
                // closeButton={() => {
                //   console.log(index, 'close tab');
                // }}
              >
                <ReportContainer
                  selectMenu={selectTabMenu}
                  selectedCompany={company}
                />
              </TabComponent>
            ))}
          </TabsContainer>
        </SidebarRight>
      ) : null}
    </>
  );
};

export default Report;

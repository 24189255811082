import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import ChatBox from 'components/chatRenderer/ChatBox';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import { dateTimerFormat } from 'helpers/generic.methods';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
const ChatDetails = ({ chatData }) => {
  const { t } = useTranslation();
  const { roles } = useSelector(selectRoles);

  const [chat, setChat] = useState({});
  useEffect(() => {
    setChat(chatData);
  }, [chatData]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="page-section">
        <h4 className="sub-head">{t('chat.chatDetails.heading')}</h4>{' '}
        <section>
          <div className="section-body">
            <ul className="editable-fields-lists">
              <SimpleLabelTextBox
                heading={t('chat.chatDetails.createdOn.label')}
                field="createdOn"
                dataProperty={dateTimerFormat(chat['createdOn'])}
                titleText={t('chat.chatDetails.createdOn.info')}
              />
              <SimpleLabelTextBox
                heading={t('chat.chatDetails.pickedUpOn.label')}
                field="pickedUpOn"
                dataProperty={dateTimerFormat(chat['pickedUpOn'])}
                titleText={t('chat.chatDetails.pickedUpOn.info')}
              />
              <SimpleLabelTextBox
                heading={t('chat.chatDetails.endedOn.label')}
                field="endedOn"
                dataProperty={dateTimerFormat(chat['endedOn'])}
                titleText={t('chat.chatDetails.endedOn.info')}
              />
              <SimpleLabelTextBox
                heading={t('chat.chatDetails.pickedUpBy.label')}
                roles={roles}
                roleBased={true}
                field="pickedUpBy"
                dataProperty={
                  chat['pickedUpByUsername']
                    ? chat['pickedUpByUsername']
                    : 'Agent'
                }
                titleText={t('chat.chatDetails.pickedUpBy.info')}
              />
              <ToggleToolTip
                heading={t('chat.chatDetails.leadFlag.label')}
                toolTipFor="leadFlag"
                flag={chat.leadFlag}
                titleText={t('chat.chatDetails.leadFlag.info')}
              />
              <SimpleLabelTextBox
                heading={t('chat.chatDetails.ip.label')}
                field="ip"
                dataProperty={chat['ipAddress']}
                titleText={t('chat.chatDetails.ip.info')}
              />
              <SimpleLabelTextBox
                heading={t('chat.chatDetails.location.label')}
                field="location"
                dataProperty={chat['location']}
                titleText={t('chat.chatDetails.location.info')}
              />
              <SimpleLabelTextBox
                heading={t('chat.chatDetails.referrer.label')}
                field="referrer"
                dataProperty={chat['referrer']}
                titleText={t('chat.chatDetails.referrer.info')}
              />
              <ChatBox
                title={t('chat.chatDetails.chatTranscript.label')}
                chatHistory={chat.transcript}
              />
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChatDetails;

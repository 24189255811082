import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { getObjectKeyByValue, TimeIntervalsEnum } from 'helpers/dropdown-enums';
import { UilCalendarAlt, UilCheck } from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';
import { camelize } from 'helpers/generic.methods';
import { GetDurationByEnum } from '../filters/constants';
const DurationDropdown = ({ duration, handleSelect, roles }) => {
  const { t } = useTranslation();
  const makeTitleFromKey = key =>
    key ? key.match(/[A-Z][a-z]+/g)?.join(' ') : '';

  let customOptions = roles?.analytics?.durationOptions || {};

  return (
    <div
      className={
        duration
          ? 'dashboard-duration-select show'
          : 'dashboard-duration-select'
      }
    >
      <DropdownButton
        id="dropdown-basic-button"
        className={duration ? 'selected-duration' : ''}
        title={
          <div
            className="duration-title-icon"
            style={{ display: 'inline-block' }}
          >
            <UilCalendarAlt />
            <span>
              {/* {duration
                ? getObjectKeyByValue(DurationOptions, duration, 'heading')
                : 'Duration'} */}
              {t(
                `company.analytics.${camelize(
                  makeTitleFromKey(GetDurationByEnum(duration))
                )}`
              )}
            </span>
          </div>
        }
        onSelect={handleSelect}
      >
        {Object.keys(customOptions).map(
          (key, index) => {
            return (
              <Dropdown.Item
                key={index}
                eventKey={customOptions[key]}
                className={
                  customOptions[key] == duration ? 'active-duration' : ''
                }
              >
                {/* {camelCaseStringToNormalText(key, 'heading')} */}
                {t(
                  `company.analytics.${camelize(
                    makeTitleFromKey(GetDurationByEnum(customOptions[key]))
                  )}`
                )}
                {customOptions[key] == duration ? <UilCheck /> : ''}
              </Dropdown.Item>
            );
          }
          // ) : null
        )}
      </DropdownButton>
    </div>
  );
};

export default DurationDropdown;

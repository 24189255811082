import React from 'react';
import { Redirect } from 'react-router-dom';
import LeftColumn from 'components/Auth/LeftColumn';
import { getCookie } from 'helpers/generic.methods';

const Pages = props => {
  const isAuthenticated = !!getCookie('Auth');
  return (
    <div className="main-bg">
      <div className="login-form-container">
        {isAuthenticated ? <Redirect to={'/'} /> : null}
        <div className="login-form-wrapper">
          <LeftColumn />
          {props.childComp({ ...props })}
        </div>
      </div>
    </div>
  );
};

export default Pages;

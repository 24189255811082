import config from 'config';
import { removeKeysFromLS } from 'helpers/generic.methods';
import React from 'react';

/* CacheBuster component */
import packageJson from '../package.json';
global.AppInfo = { version: packageJson.version };
global.AppInfo.environment = config.version;
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

export default class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    let keysArray = [
      'Auth',
      'Profile',
      'companies-selectedTab',
      'companies-selectedCompanies',
      'chats-selectedTab',
      'chats-selectedChats',
      'leads-selectedLeads',
      'leads-selectedTab',
      'provisions-selectedProvisions',
      'provisions-selectedTab',
      'myReports-selectedMyReports',
      'myReports-selectedTab',
      'queuedReports-selectedQueuedReports',
      'queuedReports-selectedTab',
      'language',
      'return-url'
    ];
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }
        removeKeysFromLS(keysArray);
        // delete browser cache and hard reload
        window.location.reload();
        // reload(true): is deprecated but it hard reloads and still supported by few browsers
        window.location.reload(true);
      }
    };
  }

  componentDidMount() {
    fetch('/meta.json')
      .then(response => response.json())
      .then(meta => {
        global.AppInfo.metaVersion = meta.version;
        global.AppInfo.modifiedOn = meta.modifiedOn;
        const currentVersion = global.AppInfo.version;
        const shouldForceRefresh = semverGreaterThan(
          global.AppInfo.metaVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${global.AppInfo.metaVersion}. Should force refresh`
          );
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(
            `You already have the latest version - ${global.AppInfo.metaVersion}. No cache refresh needed.`
          );
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload
    });
  }
}

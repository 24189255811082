import React from 'react';
import Form from 'react-bootstrap/Form';
import { UilBellSlash, UilBell } from '@iconscout/react-unicons';

const Header = ({
  totalCount,
  notifiySoundEnable,
  setNotificationSoundEnable,
  toggleStatus,
  status,
  statusLoading
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <h5 className="title">Chats</h5>
      <div className="pt-3 d-flex">
        {notifiySoundEnable ? (
          <UilBell
            className="cursor-pointer font-color mr-2"
            onClick={() => setNotificationSoundEnable(false)}
          />
        ) : (
          <UilBellSlash
            className="cursor-pointer font-color-disabled mr-2"
            onClick={() => setNotificationSoundEnable(true)}
          />
        )}
        {/* <Notification notify={notify} /> */}
        <Form.Group
          controlId="isActiveStatus"
          style={{ display: 'flex', marginBottom: 0 }}
        >
          <Form.Label>Status</Form.Label>
          <Form.Check
            type="switch"
            id={'isActiveStatus'}
            checked={!!status}
            onChange={e => toggleStatus(e)}
            inline={true}
            disabled={statusLoading}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export default Header;

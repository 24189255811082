import React, { useState, useEffect } from 'react';
import 'static/scss/sub-sidebar.scss';
// import { useHistory } from 'react-router-dom';
import storage from 'helpers/storage';
import { SubMenuActiveLink } from 'helpers/hoc/NonCollapsableLink';
import UILAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from './AccordionButton';
import { companySideBarMenu } from './constants';
import { useTranslation } from 'react-i18next';

const SubSidebar = props => {
  const { selectMenu, selectedMenu } = props;
  const t = useTranslation().t;

  // let history = useHistory();
  let [activeAccordion, setActiveAccodion] = useState(
    JSON.parse(storage.getItem('activeAccordion')) || 'general'
  );

  // TODO
  // const goToPreviousPath = () => {
  //   history.goBack();
  // };

  useEffect(() => {
    let hasActiveAccordion = JSON.parse(storage.getItem('activeAccordion'));
    setActiveAccodion(hasActiveAccordion);
  }, []);

  return (
    <aside>
      <div className="collapsible-container">
        <Accordion defaultActiveKey={activeAccordion}>
          {companySideBarMenu.map((menuItem, key) => {
            return (
              <ul className="collapsible" key={menuItem.id}>
                <li>
                  <AccordionButton
                    btnClass="collapse-trigger"
                    eventKey={menuItem.id}
                  >
                    <>
                      {menuItem.icon}
                      <span className="fz-15">{t(menuItem.name)}</span>
                      <UILAngleDown className="collapse-caret" />
                    </>
                  </AccordionButton>
                  <Accordion.Collapse eventKey={menuItem.id}>
                    <>
                      <ul className="collapsible-nav">
                        {menuItem.subItems.map((subItem, subItemKey) => (
                          <li className="fz-15" key={subItem.id}>
                            <SubMenuActiveLink
                              onClick={() => selectMenu(subItem.id)}
                              href={'#'}
                              title={t(subItem.name)}
                              activeMenu={selectedMenu === subItem.id}
                            >
                              <>{t(subItem.name)}</>
                            </SubMenuActiveLink>
                          </li>
                        ))}
                      </ul>
                    </>
                  </Accordion.Collapse>
                </li>
              </ul>
            );
          })}
        </Accordion>
      </div>
    </aside>
  );
};

export default SubSidebar;

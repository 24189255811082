import React from 'react';
import { Steps } from 'intro.js-react';
//import RoleEngine from 'app/role-engine';
import { useTranslation } from 'react-i18next';

const TourHelper = ({
  toggleTour,
  initialStep,
  onExit,
  stepData,
  options,
  //onComplete,
  onChange
}) => {
  const { t } = useTranslation();
  const initStep = initialStep || 0;

  const opt = {
    exitOnOverlayClick: options?.exitOnOverlayClick || false,
    prevLabel: t('company.tour.btn3'),
    doneLabel: t('company.tour.btn4'),
    nextLabel: t('company.tour.btn5')
  };
  return (
    <>
      <Steps
        enabled={toggleTour}
        steps={stepData}
        initialStep={initStep}
        options={opt}
        onExit={onExit}
        //onComplete={onComplete}
        onChange={onChange}
      />
    </>
  );
};

export default TourHelper;

import UILSuitcas from '@iconscout/react-unicons/icons/uil-suitcase';
import UilClockThree from '@iconscout/react-unicons/icons/uil-clock-three';
import Parameters from './parameters/Parameters';
import Schedule from './schedule/Schedule';

const base = {
  general: '/portal/report/:id'
};
export const reportSideBarMenu = [
  {
    id: 'general',
    name: 'company.general.generalTxt',
    icon: <UILSuitcas />,
    subItems: [
      {
        id: 'parameters',
        path: base.general + '/parameters',
        name: 'company.reportDetails.routes.parameters',
        icon: <UILSuitcas />,
        slug: 'parameters',
        child: props => <Parameters {...props} />,
        exact: true
      },
      {
        id: 'schedule',
        path: base.general + '/schedule',
        name: 'company.reportDetails.routes.schedule',
        icon: <UilClockThree />,
        slug: 'schedule',
        child: props => <Schedule {...props} />,
        exact: true
      }
    ]
  }
];

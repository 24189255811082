import React from 'react';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import { useTranslation } from 'react-i18next';
import dropdownEnums from 'helpers/dropdown-enums';
import DaysAndHours from 'helpers/ui-controls/ui-components/DaysAndHours';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import { dateTimerFormat } from 'helpers/generic.methods';
import Form from 'react-bootstrap/Form';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';

const ProvisionDetails = ({ provisioning }) => {
  const { t } = useTranslation();

  const updateData = (key, value) => {
    let _tempCompanyObj = {};
    _tempCompanyObj[key] = value;
    // setCompanyObj({ ...companyObj, ..._tempCompanyObj });
  };

  return (
    <>
      <div className="page-section">
        <h4 className="sub-head">
          {t('company.provisioningDetails.heading1')}
        </h4>{' '}
        <p className="fz-12 text-warning-2 fw-600">
          {t('company.general.provisioningNote')}
        </p>
        <section>
          <div className="section-body">
            <ul className="editable-fields-lists">
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.companyName.label')}
                field="companyName"
                dataProperty={provisioning['companyName'] || '-'}
                titleText={t('company.provisioningDetails.companyName.info')}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.companyKeyRequest.label'
                )}
                field="companyKeyRequest"
                dataProperty={provisioning['companyKeyRequest'] || '-'}
                titleText={t(
                  'company.provisioningDetails.companyKeyRequest.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.status.label')}
                field="status"
                dataProperty={provisioning['status'] || 'All'}
                titleText={t('company.provisioningDetails.status.info')}
                dropdownFlag={true}
                dropdownEnum={dropdownEnums.enum_Provision}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.partnerRepresentativeNameEmail.label'
                )}
                field="partnerRepresentativeNameEmail"
                dataProperty={provisioning['accountManagerEmail'] || '-'}
                titleText={t(
                  'company.provisioningDetails.partnerRepresentativeNameEmail.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.apexchatRepresentativeEmail.label'
                )}
                field="apexchatRepresentativeEmail"
                dataProperty={provisioning['accountManager'] || '-'}
                titleText={t(
                  'company.provisioningDetails.apexchatRepresentativeEmail.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.leadEmails.label')}
                field="LeadEmails"
                dataProperty={provisioning['leadEmails'] || '-'}
                titleText={t('company.provisioningDetails.leadEmails.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.website.label')}
                field="website"
                dataProperty={provisioning['url' || '-']}
                titleText={t('company.provisioningDetails.website.info')}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.googleAnalyticsCode.label'
                )}
                field="googleAnalyticsCode"
                dataProperty={provisioning['googleAnalyticsCode'] || '-'}
                titleText={t(
                  'company.provisioningDetails.googleAnalyticsCode.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.crm.label')}
                field="crm"
                dataProperty={provisioning['crmName'] || '-'}
                titleText={t('company.provisioningDetails.crm.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.crmEmail.label')}
                field="crm"
                dataProperty={provisioning['crmEmailAddress'] || '-'}
                titleText={t('company.provisioningDetails.crmEmail.info')}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.setupInstructions.label'
                )}
                field="setupInstructions"
                dataProperty={provisioning['setupInstructions'] || '-'}
                titleText={t(
                  'company.provisioningDetails.setupInstructions.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.attachements.label')}
                field="attachements"
                dataProperty={provisioning['attachements'] || '-'}
                titleText={t('company.provisioningDetails.attachements.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.companyKey.label')}
                field="companyKey"
                dataProperty={provisioning['companyKeyRequest'] || '-'}
                titleText={t('company.provisioningDetails.companyKey.info')}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.businessCategory.label'
                )}
                field="businessCategory"
                dataProperty={provisioning['primaryCategoryId'] || '-'}
                titleText={t(
                  'company.provisioningDetails.businessCategory.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.subCategories.label')}
                field="subCategories"
                dataProperty={provisioning['secondaryCategories'] || '-'}
                titleText={t('company.provisioningDetails.subCategories.info')}
              />
            </ul>
          </div>
        </section>
      </div>
      <div className="page-section">
        <h4 className="sub-head">
          {t('company.provisioningDetails.heading2')}
        </h4>{' '}
        <section>
          <div className="section-body">
            <ul className="editable-fields-lists">
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.pricing.label')}
                field="pricing"
                dataProperty={provisioning['pricing'] || '-'}
                titleText={t('company.provisioningDetails.pricing.info')}
              />
              <ToggleToolTip
                heading={t('company.provisioningDetails.setupFee.label')}
                toolTipFor="setupFee"
                flag={provisioning?.hasSetupFee}
                titleText={t('company.provisioningDetails.setupFee.info')}
              />
              <ToggleToolTip
                heading={t('company.provisioningDetails.smsFee.label')}
                toolTipFor="smsFee"
                flag={provisioning?.hasSmsFee}
                titleText={t('company.provisioningDetails.smsFee.info')}
              />
              <ToggleToolTip
                heading={t('company.provisioningDetails.exitPopupFee.label')}
                toolTipFor="exitPopupFee"
                flag={provisioning?.hasExitPopupFee}
                titleText={t('company.provisioningDetails.exitPopupFee.info')}
              />
              <ToggleToolTip
                heading={t('company.provisioningDetails.facebookFee.label')}
                toolTipFor="facebookFee"
                flag={provisioning?.hasFacebookFee}
                titleText={t('company.provisioningDetails.facebookFee.info')}
              />
              <ToggleToolTip
                heading={t('company.provisioningDetails.spanishFee.label')}
                toolTipFor="spanishFee"
                flag={provisioning?.hasSpanishFee}
                titleText={t('company.provisioningDetails.spanishFee.info')}
              />
              <ToggleToolTip
                heading={t(
                  'company.provisioningDetails.sendDealerCRMEmail.label'
                )}
                toolTipFor="sendDealerCRMEmail"
                flag={provisioning?.useCrmEmail}
                titleText={t(
                  'company.provisioningDetails.sendDealerCRMEmail.info'
                )}
              />
              {provisioning?.useCrmEmail && (
                <SimpleLabelTextBox
                  heading={t(
                    'company.provisioningDetails.autoDealerCRMEmail.label'
                  )}
                  field="autoDealerCRMEmail"
                  dataProperty={provisioning['autoDealerCRMEmail'] || '-'}
                  titleText={t(
                    'company.provisioningDetails.autoDealerCRMEmail.info'
                  )}
                />
              )}

              <ToggleToolTip
                heading={t(
                  'company.provisioningDetails.freeConsultation.label'
                )}
                toolTipFor="freeConsultation"
                flag={provisioning?.freeConsultation}
                titleText={t(
                  'company.provisioningDetails.freeConsultation.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.consultationFee.label')}
                field="consultationFee"
                dataProperty={provisioning['consultationFee'] || '-'}
                titleText={t(
                  'company.provisioningDetails.consultationFee.info'
                )}
              />
            </ul>
          </div>
        </section>
      </div>
      {/* bussiness operations */}
      <div className="page-section">
        <h4 className="sub-head">
          {t('company.provisioningDetails.heading3')}
        </h4>{' '}
        <section>
          <div className="section-body">
            <ul className="editable-fields-lists">
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.areaOfCoverage.label')}
                field="areaOfCoverage"
                dataProperty={provisioning['areaOfCoverage']}
                titleText={t('company.provisioningDetails.areaOfCoverage.info')}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.linkToPrivacyPolicy.label'
                )}
                field="linkToPrivacyPolicy"
                dataProperty={provisioning['linkToPrivacyPolicy']}
                titleText={t(
                  'company.provisioningDetails.linkToPrivacyPolicy.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.scriptTitlePosition.label'
                )}
                field="scriptTitlePosition"
                dataProperty={provisioning['scriptTitlePosition']}
                titleText={t(
                  'company.provisioningDetails.scriptTitlePosition.info'
                )}
              />
              <ToggleToolTip
                heading={t('company.provisioningDetails.hipaa.label')}
                toolTipFor="hipaa"
                flag={provisioning?.hipaa}
                titleText={t('company.provisioningDetails.hipaa.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.languages.label')}
                field="languages"
                dataProperty={provisioning['languages']}
                titleText={t('company.provisioningDetails.languages.info')}
              />
              <ToggleToolTip
                heading={t('company.provisioningDetails.callConnect.label')}
                toolTipFor="callConnect"
                flag={provisioning?.callConnect}
                titleText={t('company.provisioningDetails.callConnect.info')}
              />
              {provisioning?.callConnect ? (
                <>
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.callConnectTranscript.label'
                    )}
                    field="callConnectTranscript"
                    dataProperty={provisioning['callConnectTranscript']}
                    titleText={t(
                      'company.provisioningDetails.callConnectTranscript.info'
                    )}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.provisioningDetails.callConnectPhone.label'
                    )}
                    field="callConnectPhone"
                    dataProperty={provisioning['callConnectPhone']}
                    titleText={t(
                      'company.provisioningDetails.callConnectPhone.info'
                    )}
                  />
                </>
              ) : null}
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.insuranceCoverageResponse.label'
                )}
                field="insuranceCoverageResponse"
                dataProperty={provisioning['insuranceCoverageResponse']}
                titleText={t(
                  'company.provisioningDetails.insuranceCoverageResponse.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.createdOn.label')}
                field="createdOn"
                dataProperty={dateTimerFormat(provisioning['createdOn'])}
                titleText={t('company.provisioningDetails.createdOn.info')}
              />
              <SimpleLabelTextBox
                heading={t('company.provisioningDetails.modifiedOn.label')}
                field="modifiedOn"
                dataProperty={dateTimerFormat(provisioning['modifiedOn'])}
                titleText={t('company.provisioningDetails.modifiedOn.info')}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.specialInstructions.label'
                )}
                field="specialInstructions"
                dataProperty={provisioning['specialInstructions']}
                titleText={t(
                  'company.provisioningDetails.specialInstructions.info'
                )}
              />
              <SimpleLabelTextBox
                heading={t(
                  'company.provisioningDetails.officeHoursTimezone.label'
                )}
                field="officeHoursTimezone"
                dataProperty={provisioning['officeHoursTimezone']}
                titleText={t(
                  'company.provisioningDetails.officeHoursTimezone.info'
                )}
              />
            </ul>
            {provisioning?.callConnect ? (
              <div className="page-section">
                <Form className="form" autoComplete="off">
                  <section>
                    <header className="section-head d-flex align-items-center justify-content-between">
                      <h5 className="fw-600 mb-0">
                        {t(
                          'company.provisioningDetails.callConnectHours.label'
                        )}
                      </h5>
                    </header>
                    <div className="section-body">
                      <DaysAndHours
                        id={'cch-' + (provisioning['companyId'] || 0)}
                        dataArray={provisioning['quickConnectHours']}
                        field="quickConnectHours"
                        titleText={t(
                          'company.provisioningDetails.callConnectHours.info'
                        )}
                        handleHoursChange={updateData}
                        disabled={true}
                      ></DaysAndHours>
                    </div>
                  </section>
                </Form>
              </div>
            ) : null}
            <div className="page-section">
              <Form className="form" autoComplete="off">
                <section>
                  <header className="section-head d-flex align-items-center justify-content-between">
                    <h5 className="fw-600 mb-0">
                      {t('company.provisioningDetails.businessHours.label')}
                    </h5>
                  </header>
                  <div className="section-body">
                    <DaysAndHours
                      id={'bh-' + (provisioning['companyId'] || 0)}
                      dataArray={provisioning['officeHours']}
                      field="officeHours"
                      titleText={t(
                        'company.provisioningDetails.businessHours.info'
                      )}
                      handleHoursChange={updateData}
                      disabled={true}
                    ></DaysAndHours>
                  </div>
                </section>
              </Form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProvisionDetails;

import React from 'react';
import DisplayKey from 'helpers/ui-controls/DisplayKey';
import { useTranslation } from 'react-i18next';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
const LeadHeader = ({ name, id, flagAsProcessed, handleFlagProcessing }) => {
  const { t } = useTranslation();
  const _handleFlagProcessing = () => {
    let payload = { leadId: id, processedByCustomer: !flagAsProcessed };
    handleFlagProcessing(payload);
  };
  return (
    <>
      <DisplayKey displayKey={id} />
      <div className="page-header">
        <div className="page-header-bottom d-flex align-items-center justify-content-between">
          <h1 className="main-head text-blue-2">{name}</h1>
          <button
            type="button"
            className={
              flagAsProcessed
                ? 'btn-md btn-flex ml-6r btn-float-right btn btn-primary'
                : 'btn-md btn-flex ml-6r introJs-companies-tab-view-0 btn-float-right btn btn-lightgray-3'
            }
            onClick={_handleFlagProcessing}
          >
            <FlagOutlinedIcon fontSize="inherit" />
            <span className="ml-1">
              {flagAsProcessed
                ? t('company.leadDetails.flagAsUnProcessed.label')
                : t('company.leadDetails.flagAsProcessed.label')}
            </span>
          </button>

          {/* 
        <Button className="btn-sm btn-flex" variant="lightgray-3">
          <UilMessage />
          {t('company.leadDetails.replyToVisitor.label')}
        </Button>
        <Button className="btn-sm btn-flex" variant="lightgray-3">
          <UilEye />
          {t('company.leadDetails.viewChat.label')}
        </Button>
        <Button className="btn-sm btn-flex" variant="lightgray-3">
          <UILFileExport />
        </Button>
        <Button className="btn-sm btn-flex" variant="lightgray-3">
          <UilPrint />
        </Button> */}
        </div>
      </div>
    </>
  );
};

export default LeadHeader;

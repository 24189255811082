import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLeadAsync,
  selectLead,
  updateLead,
  updateLeadToState
} from 'features/lead/leadSlice';
import LeadHeader from 'components/lead/LeadHeader';
import { Scrollbars } from 'react-custom-scrollbars';
import LeadDetails from './LeadDetails';
import { toast } from 'react-toastify';
const LeadContainer = ({ selectedlead, removeSelectedLeads }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const leadId = selectedlead?.id || 0;
  let { lead } = useSelector(e => selectLead(e, leadId));

  useEffect(() => {
    if (!lead || !Object.keys(lead).length) {
      fetchLead();
    }
  }, [leadId, dispatch]);
  // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLead = () => {
    let result = dispatch(
      getLeadAsync({
        params: { id: parseInt(leadId) }
      })
    );

    result.catch(err => {
      if (
        err &&
        typeof err === 'string' &&
        err === 'Invalid lead ID specified.'
      ) {
        removeSelectedLeads();
        toast.error(`You don't have access to specified lead`);
      }
    });
  };

  const handleFlagProcessing = payload => {
    try {
      setLoading(true);
      updateLead(payload).then(response => {
        if (response.data.success) {
          dispatch(
            updateLeadToState({ id: leadId, data: response?.data?.data })
          );
          toast.success('Lead has been updated');
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Scrollbars
          style={{ borderRadius: '0 0 0 24px', height: 'calc(100vh - 70px)' }}
        >
          <div className="page has-sidebar">
            <LeadHeader
              name={lead?.name}
              id={leadId}
              flagAsProcessed={lead?.processedByCustomer}
              handleFlagProcessing={handleFlagProcessing}
            />
          </div>
          <div className="page has-sidebar d-flex">
            {/* <div className="page-content col px-0"> */}
            <div className="page-body">
              <LeadDetails selectedLead={lead} />
            </div>
            {/* </div> */}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default LeadContainer;

export const en_reportDetails = {
  heading1: 'Parameters',
  note:
    'Note: Dates start at 12:00 in the morning in the selected timezone and end at midnight the same day. To get the results for a specific day, use that day as *both* the start and end date.',
  routes: {
    parameters: 'Parameters',
    schedule: 'Schedule'
  },
  parameters: {
    name: {
      label: 'Name',
      info: 'Name the report with a unique name if required.'
    },
    notificationEmail: {
      label: 'Notification email',
      info: 'Send the report to multiple email addresses by using a comma.'
    },
    notificationEndPoint: {
      label: 'Notification end point',
      info:
        'Web address to notify when the report has executed successfully. The address will include a URL that can be used to retrieve the resulting report.'
    },
    emailReport: {
      label: 'Email report as attachment',
      info:
        'If enabled, the report will be sent as attachments with notification email.'
    },
    companyKeys: {
      label: 'Company keys',
      info:
        'Use comma to separate multiple company keys. Make sure Host is NOT checked when running individual reports based on single or multiple Company keys.'
    },
    timeZone: {
      label: 'Time Zone',
      info: 'Select your appropriate Time Zone.'
    },
    period: {
      label: 'Period',
      info: 'The period of time the report will be ran for.'
    },
    groupByCompany: {
      label: 'Group by company',
      info:
        'Enable if there are multiple keys that need to be grouped within the report.'
    },
    host: {
      label: 'Host',
      info: 'Select the host if the report is for all companies.'
    }
  },
  schedule: {
    schedule: {
      label: 'Schedule'
    },
    time: {
      label: 'Time'
    },
    dayOfMonth: {
      label: 'Day'
    },
    monday: {
      label: 'Monday'
    },
    tuesday: {
      label: 'Tuesday'
    },
    wednesday: {
      label: 'Wednesday'
    },
    thursday: {
      label: 'Thursday'
    },
    friday: {
      label: 'Friday'
    },
    saturday: {
      label: 'Saturday'
    },
    sunday: {
      label: 'Sunday'
    },
    noScheduleFound: {
      label: 'No schedule found'
    }
  }
};
export default en_reportDetails;
export { default as fr_reportDetails } from '../fr-FR-ReportDictionary/fr-reportDetails';
export { default as sp_reportDetails } from '../sp-SP-ReportDictionary/sp-reportDetails';

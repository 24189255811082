import {
  pushChatRoom,
  setAllChatList
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import storage from 'helpers/storage';
import { IncreaseProgress } from 'pages/portal/messaging/ChatSplashScreen/SplashScreen';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';

const Subscribe_GeneralQueue_Group = ({ groupId, callbacks }) => {
  RTC().Subscribe({
    channels: ['/generalqueue/group/' + groupId],
    callbacks: callbacks
  });
};

export const Init_GeneralQueue_Group = groupId => {
  Subscribe_GeneralQueue_Group({
    groupId,
    callbacks: {
      onSuccess: function (args) {
        IncreaseProgress({
          text: 'Subscribing assigned groups...',
          count: 3
        });
        console.log('onSuccess::General Queue Group Subscribe', args);
      },
      onFailure: function (args) {
        console.log('onFailure::General Queue Group Subscribe', args);
        console.log(
          'onFailure::General Queue Group::Channels',
          args.getChannels()
        );
      },
      onReceive: function (args) {
        console.log('genral queue Group onReceive', args);
        var data = args.getData();
        var chatroomByType = chatChanged(data);
        console.log('group on receive chatroomByType---', chatroomByType);
        if (
          chatroomByType.includeInSections.length ||
          chatroomByType.excludeInSections.length
        ) {
          store.dispatch(pushChatRoom(chatroomByType));
        }
      }
    }
  });
};

export default Subscribe_GeneralQueue_Group;

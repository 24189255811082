import React from 'react';
import UilDirection from '@iconscout/react-unicons/icons/uil-direction';
import Button from 'react-bootstrap/Button';
import CardWrapperPopOver from './card-wrapper-popover';

export const CardWrapper = ({
  id,
  children,
  title = 'head',
  clickMoreOptions,
  isCard = true,
  isFilter = false,
  showSubHeading,
  subHeading,
  showTabs = false,
  variant,
  buttons = [],
  selected,
  multiple = false,
  handleChangeButton
}) => {
  const closeCard = () => {
    clickMoreOptions();
  };

  const onMultipleSelect = key => {
    if (selected.includes(key)) {
      handleChangeButton(selected.filter(s => s !== key));
    } else {
      handleChangeButton([...selected, key]);
    }
  };

  return (
    <div
      className={`
        ${
          showTabs
            ? 'line-chart-card-container chat-stats-chart'
            : 'line-chart-card-container'
        } ${variant === 'list' ? 'listing-wrapper' : ''}`}
    >
      <div className="graph-card-header">
        <span className="full-available-width">
          <span>{title}</span>
          {showSubHeading && <span className="sub-heading">{subHeading}</span>}
          {/* UIUX-525, buttons in header */}
          <div className="float-lg-right card-buttons-container">
            {buttons.length
              ? buttons.map((bt, index) => (
                  <span className="ml-2" key={index}>
                    <Button
                      className="btn-sm card-wrapper-button"
                      type="button"
                      variant={
                        multiple
                          ? selected.includes(bt.key)
                            ? 'primary added-cancelled-btns'
                            : 'white'
                          : bt.key === selected
                          ? 'primary added-cancelled-btns'
                          : 'white'
                      }
                      onClick={
                        multiple
                          ? e => onMultipleSelect(bt.key)
                          : e => handleChangeButton(bt.key)
                      }
                    >
                      {bt.label}
                    </Button>
                  </span>
                ))
              : null}
          </div>

          {/* {buttonOneText && (
            <span className={title ? 'ml-3' : 'ml-0'}>
              <Button
                className={
                  added || addedBiller
                    ? 'btn-md added-cancelled-btns hide-print-btn'
                    : 'btn-md hide-print-btn'
                }
                type="button"
                variant={added || addedBiller ? 'primary' : 'white'}
                onClick={e => {
                  e.preventDefault();
                  if (buttonOneText === 'Top Billers') {
                    handleBillerTopClick(true);
                  } else {
                    handleAddedClick(true);
                  }
                }}
              >
                <span>{buttonOneText}</span>
              </Button>
            </span>
          )}
          {buttonTwoText && (
            <span className="ml-3">
              <Button
                className={
                  cancelled || cancelledBiller
                    ? 'btn-md canceled-btn-white hide-print-btn'
                    : 'btn-md hide-print-btn'
                }
                type="button"
                variant={cancelled || cancelledBiller ? 'primary' : 'white'}
                onClick={e => {
                  e.preventDefault();
                  if (buttonTwoText === 'Bottom Billers') {
                    handleBillerBottomClick(true);
                  } else {
                    handleCancelledClick(true);
                  }
                }}
              >
                <span>{buttonTwoText}</span>
              </Button>
            </span>
          )} */}
        </span>
        {/* TODO-sort Icon */}
        {/* {isFilter && (
          <span className="btn-sort-menu">
            {!isCard ? <UilDirection /> : null}
          </span>
        )} */}
        <CardWrapperPopOver closeCard={closeCard} />
      </div>
      <div className={isCard ? 'graph-card-body' : 'list-card-body'}>
        {children}
      </div>
    </div>
  );
};

import React from 'react';

const ActionButtons = ({ chat, onAccept }) => {
  const onAcceptHandler = e => {
    e.stopPropagation();
    onAccept(chat);
  };

  return (
    <div className="action-btn-container text-right">
      {/* TODO */}
      <button
        className="btn btn-sm btn-success btn-font-size"
        style={{ padding: '5px 13px' }}
        onClick={onAcceptHandler}
      >
        Accept
      </button>
      {/* <button
        className="btn btn-sm btn-secondary p-1 ml-1 btn-font-size pr-3 pl-3"
        style={{ width: '50%' }}
        disabled={true}
        onClick={e => e.stopPropagation()}
      >
        Ignore
      </button> */}
    </div>
  );
};

export default ActionButtons;
